import React from 'react'

import { Carousel } from 'react-bootstrap'
import Tag from '../../core/tag/Tag'
import { Helmet } from "react-helmet";
import Civitas from '../../assets/img/partners/Civitas.jpg';

import './partners.css'
import PageBottomSendMessage from '../../components/page_bottom_send_message/PageBottomSendMessage'
import dataProvider from '../../dataProvider';

function Partners(props: any) {

  const { modalPopper, closeModal, userProfile } = props;

  const [partnersList, setPartnersList] = React.useState<any[]>([])
  const [contentFromApi, setContentFromApi] = React.useState<any[]>([])

  React.useEffect(() => {
    dataProvider.getMany('/partners', (response: any) => {
      if (response?.status === 200) {

        const sortedData: any[] = response?.data?.data || []
        sortedData.sort((a, b) => a.sortOrder - b.sortOrder);
        setPartnersList(sortedData || [])
      }
    })
    dataProvider.getMany('/pages/partnereink', (response: any) => {
      /* console.log('partnerek')
      console.log(response?.data?.data) */
      if (response?.status === 200) {
        setContentFromApi(response?.data?.data?.content)
      }
    })


  }, [])

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Partnereink - enjovom.hu</title>
        <meta name="description" content="Az #énjövőm programban közreműködő alapítványok, oktatási központok és civil szervezetek" />
      </Helmet>
      <div className="cc-content partners">
        <div className="cc-section d-xl-flex flex-column justify-content-center partners-header">
          <div className="coca-container">
            <div className="row header-column">
              <div className="col col-xl-6 header-section-text">
                <h1 className="section-title">Partnereink</h1>
                <div dangerouslySetInnerHTML={{ __html: contentFromApi[1]?.intro || '' }} />
                <div className="row partners-counter mt-2 mt-lg-5">
                  <div className="col-12 d-block d-lg-none">
                    <Carousel >
                      {/* map counters contentFormApi[2] different amount of items handling needed by groups of 2*/}
                      {
                        (contentFromApi[2]?.icons || []).map((counterDetails: any, index: number) =>
                          index % 2 === 0 &&
                          <Carousel.Item className='home_carousel_item justify-content-center' key={`carousel-${index}`}>
                            <div className='row justify-content-center' >
                              <div className={`col-${contentFromApi[2]?.icons[index + 1] ? '6' : '12'}`}>
                                <div className='home_stat_element'>
                                  <div className="home_stat_element_icon">
                                    <Tag color='lightred' label='' leftIcon={true} icon={`ejicon-${counterDetails?.icon}`} rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                  </div>
                                  <div className="home_stat_element_no heading_two">
                                    <h2>{counterDetails.number}</h2>
                                  </div>
                                  <div className="home_stat_element_desc body_small">
                                    {counterDetails.description}
                                  </div>
                                </div>
                              </div>
                              {contentFromApi[2]?.icons[index + 1] &&
                                <div className="col-6">
                                  <div className='home_stat_element'>
                                    <div className="home_stat_element_icon">
                                      <Tag color='lightred' label='' leftIcon={true} icon={`ejicon-${contentFromApi[2]?.icons[index + 1]?.icon}`} rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                    </div>
                                    <div className="home_stat_element_no heading_two">
                                      <h2>{contentFromApi[2]?.icons[index + 1]?.number}</h2>
                                    </div>
                                    <div className="home_stat_element_desc body_small">
                                      {contentFromApi[2]?.icons[index + 1]?.description}
                                    </div>
                                  </div>
                                </div>}
                            </div>
                          </Carousel.Item>
                        )
                      }
                    </Carousel>
                  </div>
                  {(contentFromApi[2]?.icons || []).map((counterDetails: any, index: number) =>
                    <div className="col-6 col-md-4 d-none d-lg-block" key={index}>
                      <div className={`home_stat_element ${index === 0 && 'mr-auto'}`}>
                        <div className="home_stat_element_icon">
                          <Tag color='lightred' label='' leftIcon={true} icon={`ejicon-${counterDetails?.icon}`} rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                        </div>
                        <div className="home_stat_element_no heading_two">
                          <h2>{counterDetails.number}</h2>
                        </div>
                        <div className="home_stat_element_desc body_small">
                          {counterDetails.description}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="col-12 col-xl-6 partner-header-img">
                <img alt={contentFromApi[0]?.alt} src={contentFromApi[0]?.image?.sizes?.original?.url} />
              </div>
            </div>
          </div>
        </div>
        <div className="cc-section partners-cards">
          <div className="coca-container">
            <h2 className="text-left">Ismerd meg partnereinket</h2>
            <div className=" row partners-cards-container">
              {/*  map start */}
              {partnersList.map((partnerDetails: any) =>
                partnerDetails &&
                <div className={`col card-wrapper `} key={`partner-${partnerDetails.id}`}>
                  <div className="row gx-0 partner-card">
                    <div className="col row gx-0 partner-card-image-container">
                      <div className="col videoWrapper partner-card-video">
                        {/* select by type photo / video */}
                        {partnerDetails.type === 'photo' &&
                          <div className='partner_card_image align-middle'>
                            <img
                              src={partnerDetails?.cover?.sizes?.original?.url || Civitas}
                              className='w-100 my-auto'
                              alt={partnerDetails?.name}>
                            </img>
                          </div>
                        }
                        {partnerDetails.type === 'video' &&
                          <iframe
                            style={{ width: "560", height: "315" }}
                            src={partnerDetails.url}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen>
                          </iframe>
                        }
                      </div>
                    </div>
                    <div className="col row partner-card-data">
                      <div className="col row partner-card-header">
                        <div className="col partner-name">
                          <h4 className="cl-red">{partnerDetails?.name}</h4>
                        </div>
                        <div className="col partner-socials">
                          {(partnerDetails?.links || []).map((linkDetails: any) =>
                            <a
                              key={linkDetails.key}
                              target="_blank"
                              rel="noreferrer"
                              href={linkDetails?.attributes?.url || '#'}
                              className="btn btn-small-circle bg-red mx-1"
                              title={linkDetails?.attributes?.type === 'external' ? `${partnerDetails?.name} weboldala` : linkDetails?.attributes?.type}
                            >
                              <span className={`cl-white ejicon-${linkDetails?.attributes?.icon}`} />
                            </a>)}
                        </div>
                        <div className="col partner-card-text">
                          {/* mapping by \r\n\r\n */}
                          {
                            (partnerDetails?.description &&
                              partnerDetails?.description.split('\r\n\r\n') || []).map((descriptionParagraph: any, index: number) =>
                                <p key={`paragraph-${index}`}>{descriptionParagraph}</p>
                              )}
                          {/* map by \r\n\r\n end */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/*  map end */}
            </div>
          </div>
        </div>
      </div>
      <PageBottomSendMessage
        modalPopper={modalPopper}
        closeModal={closeModal}
        userProfile={userProfile}
      />
    </div>
  )
}

export default Partners