import React from 'react'
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom'
import Button from '../../core/button/Button';
import Input from '../../core/inputs/Input';
import enjovom_bejelentkezes_csoportkep from '../../assets/img/enjovom_bejelentkezés_csoportkép.jpg'
import emojiApplause from '../../assets/icons/emoji/applause.png';
import { validate } from '../../validate';
import authProvider from '../../authProvider';
import { useHistory } from 'react-router-dom';

function PasswordReset(props: any) {

    const { modalPopper, closeModal } = props;

    const history = useHistory();
    let params: any = useParams();
    const query = params?.query;
    const [token, setToken] = React.useState('');
    const [pswd, setPswd] = React.useState('');
    const [pswd2, setPswd2] = React.useState('');
    const [errors, setErrors] = React.useState({ pswd: '', pswd2: '' });


    React.useEffect(() => {
        try {
            const queryParams = query.split(';')
            console.log(queryParams);
            const recievedToken = queryParams[2].split('=')[1]
            setToken(recievedToken);
        } catch (error) {
        }
    }, [query])

    const handleInputChange = (value: string, type: string) => {
        switch (type) {
            case 'pswd':
                console.log(value);
                console.log(validate.password(value))
                if (validate.password(value)) {
                    setErrors({ ...errors, pswd: '' })
                } else {
                    setErrors({ ...errors, pswd: 'A jelszó formátuma nem megfelelő!' })
                }
                setPswd(value);
                break;
            case 'pswd2':
                if (value === pswd) {
                    setErrors({ ...errors, pswd2: '' })
                } else {
                    setErrors({ ...errors, pswd2: 'A két jelszó nem egyezik meg!' })
                }
                setPswd2(value)
                break;
            default:
                console.log('?')
                break;
        }
    }

    const goToLogin = () => {
        closeModal()
        history.push('/bejelentkezes')
    }

    const TrySendNewPswd = () => {
        authProvider.resetUserPassword(pswd, token, (response: any) => {
            if (response.status === 200) {
                modalPopper(<div onClick={closeModal}>
                    <h3>Az új jelszavadat sikeresenen elmentettük.</h3>
                    <div className="d-flex col-12 justify-content-center my-3" onClick={goToLogin}>
                        <Button type='primary'
                            disabled={false}
                            icon={null}
                            icon_position={null}
                            label='Tovább'

                        />
                    </div>
                </div>)
            }
        })
    }


    return (
        <Container fluid>
            <div className='d-flex flex-lg-row flex-xl-row flex-column-reverse flex-md-column-reverse'>

                <div className='login_container d-flex align-items-start flex-column col-xl-6 col-lg-6 col-md-12 col-12'>

                    <div className='d-flex align-items-start flex-column'>
                        <div className='d-flex flex-row heading_one login_greetings_container '>
                            <div><img src={emojiApplause} className='login_apllause_emoji' alt="" /></div>
                            <h1 className="login_header_text">Üdv újra itt</h1>
                        </div>
                        <div className='body_large login_folytasd'>
                            Folytasd, ahol abbahagytad
            </div>
                    </div>
                    <div className='heading_four  login_bejelentkezes'>
                        <h4>Új jelszó igénylése</h4>
                    </div>
                    <div className='inputs_container col-xl-8 col-lg-8 col-md-12 col-12'>
                        <div className="input_holder">
                            <Input
                                disabled={false}
                                label=''
                                leftIcon='ejicon-lock'
                                rightIcon={null}
                                value={pswd}
                                onChangeHandler={(e: any) => handleInputChange(e.target.value, 'pswd')}
                                error={errors.pswd}
                                type='password'
                                placeHolder='jelszó' />
                        </div>
                        <div className="input_holder">
                            <Input
                                disabled={false}
                                label=''
                                leftIcon='ejicon-lock'
                                rightIcon={null}
                                value={pswd2}
                                onChangeHandler={(e: any) => handleInputChange(e.target.value, 'pswd2')}
                                error={errors.pswd2}
                                type='password'
                                placeHolder='Jelszó megerősítése' />
                        </div>
                        <div className='d-flex flex-row col-12 col-lg-8 body_tiny mb-4'>
                            <div >A jelszónak 6-20 karaktert kell tartalmaznia, szám, nagybetű és kisbetű is legyen benne.</div>
                        </div>
                    </div>
                    <div className='d-flex  login_forgot body_small flex-column flex-xl-row flex-md-column flex-lg-row align-middle'>
                        <div className="login_button" onClick={TrySendNewPswd}>
                            <Button
                                type='primary'
                                disabled={(errors.pswd.length || errors.pswd2.length)}
                                icon={null}
                                icon_position={null}
                                label='Új jelszó küldése'

                            />
                        </div>
                    </div>
                </div>
                <div className="login_picture_container col-xl-6 col-lg-6 col-md-12 col-12">
                    <Container fluid className='login_picture_container_inside text-end '>
                        <img src={enjovom_bejelentkezes_csoportkep} alt="" className="login_responsive_img" />
                        <div className="login_maximum col-xl-8 col-lg-8 col-md-10 col-10 heading_two">
                            <div className='col login_hozd'>Hozd ki magadból<br />a maximumot!</div>

                        </div>
                    </Container>
                </div>
            </div>

        </Container>
    )
}

export default PasswordReset
