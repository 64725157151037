import React from 'react';
import './checkbox.css';

interface checkbox_properties {
    value: string,
    handleValueChange: Function,
    disabled: boolean
}

/// lehetséges value értékek: indeterminated, checked, default

function CheckBox(props: checkbox_properties) {

    const { value, handleValueChange, disabled } = props;

    const bgColor = disabled ? 'dark_grey' : value === 'default' ? 'light_grey' : 'red';

    return (
        <div
            className={`
            checkbox 
            checkbox_${value} 
            checkbox_${bgColor}
            d-flex
            justify-content-center
            align-item-center
            `}
            onClick={disabled ? () => { } : () => handleValueChange()}
        >
            {value === 'checked' ? <span className={`ejicon-tick checkbox_icon`} /> : null}
            {value === 'indeterminated' ? <div className='centered_line' /> : null}
        </div>
    )
}

export default CheckBox
