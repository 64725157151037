/* 
properties: 
    type : radio | multiple
    setParentValue: Function  ===> API függő később finomítva lesz 
    dropList : Array<string>  ===> API függő később finomítva lesz
    selectedUnits: boolean[],
    setSelectedUnits: React.Dispatch<React.SetStateAction<boolean[]>>,
    closeDrop: Function 

*/

import React from 'react'
import './dropdown.css'

interface props {
    type: string,
    setParentValue: Function,
    dropList: Array<string>,
    selectedUnits: boolean[],
    setSelectedUnits: React.Dispatch<React.SetStateAction<boolean[]>>,
    closeDrop: Function
}

function Dropdown(props: props) {

    const { type, setParentValue, dropList, selectedUnits, setSelectedUnits, closeDrop } = props

    const handleClickOnUnit = (unitIndex: number): void => {
        if (type === 'multiple') {
            const newSelectedUnits = (selectedUnits).map((unitState, index) => index === unitIndex ? Boolean(!unitState) : Boolean(unitState))
            setSelectedUnits(newSelectedUnits);
            setParentValue(newSelectedUnits.map((data, index) => data ? dropList[index] : ''))
        }
        if (type === 'radio') {
            setParentValue([dropList[unitIndex]]);
            setSelectedUnits(selectedUnits.map((value, index) => index === unitIndex))
            closeDrop();
        }
    }

    return (
        <ul className='droplist_container body_small body_bold'>
            {dropList.map((list_element, index) =>
                <>
                    <li key={index} className={`droplist_element droplist_${type}`} onClick={() => handleClickOnUnit(index)}>
                        {type === 'multiple' ?
                            <div className={`check_box ${selectedUnits[index] ? 'selected_box' : ''}`} key={index}>
                                {selectedUnits[index] ? <span className={`ejicon-tick`}></span> : ' '}
                            </div>
                            : null}
                        <div className={`${selectedUnits[index] ? 'selected_unit' : ''}`}>
                            {list_element}
                        </div>
                    </li>
                    {index < dropList.length - 1 ? <hr className='droplist_ruler' /> : null}
                </>
            )}
        </ul>
    )
}

export default Dropdown
