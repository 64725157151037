import React from 'react'
import Star from './Star'

interface star_line_properties {
    [x: string]: any;
    rating: number,
    disabled: boolean,

}

function StarLine(props: star_line_properties) {

    const { rating, disabled } = props;

    const setParentValue = props.setParentValue
    const fiveStarDrawer = new Array(5).fill(1)

    const [liveRating, setLiveRating] = React.useState(rating);

    const sendRating = (ratingValue: number) => {
        console.log(ratingValue)
        if (setParentValue) { setParentValue(ratingValue) }
    }

    React.useEffect(() => {
        setLiveRating(rating)
    }, [rating])

    return (
        <div className="modulcard_stars">
            {
                fiveStarDrawer.map((_, index) =>
                    <span
                        title="Értékelés"
                        onMouseEnter={!disabled ? () => setLiveRating(index + 1) : () => { }}
                        onMouseLeave={!disabled ? () => setLiveRating(rating) : () => { }}
                        onClick={!disabled ? () => sendRating(index + 1) : () => { }}
                        key={`star-${index}`}
                    >
                        <Star
                            icon='ejicon-star' type={index < liveRating ? 'filled' : 'empty'}
                        />
                    </span>
                )
            }
        </div >
    )

}

export default StarLine
