/*props
icon : icon_name || null
type: empty, filled
disabled: true | false
*/
import React from 'react'

function Star(props: any) {

    const { icon, type, /* disabled, */ ...restProperties } = props;
    /* const disabledClass: string = disabled ? 'star_disabled' : ''; */
    return (
        <button className={`star star_${type} `} name="csillag">
            <span className={icon} />
        </button>
    )
}

export default Star
