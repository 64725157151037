/*
properties:
  type: primary| secondary
  disabled: true|false
  icon: icon_name | null 
  icon position: left | right | null
  label: label
*/
import React from 'react'

function Button(props: any) {

    let childrens: Array<any> = [];
    const { type, disabled, label, icon, icon_position, onClick } = props
    const disabledClass: string = disabled ? 'btn_disabled' : '';

    if (icon_position === 'left' && icon) {
        childrens.push(
            <span className={icon} key='left_icon' />
        )
    }

    childrens.push(<p className='heading_six' key='label'>{label}</p>)

    if (icon_position === 'right' && icon) {
        childrens.push(
            <span className={icon} key='right_icon' />
        )
    }

    return (
        <button className={`btn_component btn_${type} ${disabledClass}`} disabled={disabled} onClick={onClick} name={label}>
            {childrens}
        </button>
    )
}

export default Button
