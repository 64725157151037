import React from 'react'
import { Modal } from 'react-bootstrap'

import './modal.css'

function AppModal(props: any) {


    const { show, onHide, children } = props

    return (
        <div>
            <Modal
                className='footer_modal'
                show={show}
                onHide={onHide}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                backdropClassName='modal-backdrop-blur'
            >
                <Modal.Body>
                    {children}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default AppModal
