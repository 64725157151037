function AppleMusicIcon() {
  return (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="16" cy="16" r="16" fill="var(--red)"/>
    <path d="M16.0002 17.7997C16.9943 17.7997 17.8002 16.9938 17.8002 15.9997C17.8002 15.0056 16.9943 14.1997 16.0002 14.1997C15.0061 14.1997 14.2002 15.0056 14.2002 15.9997C14.2002 16.9938 15.0061 17.7997 16.0002 17.7997Z" fill="white"/>
    <path d="M15.0999 24.9997H16.8999L17.3499 18.6997H14.6499L15.0999 24.9997Z" fill="white"/>
    <path d="M16 7C11.0374 7 7 11.0374 7 16C7 20.2003 9.8962 23.7274 13.7941 24.7147L13.6573 22.8004C10.8358 21.8257 8.8 19.1491 8.8 16C8.8 12.0301 12.0301 8.8 16 8.8C19.9699 8.8 23.2 12.0301 23.2 16C23.2 19.1491 21.1642 21.8257 18.3427 22.8013L18.2059 24.7156C22.1038 23.7274 25 20.2003 25 16C25 11.0374 20.9626 7 16 7Z" fill="white"/>
    <path d="M18.9858 13.8446C19.2493 14.2337 19.4332 14.6711 19.5267 15.1316C19.672 15.8456 19.5999 16.5867 19.3197 17.2592C19.2281 17.473 19.1169 17.6779 18.9876 17.8712C18.8949 18.0089 18.7806 18.1322 18.669 18.2564L18.4998 20.6198C18.6753 20.5271 18.858 20.447 19.0218 20.3363C19.596 19.9476 20.0905 19.4527 20.4789 18.8783C20.8809 18.2824 21.1598 17.6121 21.2992 16.9069C21.4386 16.2017 21.4357 15.4757 21.2907 14.7716C21.0045 13.3884 20.1894 12.1716 19.0191 11.3804C18.4232 10.9783 17.753 10.6993 17.0478 10.5597C16.3426 10.4202 15.6166 10.4229 14.9124 10.5677C14.2209 10.7103 13.5639 10.9867 12.9783 11.3813C12.693 11.5747 12.4268 11.795 12.1836 12.0392C11.6886 12.5306 11.2948 13.1143 11.0244 13.7573C10.745 14.4231 10.6013 15.1379 10.6016 15.8599C10.6019 16.5819 10.7462 17.2966 11.0262 17.9621C11.435 18.9262 12.1143 19.7511 12.9819 20.3372C13.1448 20.447 13.3275 20.5271 13.5021 20.6189L13.3329 18.2564C13.2213 18.1331 13.1079 18.0098 13.0143 17.873C12.7508 17.484 12.567 17.0465 12.4734 16.586C12.3289 15.872 12.4013 15.131 12.6813 14.4584C12.863 14.0297 13.1265 13.6406 13.4571 13.3127C13.7836 12.9835 14.1717 12.7217 14.5992 12.5423C14.817 12.4496 15.0447 12.3794 15.2742 12.3326C15.754 12.2363 16.248 12.2363 16.7277 12.3326C16.9572 12.3803 17.1849 12.4505 17.4009 12.5405C18.0435 12.8141 18.5937 13.2668 18.9858 13.8446Z" fill="white"/>
  </svg>
  );
}

export default AppleMusicIcon;