/* 
proprerties:
disabled: boolean,
    label: string,
    leftIcon: string,     ====> ejicon elnevezése
    onChangeHandler: any  ====> szülő elem vezérlése API függő
    type: radio | multiple
*/

import React from 'react'

import Dropdown from '../dropdown/Dropdown'
import Tag from '../tag/Tag'

import './inputs.css'

interface dropinput_props {
    disabled: boolean,
    label: string,
    leftIcon: string,
    onChangeHandler: any,
    type: string,
    dropList: Array<string>
    initialSelection: Array<string>
}

function DropInput(props: dropinput_props) {
    const { disabled, label, leftIcon, onChangeHandler, type, dropList } = props
    const { initialSelection } = props

    const [selectedUnits, setSelectedUnits] = React.useState(dropList.map((listElem) => initialSelection.includes(listElem)))
    const [valueArray, setValueArray] = React.useState(dropList.map((listElem) => initialSelection.includes(listElem) ? listElem : ''));
    const [dropWidth, setDropWidth] = React.useState(100)
    const [showDrop, setShowDrop] = React.useState(false)

    const disableInputClass = disabled ? 'input_disabled' : '';
    const rightIcon = 'ejicon-chev-down'

    const inputContainerRef = React.createRef<HTMLDivElement>()

    const toggleDrop = () => {
        if (!disabled) {
            setShowDrop(!showDrop)
            setDropWidth(inputContainerRef.current?.clientWidth || 100)
        }
    }

    const removeTag = (e: any, tagIndex: number): void => {
        setSelectedUnits(selectedUnits.map((value, index) => index === tagIndex ? false : value))
        setValueArray(valueArray.map((value, index) => index === tagIndex ? '' : value))
    }

    function isOverflown(element: Element) {
        const parent: Element | null = element.parentElement
        if (parent?.clientWidth) {
            return element.getBoundingClientRect().right > parent.getBoundingClientRect().right
        }
        return false;
    }

    setTimeout(() => {
        const renderedTags = document.querySelectorAll(".mapped_tags_container");
        renderedTags.forEach((tagContainer: Element) => {
            const child: any = tagContainer.firstChild
            if (isOverflown(tagContainer)) {
                if (child) { child.classList.add('faded_tag') }
            } else {
                child.classList.remove('faded_tag')
            }
        })
    }, 100);

    const handleSelection = (selectionArray: Array<string>) => {
        setValueArray(selectionArray);
        onChangeHandler(selectionArray)
    }

    return (
        <>
            <div
                className={`input_container ${disableInputClass} `}
                ref={inputContainerRef}
                onClick={toggleDrop}
            >
                {leftIcon ? <div className={`${leftIcon} icon_left`}></div> : null}
                <div className='input_text_container'>
                    {label ? <p className="input_label body_tiny">{label}</p> : null}
                    <div className='input_field_container'>
                        <div className={`body_small input_field `} onChange={onChangeHandler}>
                            {valueArray.map((data, index) => type === 'radio' ? data :
                                selectedUnits[index] ?
                                    <div className="mapped_tags_container" key={index}>
                                        <Tag
                                            color='green'
                                            label={data}
                                            leftIcon={false}
                                            icon='ejicon-communication'
                                            rightIcon={true}
                                            removeTag={(e: any) => { removeTag(e, index) }}
                                        />
                                    </div>
                                    : null
                            )}
                        </div>
                    </div>
                </div>
                <div
                    className={`${rightIcon} icon_right input_dropdown_icon ${disabled ? 'disabled_dropdown_icon' : ''}`}
                />
            </div>
            <div
                className={`dropdown_container ${showDrop ? '' : 'dropdown_hidden'}`}
                style={{ minWidth: `${dropWidth}px` }}
            >
                <Dropdown
                    type={type}
                    setParentValue={(selectorArray: Array<string>) => handleSelection(selectorArray)}
                    dropList={dropList}
                    selectedUnits={selectedUnits}
                    setSelectedUnits={setSelectedUnits}
                    closeDrop={() => setShowDrop(false)}
                />
            </div>
        </>
    )
}

export default DropInput

