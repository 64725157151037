import { HOST } from '../src/constants/apiConnections';
import axios from 'axios'

interface send_data_to_component {
  data: any,
  error: string | null,
  status: number
}


const handleResponse = (response: any): send_data_to_component => {
  const status = response?.status;
  switch (status) {
    case 200:
      return {
        data: response.data,
        error: null,
        status: 200
      };
    case 422:
      return {
        data: null,
        error: 'Nincs ilyen bejegyzés',
        status: 422
      };
    case 404:
      return {
        data: null,
        error: 'Szerverhiba, kérlek, próbálkozz később',
        status: 404
      };
    default:
      return {
        data: null,
        error: null,
        status: response?.status || 0
      };
  }
}


const dataProvider: any = {
  getProfile: (sendDataToComponent: Function) =>  {
    const accessToken = localStorage.getItem('access_token');
    axios.get(`${HOST}/user/profile`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .catch(error => console.error(error))
      .then((response: any) => {
        sendDataToComponent(handleResponse(response));
      })
  },

  getMany: (endPoint: string, sendDataToComponent: Function) => {
    const accessToken = localStorage.getItem('access_token');
    axios.get(`${HOST}${endPoint}`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .catch(error => console.error(error))
      .then(response => {
        sendDataToComponent(handleResponse(response));
      })
  },

  getOne: (endPoint: string, itemId: number | string, specialRequest: string | null, callback: Function) => {
    const accessToken = localStorage.getItem('access_token');
    axios.get(`${HOST}${endPoint}/${itemId}${specialRequest ? specialRequest : ''}`, { headers: { Authorization: `Bearer ${accessToken}` } })
      .catch(error => console.error(error))
      .then(response => { return callback(response) })
  },
  createNew: (endPoint: string, data: any, callback: Function) => {
    const accessToken = localStorage.getItem('access_token');
    axios.post(`${HOST}${endPoint}`, data, { headers: { Authorization: `Bearer ${accessToken}` } })
      .catch(error => { return callback(error) })
      .then(response => {
        return callback(response)
      })
  },
  updateOne: (endPoint: string, data: any, callback: Function) => {
    const accessToken = localStorage.getItem('access_token');
    axios.patch(`${HOST}${endPoint}`, data, { headers: { Authorization: `Bearer ${accessToken}` } })
      .catch(error => console.error(error))
      .then(response => { return callback(response) })
  },
  updateSpecial: (endPoint: string, itemId: number, specialRequest: string, data: any, sendDataToComponent: Function) => {
    const accessToken = localStorage.getItem('access_token');
    axios.post(`${HOST}${endPoint}/${itemId}/${specialRequest}`, data, { headers: { Authorization: `Bearer ${accessToken}` } })
      .catch((error) => { return sendDataToComponent({ Error: error }) })
      .then((response) => { return sendDataToComponent(response) })
  },
}

export default dataProvider