import React from 'react';
import './toggle.css';

interface toggle_properties {
    checked: boolean,
    handleValueChange: Function,
    disabled: boolean
}





function Toggle(props: toggle_properties) {

    const { checked, handleValueChange, disabled } = props;

    return (
        <div>
            <div className="">
                <label className="switch" >
                    <input
                        onChange={() => handleValueChange()}
                        type="checkbox"
                        className={disabled ? 'toggle_disabled' : ''}
                        checked={checked}
                        disabled={disabled} />
                    <span className={`slider round ${disabled ? 'toggle_disabled' : ''}`}></span>
                </label>
            </div>
        </div>
    )
}

export default Toggle
