import React from 'react';

export const Honors = (props: any) => {

    const { statistics } = props;

    return (
        <div className="row mt-4">
            {statistics?.badges.map((badgeDetails: any) =>
                <div className="profil_badge col-6 col-md-4 col-lg-3 mb-3">
                    <img
                        src={badgeDetails?.image?.sizes.original.url}
                        alt="jelvény"
                        className='w-100'
                    />
                    
                </div>)}
        </div>
    );
};
