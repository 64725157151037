import React from 'react'
import Button from '../../core/button/Button'
import './login_reg.css'
/* import logoEj from '../../assets/img/logo_ej.svg'
import logoBootleGrey from '../../assets/img/bootle_gray.svg'
import logoBootleBlack from '../../assets/img/bootle_black.svg'
import logoBootleRed from '../../assets/img/bootle_red.svg' */
import Input from '../../core/inputs/Input'
import { Container } from 'react-bootstrap'
import './login.scss'
import enjovom_bejelentkezes_csoportkep from '../../assets/img/enjovom_bejelentkezés_csoportkép.jpg'
import emojiApplause from '../../assets/icons/emoji/applause.png';
import authProvider from '../../authProvider';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom'
import { validate } from '../../validate'

/* interface login_props {
    setSelectedPage: React.Dispatch<React.SetStateAction<number>>
} */

function Login(props: any) {
    React.useEffect(() => {
        authProvider.checkAuth() && history.push('/kezelopult')
    });

    const { setAccessToken, modalPopper, closeModal } = props;
    const history = useHistory();
    /* const { setSelectedPage } = props */
    const [email, setEmail] = React.useState('')
    const [pswd, setPswd] = React.useState('')
    const [checked, setChecked] = React.useState(false);
    const [errors, setErrors] = React.useState({
        email: '',
        password: ''
    })

    const handleEmailChange = (emailValue: string) => {
        setEmail(emailValue);
        validate.email(emailValue) && emailValue.length ? setErrors({ ...errors, email: '' }) : setErrors({ ...errors, email: 'Az email cím hibás' })
    }

    const handlePasswordChange = (passwordValue: string) => {
        setPswd(passwordValue);
        // validate.password(passwordValue) && passwordValue.length ? setErrors({ ...errors, password: '' }) : setErrors({ ...errors, password: 'A jelszó hibás' });
    }

    const isValid = errors.email === '' && errors.password === '' && email.length && pswd.length;


    const handleLogin = () => {
        authProvider.login(email, pswd, (apiResponse: any) => {
            if (apiResponse.Error === null) {
                setAccessToken(localStorage.getItem('access_token'))
                history.push('/kezelopult')
            }

            if (apiResponse?.Error?.response?.data?.errors[0]?.code === 'auth:failed') {
                modalPopper(<EmailPswdError closeModal={closeModal} />)
            }
            if (apiResponse?.Error?.response?.data?.errors[0]?.code === 'email:not_confirmed') {
                modalPopper(<NoConfirmError closeModal={closeModal} />)
            }
        })
    }

    return (
        <Container fluid>
            <div className='d-flex flex-lg-row flex-xl-row flex-column-reverse flex-md-column-reverse'>
                <div className='login_container d-flex align-items-start flex-column col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='d-flex align-items-start flex-column'>
                        <div className='d-flex flex-row heading_one login_greetings_container '>
                            <div><img src={emojiApplause} className='login_apllause_emoji' alt="" /></div>
                            <h1 className="login_header_text">Üdv újra itt</h1>
                        </div>
                        <div className='body_large login_folytasd'>
                            Folytasd, ahol abbahagytad
                        </div>
                    </div>
                    <div className='heading_four  login_bejelentkezes'>
                        <h4>Bejelentkezés</h4>
                    </div>
                    <div className='inputs_container col-xl-8 col-lg-8 col-md-12 col-12'>
                        <div className="input_holder">
                            <Input
                                disabled={false}
                                label=''
                                leftIcon='ejicon-envelope-alt'
                                rightIcon={null}
                                value={email}
                                onChangeHandler={(e: any) => handleEmailChange(e.target.value)}
                                error={errors.email}
                                type='text'
                                placeHolder='E-mail cím' />
                        </div>
                        <div className="input_holder">
                            <Input
                                disabled={false}
                                label=''
                                leftIcon='ejicon-lock'
                                rightIcon={null}
                                value={pswd}
                                onChangeHandler={(e: any) => handlePasswordChange(e.target.value)}
                                error={errors.password}
                                type='password'
                                placeHolder='Jelszó' />
                        </div>
                    </div>
                    <div className='d-flex  login_forgot body_small flex-column flex-xl-row flex-md-column flex-lg-row align-middle'>
                        <label className='d-flex flex-row login_stay align-middle container-border'>
                            <input className="form-check-input login_checkbox_1"
                                type="checkbox"
                                checked={checked}
                                onClick={() => setChecked(!checked)} />
                            <div className='login_marad'>Maradj bejelentkezve</div>
                        </label>
                        {/* routing kell majd */}
                        <div /* onClick={() => setSelectedPage(2)} */ className='body_bold login_page_changer align-middle' style={{paddingTop: "14px", paddingLeft: "13px"}}>
                            <Link className='body_bold login_page_changer align-middle' to='/elfelejtett_jelszo'>
                                Elfelejtettem a jelszavam
                            </Link>
                        </div>
                    </div>
                    <div className="login_button">
                        <Button
                            type='primary'
                            disabled={!isValid}
                            onClick={isValid ? () => handleLogin() : () => { }}
                            icon={null}
                            icon_position={null}
                            label='Belépek'

                        />
                    </div>
                    <div className='d-flex align-items-start login_register body_small flex-column flex-xl-row flex-md-column flex-lg-row'>
                        <div className='login_not_registered'>
                            Nincs még fiókod?
                        </div>
                        {/* routing kell majd */}
                        <div /* onClick={() => setSelectedPage(1)} */ className='body_bold login_page_changer'>
                            <Link className='body_bold login_page_changer' to='/regisztracio'>
                                Regisztrálj
                            </Link>
                        </div>
                    </div>

                </div>
                <div className="login_picture_container col-xl-6 col-lg-6 col-md-12 col-12">
                    <Container fluid className='login_picture_container_inside text-end '>
                        <img src={enjovom_bejelentkezes_csoportkep} alt="" className="login_responsive_img" />
                        <div className="login_maximum col-xl-8 col-lg-8 col-md-10 col-10 heading_two">
                            <div className='col login_hozd'>Hozd ki magadból<br />a maximumot!</div>
                        </div>
                    </Container>
                </div>
            </div>
        </Container>
    )
}

const EmailPswdError = (props: any) => {
    const { closeModal } = props
    return (
        <div className='modal-reg-megerosites' onClick={closeModal}>A megadott felhasználónév, vagy jelszó hibás!</div>
    )
}
const NoConfirmError = (props: any) => {
    const { closeModal } = props
    return (
        <div className='modal-reg-megerosites' onClick={closeModal}>Kérjük erősítsd meg a regisztrációdat a kapott email alapján!</div>
    )
}


export default Login
