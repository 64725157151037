/* 
properties tab :
   label: string,
   onClickHandler: Function  ===> szülő komponens kezelőfüggvénye (callback)

properties tablist:
   tabList : Array<tab_properties>
*/

import React from 'react'
import './tab.css'

interface tab_properties {
    label: string,
    onClickHandler: Function
}

interface tablist_properties {
    tabList: Array<tab_properties>
}


function TabList(props: tablist_properties) {

    const { tabList } = props
    const [activeTab, setActiveTab] = React.useState(0);

    const handleTabClick = (indexOfTag: number) => {
        setActiveTab(indexOfTag);
        tabList[indexOfTag].onClickHandler();
    }

    const amIActive = (indexOfTab: number) => {
        return indexOfTab === activeTab ? 'active_tab' : ''
    }
    return (
        <div className='tablist_container tablist_multirow'>
            {tabList.map((tabProperties, index) =>
                <div
                    key={index}
                    className={`tab_container body_bold body_normal ${amIActive(index)}`}
                    onClick={() => handleTabClick(index)}
                >
                    {tabProperties.label}
                </div>
            )}
        </div>
    )
}


export default TabList
