import React from "react";
import {Helmet} from "react-helmet";
import dataProvider from "../../dataProvider";

function PodcastPlayer(props: any) {
	const [podcastDetails, setPodcastDetails] = React.useState<any>({});

	React.useEffect(() => {
		dataProvider.getMany('/podcasts', (response: any) => {
			if (response.status === 200) {
				setPodcastDetails(response?.data?.data || {})
			}
		})
	}, [])
	
	return (
		<>
			{podcastDetails &&
				<div className='buzzsprout-small-player-home justify-content-center'>
					<div id={`buzzsprout-small-player-1721022-${podcastDetails?.buzzsproutId || 9205328}`}></div>
					<Helmet>
						<script type='text/javascript' charSet='utf-8'
								src={`https://www.buzzsprout.com/1721022/${podcastDetails?.buzzsproutId || 9205328}.js?player=small&container_id=buzzsprout-small-player-1721022-${podcastDetails?.buzzsproutId || 9205328}`}></script>
					</Helmet>
				</div>
			}
		</>
	)
}

export default PodcastPlayer;