import React from 'react';
import './radio_button.css';

interface radio_button_properties {
    checked: boolean,
    disabled: boolean,
    label: string,
    rightIcon: string | null,
    simple: boolean
}




function RadioButton(props: radio_button_properties) {

    const { checked, disabled, label, rightIcon, simple } = props


    return (
        <div className="body_normal radio_button">
            {simple
                ?
                <SimpleButton checked={checked} disabled={disabled} />
                :
                <div className={`
                                 d-flex 
                                 flex-row 
                                 justify-content-start
                                 big_radio_button_container 
                                 container 
                                 p-2  
                                 mx-auto
                                 ${disabled ? 'radio_button_disabled' : checked ? 'radio_button_checked' : 'radio_button_unchecked'}
                                `}>
                    <SimpleButton checked={checked} disabled={disabled} />
                    {label ? <div className='mx-2'>{label}</div> : null}
                    {rightIcon ? <div><span className={`${rightIcon} mx-2 right_icon_${disabled ? 'grey' : 'red'}`} /></div> : null}
                </div>
            }
        </div>
    )
}



const SimpleButton = (props: any) => {

    const { checked, disabled } = props;


    return (
        <div className={`
        radio_button_container
        d-flex
        justify-content-center
        mx-2
        radio_button_${checked && !disabled ? 'red' : 'grey'}
        `}>
            {checked ? <span className={`ejicon-smiley centered_circle ${disabled ? 'circle_grey' : 'circle_red'}`} /> : null}
        </div>
    )

}



export default RadioButton


