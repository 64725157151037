import React from 'react'
import courseSlidesNoteBookBig from "../../assets/img/course_slides/course_slides_notebook_big.png"
import courseDetailCheckGreen from "../../assets/img/course_detail/check_green.svg";
import courseDetailCheckGrey from "../../assets/img/course_detail/check_grey.svg";
import Circle from '../../core/circle/Circle';
import Button from '../../core/button/Button';
import LinkWithIcon from '../../core/link/LinkWithIcon';
import StarLine from '../../core/star/StarLine';

interface modal_properties {
    handleModalStateChange: Function;
}



function CS_Modal(props: modal_properties) {

    const { handleModalStateChange } = props;
    const [isSideInfoOpened, setSideInfoStatus] = React.useState(true);

    return (
        <div className="course_slide_modal_container">
            <div className="course_slide_modal_side_info_container">
                <h2 className="d-none d-lg-block">Kommunikációs tippek</h2>
                <div className="mt-auto">
                    <h3 className="text-cc-primary mb-4">Interaktív tréning</h3>
                    <h1 className="course_slide_title mb-5">Üzleti készségek Projektmenedzsment</h1>
                    <div className="row mb-5 d-none d-lg-flex">
                        <div className="col-6 mb-5">
                            <h3 className="text-cc-primary mb-2">Lorem ipsum</h3>
                            <h1 className="course_slide_title mb-0">8,7%</h1>
                        </div>
                        <div className="course_slide_title col-6 mb-5">
                            <h3 className="text-cc-primary mb-2">Dolorsit</h3>
                            <h1 className="course_slide_title mb-0">3,477</h1>
                        </div>
                    </div>
                </div>
                <div className={`course_slide_modal_sidebar ${!isSideInfoOpened ? 'side_info_closed' : ''}`}>
                    {/* 1. variáció, a Nyitott side info, vagy  Bezárt side info helyére kerülhet html*/}
                    {/* 2. variáció osztály megváltoztatása (side_info_closed hozzáadásával vagy levételével) */}
                    <div className="sidebar-open-close" onClick={() => setSideInfoStatus(!isSideInfoOpened)}>
                        {isSideInfoOpened ? <LinkWithIcon icon='ejicon-arrow-left' linkTo='#' label='Elrejtés' active={false} /> : <LinkWithIcon linkTo='#' icon='ejicon-arrow-right' label='Kinyitás' active={false} />}
                    </div>
                    <div className="d-block d-lg-none">
                        <h2>Kommunikációs tippek</h2>
                        <StarLine
                            rating={3}
                            disabled={true}
                        />
                    </div>
                    <h5 className="mt-4 mb-4">Tananyag felépítése</h5>
                    <ul className="list-style-none mb-5">
                        <li className="mb-3">
                            <div className="d-flex flex-row align-items-center">
                                <img src={courseDetailCheckGreen} alt="" /><span className="mx-2">1. lecke</span><strong>Bevezetés</strong>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="d-flex flex-row align-items-center">
                                <img src={courseDetailCheckGreen} alt="" /><span className="mx-2">2. lecke</span><strong>Lorem ipsum dolor</strong>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="d-flex flex-row align-items-center">
                                <img src={courseDetailCheckGreen} alt="" /><span className="mx-2">3. lecke</span><strong>Nunc posuere</strong>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="d-flex flex-row align-items-center">
                                <img src={courseDetailCheckGreen} alt="" /><span className="mx-2">4. lecke</span><strong>Bibendum sodales</strong>
                            </div>
                        </li>
                        <li className="mb-3">
                            <div className="d-flex flex-row align-items-center">
                                <img src={courseDetailCheckGrey} alt="" /><span className="mx-2">5. lecke</span><strong>Összefoglalás</strong>
                            </div>
                        </li>
                        <li className="mb-0">
                            <div className="d-flex flex-row align-items-center">
                                <img src={courseDetailCheckGrey} alt="" /><span className="mx-2">6. lecke</span><strong>Lorem ipsum dolor</strong>
                            </div>
                        </li>
                    </ul>
                    <Button type='secondary' label='Később folytatom' disabled={false} icon='ejicon-pause' icon_position='left' />
                </div>
                <div className="course_slide_modal_nav p-4">
                    <div className="d-flex flex-row align-items-center justify-content-center">
                        <Circle icon='ejicon-arrow-right' disabled={false} type="red" />
                        <span className="mx-4">1/24</span>
                        <Circle icon='ejicon-arrow-left' disabled={false} type="red" />
                    </div>
                </div>
                <div className="course_slide_modal_close">
                    <div className="course_slide_modal_close_button" onClick={() => handleModalStateChange()}>
                        <Circle icon='ejicon-fullscreen' disabled={false} type="white" />
                    </div>
                </div>

            </div>
            <img className="course_slide_modal_main-pic" src={courseSlidesNoteBookBig} alt="" />
        </div>
    )
}





export default CS_Modal
