import React from 'react';
import Button from '../../core/button/Button';
import Input from '../../core/inputs/Input';
import { countiesList, agesList, engHunGenderTranslator, educationList } from '../../constants/userData';
import DropInput from '../../core/inputs/DropInput';
import RadioButton from '../../core/radio_button/RadioButton';
import authProvider from '../../authProvider';

/* const fakeApiData: user_profile = {
    "lastName": "Benedek",
    "firstName": "Horvát",
    "age": "26-30",
    "gender": "male",
    "region": "Budapest",
    "education": "középiskola",
    "jobPresent": true,
    "jobInPast": true,
    "email": "benedek.horvat@gmail.com",
    "acceptEmails": false
} */

export const PersonalData = (props: any) => {
    const { userProfile, setUserProfile } = props
    const [isInEditMode, setIsInEditMode] = React.useState(false);
    /* const [userProfile, setUserProfile] = React.useState(emptyProfile); */

    return (
        <div className="card mt-4">
            <div className="card-body p-4">
                {
                    isInEditMode
                        ?
                        <EditUserData
                            userProfile={userProfile}
                            setIsInEditMode={setIsInEditMode}
                            setUserProfile={setUserProfile}
                        />
                        :
                        <SavedUserData
                            userProfile={userProfile}
                            setIsInEditMode={setIsInEditMode}
                        />
                }
            </div>
        </div>
    );
};

interface user_profile {
    lastName: string,
    firstName: string,
    age: string,
    gender: string,
    region: string,
    education: string,
    jobPresent: boolean,
    jobInPast: boolean,
    email: string,
    acceptEmails: boolean,
    emailVerifiedAt?: Date | null,
    profilePictureUrl?: string | null,
    lastSchool?: string | null

}

interface saved_user_data {
    userProfile: user_profile,
    setIsInEditMode: React.Dispatch<React.SetStateAction<boolean>>
}


const SavedUserData = (props: saved_user_data) => {

    const { userProfile, setIsInEditMode } = props;

    return (
        <>
            <div className="row mb-4">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Név</p>
                </div>
                <div className="col-12 col-lg-8">
                    <p className="mb-0">{`${userProfile.lastName} ${userProfile.firstName}`}</p>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Lakhely</p>
                </div>
                <div className="col-12 col-lg-8">
                    <p className="mb-0">{`${userProfile.region}`}</p>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Kor</p>
                </div>
                <div className="col-12 col-lg-8">
                    <p className="mb-0">{userProfile.age}</p>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Nem</p>
                </div>
                <div className="col-12 col-lg-8">
                    <p className="mb-0">{engHunGenderTranslator(userProfile.gender)}</p>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Iskolai végzettség</p>
                </div>
                <div className="col-12 col-lg-8">
                    <p className="mb-0">{userProfile.education}</p>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Legutóbbi munkahely</p>
                </div>
                <div className="col-12 col-lg-8">
                    <p className="mb-0">
                        {`${userProfile.jobPresent ? 'Van' : 'Nincs jelenleg'} munkahelyem, régebben
                            ${userProfile.jobPresent ? (userProfile.jobInPast ? 'is' : 'nem') : (userProfile.jobInPast ? '' : 'sem')} volt.`}
                    </p>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">E-mail cím</p>
                </div>
                <div className="col-12 col-lg-8">
                    <p className="mb-0">{userProfile.email}</p>
                </div>
            </div>

            <div onClick={() => setIsInEditMode(true)}>
                <Button
                    type='secondary'
                    label='Profil szerkesztése'
                    disabled={false}
                    icon='ejicon-edit'
                    icon_position='left' />
            </div>
        </>
    )
}

const EditUserData = (props: any) => {

    const { userProfile, setUserProfile, setIsInEditMode } = props;
    const [password, setPassword] = React.useState('');
    const [liveUserData, setLiveUserData] = React.useState(userProfile);
    const [manualEducationSelection, setManualEducationSelection] = React.useState(false);

    const baseGenderButtons = [
        {
            label: 'female',
            rightIcon: 'ejicon-women',
            checked: userProfile.gender === 'female'
        },
        {
            label: 'male',
            rightIcon: 'ejicon-men',
            checked: userProfile.gender === 'male'
        },
        {
            label: 'NA',
            rightIcon: 'ejicon-men',
            checked: userProfile.gender === 'NA'
        },

    ]

    const [genderRadioButtons, setGenderRadioButtons] = React.useState(baseGenderButtons)

    const tryToSendModifiedData = () => {
        /* setIsInEditMode(false); */
        const { emailVerifiedAt, profilePictureUrl, lastSchool, ...data } = liveUserData;
        console.log('Data', data);

        data.password = password;
        authProvider.updateUserProfile(data, (response: any) => {
            try {
                if (response.status === 200) {
                    setUserProfile(liveUserData)
                    setIsInEditMode(false)
                }
            } catch (error) {
                console.error(error);
            }
        })
    }


    const handleInputChange = (key: string, value: any): void => {
        let newValues = { ...liveUserData }
        newValues[key] = value;
        setLiveUserData(newValues);
        console.log(liveUserData)
    }

    const handleGenderChange = (index: number) => {
        let newGenderArray = genderRadioButtons;
        newGenderArray = newGenderArray.map((buttonProps, buttonIndex) => ({ ...buttonProps, checked: index === buttonIndex }))
        handleInputChange('gender', genderRadioButtons[index].label);
        setGenderRadioButtons(newGenderArray);
    }


    const handleEducationDrop = (targetValue: any) => {
        if (targetValue === 'egyéb, kérjük add meg') {
            setManualEducationSelection(true);
        }
        else {
            handleInputChange('education', targetValue)
        }
    }


    return (
        <>
            <div className="row mb-4 align-items-center">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Név</p>
                </div>
                
                <div className="col-12 col-lg-3">
                    <Input
                        disabled={false}
                        label=''
                        value={liveUserData.lastName}
                        leftIcon=''
                        rightIcon=''
                        onChangeHandler={(e: any) => handleInputChange('lastName', e.target.value)}
                        error=''
                        type='text'
                        placeHolder='' />
                </div>
                <div className="col-12 col-lg-3">
                    <Input
                        disabled={false}
                        label=''
                        value={liveUserData.firstName}
                        leftIcon=''
                        rightIcon=''
                        onChangeHandler={(e: any) => handleInputChange('firstName', e.target.value)}
                        error=''
                        type='text'
                        placeHolder='' />
                </div>



            </div>
            <div className="row mb-4 align-items-center">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Lakhely</p>
                </div>
                <div className="col-12 col-lg-3">
                    <DropInput
                        disabled={false}
                        leftIcon=''
                        label=''
                        type='radio'
                        onChangeHandler={(selectionArray: any) => handleInputChange('region', selectionArray[0])}
                        dropList={countiesList}
                        initialSelection={[liveUserData.region]}
                    />
                </div>
            </div>
            <div className="row mb-4 align-items-center">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Kor</p>
                </div>
                <div className="col-12 col-lg-3">
                    <DropInput
                        disabled={false}
                        leftIcon=''
                        label=''
                        type='radio'
                        onChangeHandler={(selectionArray: any) => handleInputChange('age', selectionArray[0])}
                        dropList={agesList}
                        initialSelection={[liveUserData.age]}
                    />
                </div>
            </div>
            <div className="row mb-4 align-items-center">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Nem</p>
                </div>

                {genderRadioButtons.map((genderButton, index) =>
                    <div
                        onClick={(e: any) => handleGenderChange(index)}
                        className={`col-12 col-lg-${index === 2 ? '5' : '2'}`}
                        key={`gender-${index}`}
                    >
                        <RadioButton
                            simple={false}
                            checked={genderButton.checked}
                            disabled={false}
                            label={engHunGenderTranslator(genderButton.label)}
                            rightIcon={null}
                        />
                    </div>

                )}
            </div>
            <div className="row mb-4 align-items-center">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Iskolai végzettség</p>
                </div>
                <div className="col-12 col-lg-5">
                    {manualEducationSelection
                        ?
                        <Input
                            disabled={false}
                            label=''
                            value={liveUserData.education}
                            leftIcon=''
                            rightIcon=''
                            onChangeHandler={(e: any) => handleInputChange('education', e.target.value)}
                            error=''
                            type='text'
                            placeHolder='' />
                        :
                        <DropInput
                            disabled={false}
                            leftIcon=''
                            label=''
                            type='radio'
                            onChangeHandler={(selectionArray: any) => handleEducationDrop(selectionArray[0])}
                            dropList={educationList}
                            initialSelection={[liveUserData.education]}
                        />


                    }


                </div>
            </div>

            <div className="row mb-4 align-items-center">
                <div className='col-12 col-lg-3'>
                    <div className="mb-0 text-muted">Jelenleg van munkahelyed?</div>
                </div>
                <div className='col-12 col-lg-2'>
                    <div
                        onClick={() => handleInputChange('jobPresent', true)}
                        className=" mb-2"
                    >
                        <RadioButton
                            simple={false}
                            checked={liveUserData.jobPresent}
                            disabled={false}
                            label='Van'
                            rightIcon={null}
                        />
                    </div>
                    </div>
                <div className='col-12 col-lg-2'>
                    <div onClick={() => handleInputChange('jobPresent', false)}
                        className=" mb-2"
                    >
                        <RadioButton
                            simple={false}
                            checked={!liveUserData.jobPresent}
                            disabled={false}
                            label='Nincs'
                            rightIcon={null}
                        />
                    </div>
                </div>
            </div>
            
            <div className="row mb-4 align-items-center">
                <div className='col-12 col-lg-3'>
                    <div className="mb-0 text-muted">Korábban volt munkahelyed?</div>
                </div>
                
                <div className='col-12 col-lg-2'>
                    <div
                        onClick={() => handleInputChange('jobInPast', true)}
                        className="mb-2 mr-2"
                    >
                        <RadioButton
                            simple={false}
                            checked={liveUserData.jobInPast}
                            disabled={false}
                            label='Volt'
                            rightIcon={null}
                        />
                    </div>
                </div>
                <div className='col-12 col-lg-2'>
                    <div
                        onClick={() => handleInputChange('jobInPast', false)}
                        className="mb-2"
                    >
                        <RadioButton
                            simple={false}
                            checked={!liveUserData.jobInPast}
                            disabled={false}
                            label='Még nem volt'
                            rightIcon={null}
                        />
                    </div>
                </div>
            </div>
            

            <div className="row mb-4 align-items-center">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">E-mail cím</p>
                </div>
                <div className="col-12 col-lg-5">
                    <Input
                        disabled={false}
                        label=''
                        value={liveUserData.email}
                        leftIcon=''
                        rightIcon=''
                        onChangeHandler={(e: any) => handleInputChange('email', e.target.value)}
                        error=''
                        type='text'
                        placeHolder='' />
                </div>
            </div>
            <div className="row mb-4 align-items-center">
                <div className="col-12 col-lg-3">
                    <p className="mb-0 text-muted">Jelszó</p>
                </div>

                <div className="col-12 col-lg-5">
                    <Input
                        disabled={false}
                        label=''
                        value={password}
                        leftIcon=''
                        rightIcon=''
                        onChangeHandler={(e: any) => setPassword(e.target.value)}
                        error=''
                        type='PassWord'
                        placeHolder='' />
                </div>
            </div>
            <div onClick={tryToSendModifiedData}>
                <Button
                    type='primary'
                    label='Mentés'
                    disabled={false}
                    icon=''
                    icon_position='left' />
            </div>
        </>
    )
}