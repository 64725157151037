/*props
icon : icon_name || null
type: white, bordered, red
disabled: true | false
*/
import React from 'react'

function Circle(props: any) {

    const { icon, type, disabled } = props;
    const disabledClass: string = disabled ? 'circle_disabled' : '';
    return (
        <button className={`circle circle_${type} ${disabledClass}`}>
            <span className={icon} />
        </button>
    )
}

export default Circle
