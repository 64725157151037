import React from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { FacebookShareButton } from 'react-share';

import './course_detail.css';
import '../../components/modal/modal.css'

import courseDetailYouTube from "../../assets/img/course_detail/course_detail_youtube.png";
import courseDetailYouTube_play from "../../assets/img/course_detail/play_icon.png";
import courseDetailCheckGreen from "../../assets/img/course_detail/check_green.svg";
import courseDetailCheckGrey from "../../assets/img/course_detail/check_grey.svg";
import courseDetailClock from "../../assets/img/course_detail/clock.svg";
import courseDetailStudent from "../../assets/img/course_detail/student.svg";
import courseDetailBulb from "../../assets/img/course_detail/bulb.svg";
import sampleImg from '../../assets/img/modul_sample_bg.png'

import StarLine from '../../core/star/StarLine';
import TabList2 from '../../core/tab/TabList2';
import Input from '../../core/inputs/Input';
import Button from '../../core/button/Button';
import Circle from '../../core/circle/Circle'
import Tag from '../../core/tag/Tag'

import ModulCard from '../../components/modul/ModulCard';

import dataProvider from '../../dataProvider';
import authProvider from '../../authProvider';


interface course_details {
    audiences: Array<any>,
    bookmarked: boolean,
    completedUnits: Array<any>,
    courseMaterials: Array<any>,
    id: number,
    length: number,
    cover: any,
    longDescription: string,
    path: string,
    rating: number,
    reviewsCount: number,
    shortDescription: string,
    slug: string,
    students: number,
    subscribed: boolean,
    tags: Array<any>,
    title: string,
    units: Array<any>,
    type: string
}

const emptyDetails = {
    audiences: [],
    bookmarked: false,
    completedUnits: [],
    courseMaterials: [],
    id: 0,
    length: 0,
    cover: {},
    longDescription: '',
    path: '',
    rating: 0,
    reviewsCount: 0,
    shortDescription: '',
    slug: '',
    students: 0,
    subscribed: false,
    tags: [],
    title: '',
    units: [],
    type: ''
}

function CourseDetail(props: any) {

    const { userProfile, courseList, modalPopper, closeModal, refreshCourseList } = props;

    const history = useHistory();
    const params: any = useParams()
    const id = params.id;

    const [labelVagolap, setLabelVagolap] = React.useState('');
    const [selectedTab, setSelectedTab] = React.useState(0);
    const [isCompact, setIsCompact] = React.useState(false);
    const [courseDetails, setCourseDetails] = React.useState<course_details>(emptyDetails)
    const [percentage, setPercentage] = React.useState(0);
    const [curriculumAddress, setCurriculumAddress] = React.useState('');
    const [comletedUnitsNumber, setCompletedUnitsNumber] = React.useState(0);
    const [showCourseSlide, setShowCourseSlide] = React.useState(false);
    const [certificate, setCertificate] = React.useState<any>(null);
    const [recommendedList, setRecommendedList] = React.useState<any[]>([])

    const iframeRef = React.createRef<HTMLIFrameElement>()

    const navigationLinks = [
        {
            icon: 'ejicon-book',
            label: 'Képzés adatok',
            linkTo: {
                state: 0
            },
            onClickHandler: () => {}
        },
        {
            icon: 'ejicon-download',
            label: 'Segédanyagok',
            linkTo: {
                state: 1
            },
            onClickHandler: () => {}
        }, {
            icon: 'ejicon-star',
            label: 'Értékelések',
            linkTo: {
                state: 2
            },
            onClickHandler: () => {}
        },
    ]

    React.useEffect(() => {
        setSelectedTab(typeof history.location.state === 'number' ? history.location.state : 0);
    }, [history.location.state]);

    React.useEffect(() => {
        dataProvider.getOne('/courses', id, '', (response: any) => {
            try {
                console.log(response?.data?.data)
                setCourseDetails(response.data.data);
                setPercentage(response?.data?.data?.progress?.progress || 0)
                setCompletedUnitsNumber(response?.data?.data?.completedUnits.length || 0)
            } catch (error) {
                console.error(error)
            }
        })
        dataProvider.getOne('/courses', id, '/certificate', (response: any) => {
            if (response?.status === 200) {
                console.log(response.data)
                const fileURL = response?.data?.data?.url;
                setCertificate(fileURL)
            }
        })

        return () => refreshCourseList();
    }, [id])


    React.useEffect(() => {
        const newRecommendedList: course_details[] = []
        courseList.forEach((courseDetails: any) => {
            if (courseDetails.slug == id) {
                setCurriculumAddress(courseDetails.path)
            }
            if (id !== courseDetails.slug && !courseDetails.bookmarked && !courseDetails.subscribed) {
                newRecommendedList.push(courseDetails)
            }
        })
        setRecommendedList(newRecommendedList);
    }, [courseList, id])

    const refreshCourseData = () => {
        dataProvider.getOne('/courses', id, '', (response: any) => {
            try {
                setCourseDetails(response.data.data);
                setCurriculumAddress(courseDetails.path);
                setPercentage(response?.data?.data?.progress?.progress || 0);
                if (percentage === 100) {
                    dataProvider.getOne('/courses', id, '/certificate', (response: any) => {
                        if (response?.status === 200) {
                            console.log(response.data)
                            const fileURL = response?.data?.data?.url;
                            setCertificate(fileURL)
                        }
                    })
                }
            } catch (error) {
                console.error(error)
            }
        })
    }

    const calculateTimeHHMM = () => {
        const TIME = courseDetails.length;
        const HOURS = TIME > 59 ? `${Math.floor(TIME / 60).toString()} óra ` : '';
        const MINUTES = `${TIME % 60} perc`
        return HOURS + MINUTES;
    }

    const toggleBookmarked = () => {
        if (id) {
            dataProvider.updateSpecial('/courses', id, 'bookmark', {}, () => { });

        }
        refreshCourseData()
    }

    const getCertificate = () => {
        const tryGetCertificate = () => {
            dataProvider.getOne('/courses', id, '/certificate', (response: any) => {
                if (response?.status === 200) {
                    const fileURL = response?.data?.data?.url;
                    fileURL && setCertificate(fileURL);
                    !fileURL && console.log('wait 500ms')
                    !fileURL && setTimeout(() => {
                        tryGetCertificate()
                    }, 500);
                } else {
                    console.log('wait 500ms')
                    setTimeout(() => {
                        tryGetCertificate()
                    }, 500);
                }
            })
        }
        setTimeout(() => {
            console.log('Try get cert')
            tryGetCertificate()
        }, 200);
    }

    const handleCourseSelect = (courseId: number | string) => {
        history.push(`/tananyagok/${courseId}`);
        refreshCourseData();
    }

    const getPageInfo = (lastSlideVisited: number) => {
        console.log('pageInfo triggered')
        const values = ['MenuSectionNumber', 'MenuSectionViewed', 'MenuSlidesViewed', 'MenuTotalSlides', 'SlidesViewed', 'TotalSlides', 'SceneSlideNumber']
        let iframe: any = document.getElementById('iframe_slide')
        try {
            if (iframe) {
                const CW = iframe.contentWindow
                console.log(CW)
                try {
                    let composedData: any = {};
                    values.forEach((selector: string) => composedData[selector.charAt(0).toLowerCase() + selector.slice(1)] = CW.GetPlayer().GetVar(selector))
                    let progress = composedData.menuTotalSlides > 0 ? Math.round(((composedData.menuSlidesViewed / composedData.menuTotalSlides) * 100)) : 0
                    /* const progress = composedData.menuSectionNumber > 0 ? Math.round(((composedData.menuSectionViewed / composedData.menuSectionNumber) * 100)) : 0 */
                    console.log('4 tests true, NaN, false, true')
                    console.log(courseDetails.title === 'Önismereti teszt')
                    console.log(composedData.menuSectionNumber)
                    console.log(composedData.menuSectionNumber !== 0)
                    console.log(!composedData.menuSectionNumber)
                    if (courseDetails.title === 'Önismereti teszt' && composedData.menuSectionNumber !== 0 && !composedData.menuSectionNumber) {
                        progress = 100
                        composedData.menuSectionNumber = 2
                    }
                    composedData.progress = progress;
                    if (progress === 100 && !certificate && courseDetails.type === 'course') {
                        getCertificate()
                    }
                    setPercentage(progress || 0)
                    if (composedData.menuSectionViewed >= 1) {
                        setCompletedUnitsNumber(progress === 100 ? composedData.menuSectionViewed : composedData.menuSectionViewed - 1 || 0)
                    }
                    console.log(composedData)
                    if (composedData.slidesViewed > lastSlideVisited) {
                        try {
                            dataProvider.updateSpecial('/courses', id, 'progress', composedData, (response: any) => console.log(response))
                        } catch (error) {
                            console.log('API error: ' + error)
                        }
                    } else {
                        console.log('already viewed')
                    }
                    setTimeout(() => {
                        getPageInfo(Math.max(composedData.slidesViewed, lastSlideVisited))
                    }, 1000);

                } catch (error) {
                    console.log('Cannot get values')
                    setTimeout(() => {
                        getPageInfo(lastSlideVisited)
                    }, 1000);
                }
            }
            else {
                console.log('No iframe detected!' + new Date().getTime())
            }
        } catch (error) {
            console.log('get CW failed')
            setTimeout(() => {
                getPageInfo(lastSlideVisited)
            }, 1000);
        }
    }

    const setWatcher = () => {
        console.log('version: 1.01');
        getPageInfo(-1);
    }

    let myLink = window.location.href;
    let myLinkEdin = `https://www.linkedin.com/sharing/share-offsite/?url=${myLink}`
    const copyMylink = () => {
        navigator.clipboard.writeText(myLink);
        setLabelVagolap("link másolva");
    }
    return (
        <>
            <div className="container mt-5">
                <div className={`${percentage < 100 ? '' : 'card'}`}>
                    <div className={`${percentage < 100 ? '' : 'card-body p-4_5'}`}>
                        <div className="row justify-content-between">
                            {/* teljesített tananyag header*/}
                            {percentage < 100
                                ?
                                null
                                :
                                <div className="d-flex flex-column col-12">
                                    <div className="d-flex flex-row align-items-start align-items-lg-center mb-4">
                                        <div className="d-flex me-auto flex-column flex-lg-row align-items-start align-items-lg-center">
                                            <div className="d-flex flex-row align-items-start align-items-lg-center mx-2">
                                                <img className="me-2 mt-2 mt-lg-0" src={courseDetailCheckGreen} alt="" />
                                                <h2 className="mb-0">{courseDetails.title}</h2>
                                            </div>
                                            <div>
                                                <div style={{ float: 'left' }}>
                                                    <StarLine
                                                        rating={courseDetails.rating}
                                                        disabled={true}
                                                    />
                                                </div>
                                                <div style={{ float: 'left', paddingTop: '1px' }}>
                                                    ({courseDetails.reviewsCount})
                                                </div>

                                            </div>
                                        </div>
                                        <div className="d-flex flex-row align-items-center">
                                            <span
                                                className={`mt-2 mt-lg-0 me-2 me-lg-0 open-course-arrow ejicon-chev-${isCompact ? 'down' : 'up'} tick_to_compact`}
                                                onClick={() => setIsCompact(!isCompact)}
                                            />
                                        </div>
                                    </div>
                                    <div className='row justify-content-between bordered-top pt-4'>
                                        <div className="col-12 col-lg-7">
                                            <h4>🎉 Gratulálunk! Sikeresen elvégezted a képzést.</h4>
                                            {/* <p className="text-muted">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod nulla eget nunc posuere, vitae porttitor velit posuere. </p> */}
                                        </div>
                                        <div className="col-12 col-lg-4 d-flex align-items-start justify-content-end download-my-document">
                                            <div className="" >
                                                {certificate && courseDetails.type === 'course' &&
                                                    <a href={certificate} target='_blank'>
                                                        <Button
                                                            type='secondary'
                                                            label='Letöltöm az oklevelem'
                                                            disabled={false}
                                                            icon='ejicon-document-sign'
                                                            icon_position='left'
                                                        />
                                                    </a>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* Kompakt nézetnél ez nem kell */}
                            {isCompact ?
                                null :
                                <>
                                    <div className="col-12 col-lg-7">
                                        {/* alsó fejléc váltása teljesített / nem teljesített modulnál */}
                                        {percentage < 100
                                            ?
                                            <div className="d-flex flex-row align-items-start align-items-md-center mb-4">
                                                <div className="me-3 me-md-auto">
                                                    <h2 className="mb-0">{courseDetails.title} </h2>
                                                    <div>
                                                        <div style={{ float: 'left' }}>
                                                            <StarLine
                                                                rating={courseDetails.rating}
                                                                disabled={true}
                                                            />
                                                        </div>
                                                        <div style={{ float: 'left', paddingTop: '1px' }}>
                                                            ({courseDetails.reviewsCount})
                                                        </div>

                                                    </div>
                                                </div>
                                                <div onClick={toggleBookmarked} className={`${courseDetails.bookmarked ? '' : 'not_bookmarked'}`}>
                                                    {
                                                        authProvider.checkAuth() &&
                                                        <Circle icon={`ejicon-bookmark`} disabled={false} type="white" />
                                                    }
                                                </div>

                                            </div>
                                            : null
                                        }

                                        <div className={`course-video-container mt-4 ratio ratio-16x9`}>
                                            {showCourseSlide &&
                                                <>

                                                    <div className="w-100 ratio ratio-16x9">
                                                        <iframe
                                                            className='w-100 h-100'
                                                            src={`${courseDetails?.path}`}
                                                            title='course_slide'
                                                            onLoad={setWatcher}
                                                            ref={iframeRef}
                                                            id='iframe_slide'
                                                        /* style={{ width: slideDimensions.width, height: slideDimensions.height }} */
                                                        >
                                                        </iframe>
                                                    </div>
                                                </>
                                            }
                                            <img
                                                className="w-100 ratio ratio-16x9"
                                                src={courseDetails?.cover?.sizes?.course?.url || courseDetailYouTube}
                                                alt=""
                                                id="course_picture"
                                                style={{ display: showCourseSlide ? 'none' : 'block' }}
                                            />
                                            {/* Csak akkor kell a progress, ha nincs kész a kurzus */}
                                            {authProvider.checkAuth() && !showCourseSlide &&
                                                <img className="play" src={courseDetailYouTube_play} alt="" onClick={() => setShowCourseSlide(true)} />
                                            }

                                        </div>
                                        {percentage < 100 && authProvider.checkAuth() ?
                                            <div className="d-flex mt-3 mb-3">
                                                <div className='course_completion'>
                                                    <div className="course_completion_max">
                                                        <div className="course_completion_status" style={{ "width": `${percentage}%` }} >
                                                        </div>
                                                    </div>
                                                    <div className="course_completion_no body_tiny">
                                                        {percentage} %
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        <div className="card course-tab-container">
                                            <div className="card-body px-4 pb-0 pe-0 pe-lg-4 border-bottom">
                                                <TabList2
                                                    linkList={navigationLinks}
                                                    activeLink={selectedTab}
                                                />
                                            </div>
                                            <div className="card-body p-4">
                                                {/* A választott Tab szerinti renderelés */}
                                                {selectedTab === 0
                                                    ? <CourseData description={courseDetails.longDescription} />
                                                    : selectedTab === 1
                                                        ? <Materials courseMaterials={courseDetails.courseMaterials} />
                                                        : <Ratings
                                                            userProfile={userProfile}
                                                            courseId={id}
                                                            modalPopper={modalPopper}
                                                            closeModal={closeModal}
                                                        />
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`col-12 col-lg-4 pt-4 ${percentage < 100 ? 'p-4 course_detail-sidebar' : ''}`}>
                                        <h5>Tananyag felépítése</h5>
                                        <div className="card">
                                            <div className="card-body p-4">
                                                <ul className="list-style-none">
                                                    {courseDetails.units.map((unitDetails, index) =>
                                                        <li className="mb-3" key={`unit-${index}`}>
                                                            <div className="d-flex align-items-start flex-row">
                                                                <img src={comletedUnitsNumber && (index < comletedUnitsNumber) ? courseDetailCheckGreen : courseDetailCheckGrey} alt="" className="me-2" />
                                                                <p className="mb-0">
                                                                    <span className="me-2">{unitDetails.unitNumber}</span><strong>{unitDetails.title}</strong>
                                                                </p>
                                                            </div>
                                                        </li>
                                                    )}
                                                </ul>
                                            </div>
                                        </div>
                                        <h5 className="mt-5">Tananyag információk</h5>
                                        <div className="card">
                                            <div className="card-body p-4">
                                                <ul className="list-style-none">
                                                    <li className="mb-3">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <img src={courseDetailClock} alt="" className="me-2" />
                                                            <p className="mb-0">
                                                                <strong className="me-2">
                                                                    {calculateTimeHHMM()}
                                                                </strong><span>alatt elvégezhető</span>
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li className="mb-3">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <img src={courseDetailStudent} alt="" className="me-2" />
                                                            <p className="mb-0">
                                                                <strong className="me-2">{courseDetails.students}</strong><span>tanuló végezte el</span>
                                                            </p>
                                                        </div>
                                                    </li>
                                                    <li className="mb-0">
                                                        <div className="d-flex flex-row align-items-start">
                                                            <img src={courseDetailBulb} alt="" />
                                                            <div className="ms-2">
                                                                <p className="mb-2">A képzéssel elsajátítható készségek:</p>
                                                                {
                                                                    courseDetails.tags.map((tagDetails: any) =>
                                                                        <span className="d-flex me-2 mb-2" key={tagDetails.name}>
                                                                            <Tag
                                                                                color='orange'
                                                                                label={tagDetails.name}
                                                                                leftIcon={false}
                                                                                icon='ejicon-communication'
                                                                                rightIcon={false}
                                                                                removeTag={() => { }}
                                                                            />
                                                                        </span>
                                                                    )
                                                                }
                                                            </div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <h5 className="mt-5">Kiknek ajánljuk a képzést?</h5>
                                        <div className="card">
                                            <div className="card-body p-4">
                                                <ul className="list-style-none">
                                                    {courseDetails.audiences.map((audienceDetails) =>
                                                        <li className="mb-3" key={audienceDetails.title}>
                                                            <div className="d-flex flex-row align-items-center">
                                                                {audienceDetails.icon ? <img src={audienceDetails.icon} alt="" /> : null}
                                                                <span className="ms-2">{audienceDetails.title}</span>
                                                            </div>
                                                        </li>
                                                    )
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                        <h5 className="mt-5">Ha tetszett, oszd meg ismerőseiddel is!</h5>
                                        <div className='d-flex flex-row justify-content-left mt-3'>
                                            <div className='me-3 share_button'>
                                                <FacebookShareButton
                                                    url={myLink}
                                                    quote={courseDetails.title}
                                                    hashtag="#énjövőm"
                                                >
                                                    <Tag color='red' label='' leftIcon={true} icon='ejicon-facebook' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                                </FacebookShareButton>

                                            </div>
                                            <div className='me-3 share_button'>
                                                <a href={myLinkEdin} target='_blank'>
                                                    <Tag color='red' label='' leftIcon={true} icon='ejicon-linkedin' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                                </a>
                                            </div>
                                            <div className='me-3 share_button'>
                                                <Link to='#' onClick={copyMylink} title="Hivatkozás másolása" >
                                                    <Tag color='red' label={labelVagolap} leftIcon={true} icon='ejicon-link-chain' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                                </Link>

                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
                {/* Ide jönnek teljesített kurzus esetén az ajánlások */}
                <div>
                    {percentage < 100
                        ?
                        null
                        :
                        <div className=''>
                            <h3 className="mb-4 mt-5">Folytatnád a tanulást? Ezeket a képzéseket ajánljuk Neked</h3>
                            <div className='row'>
                                {recommendedList.map((recommendedCourseDetails: any, index: number) =>
                                    index < 3 &&
                                    <div className='col-12 col-md-6 col-lg-4 col-xl-4 mb-4' key={`rec-${index}`}>
                                        <ModulCard
                                            title={recommendedCourseDetails.title}
                                            description={recommendedCourseDetails.shortDescription}
                                            rating={recommendedCourseDetails.rating}
                                            nrOfRatings={recommendedCourseDetails.reviewsCount}
                                            nrOfStudents={recommendedCourseDetails.students}
                                            tags={
                                                recommendedCourseDetails.tags.map((tagDetails: any) => (
                                                    {
                                                        label: tagDetails.name,
                                                        color: 'orange'
                                                    }
                                                ))
                                            }
                                            percentage={0}
                                            mainPicture={recommendedCourseDetails.cover.sizes.course.url || sampleImg}
                                            handleParentClick={() => { handleCourseSelect(recommendedCourseDetails.slug) }}
                                            id={recommendedCourseDetails.id}
                                        />
                                    </div>)}
                            </div>
                        </div>
                    }
                </div>
            </div>
            {/**https://static.moonproject.io/coca-cola/enjovom/targyalasi_keszsegek/story.html*/}
            {/**https://enjovom.hu/storage/curriculum/az_idogazdalkodas_muveszete/story.html */}
        </>
    )
}

const CourseData = (props: any) => {
    const { description } = props;

    function createHTML() {
        return {
            __html: description
        };
    };

    return (
        <div>
            <h5 className="mt-4">Képzés leírása</h5>
            <div className="text-muted" >
                <div dangerouslySetInnerHTML={createHTML()} />
            </div>
        </div>
    )
}

const Materials = (props: any) => {

    const { courseMaterials } = props;

    const handleDownload = (source: any): void => {
        /* console.log(source); */
    }

    return (
        <div>
            {authProvider.checkAuth() && courseMaterials.map((materialsDetails: any, index: number) =>
                <div className="material_link_container mb-3"
                    key={index}
                    onClick={() => { handleDownload(materialsDetails) }}>
                    <a href={materialsDetails.file}
                        target='_blank'
                        className='ejicon-download alink_red'
                        rel='noreferrer'
                        download
                    >
                        <span className='heading_six' style={{ color: '#F40000', paddingLeft: '20px' }}>{materialsDetails.name}</span>
                    </a>
                </div>)}
        </div>
    )
}

const Ratings = (props: any) => {

    const { userProfile, courseId, modalPopper, closeModal } = props;
    const [liveRating, setLiveRating] = React.useState(0)
    const [myDescription, setMyDescription] = React.useState('');
    const [userRatings, setUserRatings] = React.useState<any[]>([])

    const calculateFirstLetters = claculateFL(userProfile)

    React.useEffect(() => {
        dataProvider.getMany(`/courses/${courseId}/reviews`, (response: any) => {
            /* console.log(response) */
            if (response.status === 200) {
                setUserRatings(response.data.data)
            }
        })
    }, [courseId])


    const refreshRatings = () => {
        dataProvider.getMany(`/courses/${courseId}/reviews`, (response: any) => {
            /* console.log(response) */
            if (response.status === 200) {
                setUserRatings(response.data.data)
            }
        })
    }


    const trySendReview = () => {
        if (liveRating) {
            const reviewData = {
                reviewStars: liveRating,
                reviewText: myDescription
            }
            dataProvider.updateSpecial('/courses', courseId, 'review', reviewData, (response: any) => {
                if (response?.status === 200) {

                    modalPopper(
                        <div onClick={closeModal}>
                            <h3 style={{ padding: '24px' }}>Az értékelésed elmentettük.</h3>
                        </div>)
                    setLiveRating(0);
                    setMyDescription('');
                    refreshRatings();
                }

            })
        }
    }

    return (
        <div className="course-rating-container">
            {authProvider.checkAuth() && <>
                <h5 className="mt-4">Értékelnéd a tanfolyamot?</h5>
                <p className="text-muted mb-4">
                    Osztályozd és írj véleményt a tananyagról!
                </p>
                <div className="self_rating">
                    <div className="rating_self_rating_stars my-3">
                        <StarLine
                            rating={liveRating}
                            disabled={false}
                            setParentValue={setLiveRating}
                        />
                    </div>

                    <div className='row mb-3'>
                        <div className="col-auto">
                            <span className="cl-white ikon_with_big justify-content-center" >
                                {userProfile?.profilePictureUrl ?
                                    <img src={userProfile.profilePictureUrl} alt="" />
                                    :
                                    <div className="letters d-flex justify-content-center align-items-center body_bold">
                                        {calculateFirstLetters}
                                    </div>
                                }
                            </span>
                        </div>
                        <div className="col body_small">
                            <Input
                                disabled={false}
                                leftIcon={null}
                                rightIcon={null}
                                label=''
                                value={myDescription}
                                onChangeHandler={(e: any) => setMyDescription(e.target.value)}
                                error=''
                                placeHolder='Ha szeretnéd, itt értékelheted szövegesen is a modult.'
                                type='text'
                                textArea={true}
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end mb-3" onClick={trySendReview}>
                        <div className="">
                            <Button
                                type='primary'
                                label='Értékelés elküldése'
                                disabled={!(liveRating)}
                                icon=''
                                icon_position='right'
                            />
                        </div>
                    </div>
                </div>
                <hr />

            </>
            }
            {userRatings.map((ratingDetails) =>
                <>
                    <RatingCard
                        avatar={ratingDetails.user.profilePictureUrl}
                        description={ratingDetails.review}
                        rating={ratingDetails.stars}
                        sendTime={ratingDetails.sendTime}
                        userName={`${ratingDetails.user.firstName} ${ratingDetails.user.lastName}`}
                    />
                    <hr />
                </>
            )}
        </div>
    )
}

const RatingCard = (props: any) => {
    const { avatar, userName, description, rating, sendTime } = props

    return (
        <div className="rating_card_container">
            <div className="row mb-3">
                <div className="rating_card_avatar_container col-auto">
                    {avatar ? <img src={avatar} alt="" className='my_avatar' />

                        :
                        <div className="letters d-flex justify-content-center align-items-center body_bold">
                            {claculateFL({
                                firstName: userName.split(' ')[0],
                                lastName: userName.split(' ')[1],
                            })}
                        </div>
                    }
                </div>
                <div className="col d-flex flex-column justify-content-center">
                    <h6 className="mb-0">{userName}</h6>
                    <div className="body_small">
                        {sendTime}
                    </div>
                </div>
                <div className="col d-flex justify-content-end align-items-center">
                    <StarLine
                        rating={rating}
                        disabled={true}
                    />
                </div>
            </div>
            <div className="body_normal ">
                {description}
            </div>
        </div>
    )


}


export default CourseDetail
function claculateFL(userProfile: any) {

    if (userProfile.firstName && userProfile.lastName) {
        const letters = userProfile.firstName.charAt(0) + userProfile.lastName.charAt(0);

        return letters;
    }
    return '';

}

