function SpotifyIcon() {
	return (
		<svg
			width="32"
			height="32"
			viewBox="0 0 32 32"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle
				cx="16"
				cy="16"
				r="16"
				fill="var(--red)"
			/>
			<g clipPath="url(#clip0)">
				<path
					d="M16 7.53125C11.1836 7.53125 7.28125 11.4688 7.28125 16.25C7.28125 21.0664 11.1836 24.9688 16 24.9688C20.7812 24.9688 24.7188 21.0664 24.7188 16.25C24.7188 11.4688 20.7812 7.53125 16 7.53125ZM19.5156 20.3633C19.375 20.3633 19.2695 20.3281 19.1641 20.2578C16.9492 18.9219 14.418 18.8867 11.8867 19.3789C11.7461 19.4141 11.5703 19.4844 11.4648 19.4844C11.1133 19.4844 10.9023 19.2031 10.9023 18.9219C10.9023 18.5703 11.1133 18.3945 11.3945 18.3242C14.2773 17.6914 17.1953 17.7617 19.7266 19.2383C19.9375 19.3789 20.043 19.5195 20.043 19.8359C20.043 20.1523 19.7969 20.3633 19.5156 20.3633ZM20.4648 18.0781C20.2891 18.0781 20.1484 17.9727 20.043 17.9375C17.8281 16.6367 14.5586 16.1094 11.6406 16.8828C11.4648 16.918 11.3945 16.9883 11.2188 16.9883C10.8672 16.9883 10.5508 16.6719 10.5508 16.2852C10.5508 15.9336 10.7266 15.6875 11.0781 15.582C12.0625 15.3008 13.082 15.0898 14.5234 15.0898C16.8086 15.0898 19.0234 15.6523 20.7461 16.707C21.0273 16.8477 21.168 17.0938 21.168 17.375C21.1328 17.7617 20.8516 18.0781 20.4648 18.0781ZM21.5547 15.4062C21.3789 15.4062 21.2734 15.3359 21.0977 15.2656C18.6016 13.7539 14.1367 13.4023 11.2188 14.2109C11.1133 14.2461 10.9375 14.3164 10.7617 14.3164C10.3047 14.3164 9.95312 13.9297 9.95312 13.4727C9.95312 12.9805 10.2695 12.7344 10.5859 12.6289C11.8164 12.2773 13.1875 12.1016 14.6992 12.1016C17.2656 12.1016 19.9727 12.6289 21.9062 13.7891C22.1875 13.9297 22.3633 14.1406 22.3633 14.5625C22.3633 15.0547 21.9766 15.4062 21.5547 15.4062Z"
					fill="white"
				/>
			</g>
			<defs>
				<clipPath id="clip0">
					<rect
						width="24"
						height="24"
						fill="white"
						transform="translate(4 4)"
					/>
				</clipPath>
			</defs>
		</svg>
	)
}

export default SpotifyIcon
