import React from 'react';
import ModulCard from '../../components/modul/ModulCard';

export const CoursesDone = (props: any) => {

    const { courseList } = props;

    return (
        <div className="row mt-4">
            {
                courseList.map((courseDetails: any) =>
                    courseDetails?.finished &&
                    <div className='col-12 col-md-6 col-lg-6 col-xl-6 mb-4' key={courseDetails.id}>
                        <ModulCard
                            title={courseDetails.title}
                            description={courseDetails.shortDescription}
                            rating={courseDetails.rating}
                            nrOfRatings={courseDetails.reviewsCount}
                            nrOfStudents={courseDetails.students}
                            tags={
                                courseDetails.tags.map((tagDetails: any) => (
                                    {
                                        label: tagDetails.name,
                                        color: 'orange'
                                    }
                                ))
                            }
                            percentage={100}
                            mainPicture={courseDetails.cover.sizes.course.url}
                            handleParentClick={() => {/* handleCourseSelect(courseDetails.id) */ }}
                            id={courseDetails.id}
                        />
                    </div>
                )
            }
        </div>
    );
};
