import React from 'react'
import './progress_circle.css'

interface progress_circle_props {
    progress: number;
}



function ProgressCircle(props: progress_circle_props) {

    const { progress } = props;
    const [transformElemntsStyle, setTransform] = React.useState({
        firstQuarter: {},
        secondQuarter: {},
        thirdQuarter: {},
        fourthQuarter: {},
    })

    const renderProgress = (progress: number): void => {
        if (progress < 25) {
            const angle = -90 + (progress / 100) * 360;
            setTransform({
                ...transformElemntsStyle,
                fourthQuarter: {},
                thirdQuarter: {},
                secondQuarter: {},
                firstQuarter: { "transform": "rotate(" + angle + "deg)" }
            })
        }
        else if (progress >= 25 && progress < 50) {
            const angle = -90 + ((progress - 25) / 100) * 360;
            transformElemntsStyle.secondQuarter = { "transform": "rotate(" + angle + "deg)" }
            setTransform({
                ...transformElemntsStyle,
                fourthQuarter: {},
                thirdQuarter: {},
                secondQuarter: { "transform": "rotate(" + angle + "deg)" },
                firstQuarter: { "transform": "rotate(0deg)" }
            })
        }
        else if (progress >= 50 && progress < 75) {
            const angle = -90 + ((progress - 50) / 100) * 360;
            setTransform({
                ...transformElemntsStyle,
                fourthQuarter: {},
                thirdQuarter: { "transform": "rotate(" + angle + "deg)" },
                secondQuarter: { "transform": "rotate(0deg)" },
                firstQuarter: { "transform": "rotate(0deg)" }
            })
        }
        else if (progress >= 75 && progress <= 100) {
            const angle = -90 + ((progress - 75) / 100) * 360;
            setTransform({
                fourthQuarter: { "transform": "rotate(" + angle + "deg)" },
                thirdQuarter: { "transform": "rotate(0deg)" },
                secondQuarter: { "transform": "rotate(0deg)" },
                firstQuarter: { "transform": "rotate(0deg)" }
            })
        }
    }

    React.useEffect(() => { renderProgress(progress) }, [progress])



    return (
        <div>
            <div className="loader">
                <div className="loader-bg">
                    <div className="text">
                        <h6> {`${progress}%`}</h6>
                    </div>
                </div>
                <div className="spiner-holder-one animate-0-25-a">
                    <div className="spiner-holder-two animate-0-25-b" style={transformElemntsStyle.firstQuarter} >
                        <div className="loader-spiner" ></div>
                    </div>
                </div>
                <div className="spiner-holder-one animate-25-50-a">
                    <div className="spiner-holder-two animate-25-50-b" style={transformElemntsStyle.secondQuarter}>
                        <div className="loader-spiner"></div>
                    </div>
                </div>
                <div className="spiner-holder-one animate-50-75-a">
                    <div className="spiner-holder-two animate-50-75-b" style={transformElemntsStyle.thirdQuarter}>
                        <div className="loader-spiner"></div>
                    </div>
                </div>
                <div className="spiner-holder-one animate-75-100-a">
                    <div className="spiner-holder-two animate-75-100-b" style={transformElemntsStyle.fourthQuarter}>
                        <div className="loader-spiner"></div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProgressCircle
