import React from 'react'
import '../../core/link/link.css'
import '../../core/notification/notification.css'
import "../../core/icons/badge/badge.css"
import "../../core/icons/opinion/opinion.css"
import "../../core/icons/emoji/emoji.css"
import "../../core/icons/play/play.css"
import "../../core/tooltip/tooltip.css"

import "../../components/footer/footer.css"
/* import './bootstrap.min.css' */
import cbcmLogo from '../../assets/img/landing/cbcmlogo.svg'
import registerCalendar from '../../assets/img/landing/register_calendar.svg'
import registerBulb from '../../assets/img/landing/register_bulb.svg'
import nagyKep from '../../assets/img/landing/nagykep.jpg'
import landingYoutube from '../../assets/img/landing/landing_youtube.png'
import fejleszd from '../../assets/img/landing/fejleszd.png'
import podcast from '../../assets/img/landing/podcast.png'
import podcastPlayer from '../../assets/img/landing/podcast-player.png'
import kikVagyunkMi from '../../assets/img/landing/kik-vagyunk-mi.jpg'

/* import './alkosa.css' */
import video from '../../assets/img/landing/video.jpg'
/* import './_base/_base.css'
import './_base/_variables.css'
import './_base/_colors.css'
 */
/* import './_base/_fonts.css' */
function Alkosa() {
    return (
        <div /* className="page" */>
            {/* <div className="cc-navigation">
                <div className="cc-container spacer">
                    <div className="row gx-0">
                        <div className="col cc-logo"></div>
                        <div className="col col-xl-7 cc-menu">
                            <ul className="d-xl-flex justify-content-xl-between cc-navigation-menu">
                                <li className="cc-navigation-menu-button d-block"><button className="btn btn-circle bg-white cl-red"><span className="ejicon-close"></span></button></li>
                                <li className="cc-navigation-menu-item d-block"><a className="cc-navigation-menu-link" href="/course_list">Tananyagok</a></li>
                                <li className="cc-navigation-menu-item d-block"><a className="cc-navigation-menu-link" href="/course_list">Hírek</a></li>
                                <li className="cc-navigation-menu-item d-block"><a className="cc-navigation-menu-link" href="/course_list">Események</a></li>
                                <li className="cc-navigation-menu-item d-block"><a className="cc-navigation-menu-link" href="/course_list">Rólunk</a></li>
                                <li className="cc-navigation-menu-item d-block"><a className="cc-navigation-menu-link" href="/course_list">Mentorprogram</a></li>
                                <li className="cc-navigation-menu-item d-block"><a className="cc-navigation-menu-link" href="/course_list">Partnereink</a></li>
                            </ul>			</div>
                        <div className="col justify-content-xl-center d-xl-flex flex-xl-column cc-menu-end"></div>
                    </div>
                </div>
                <div className="cc-container buttons">
                    <div className="row gx-0">
                        <div className="col col-xl-auto cc-logo">
                            <a href="https://localhost/xx" className="justify-content-xl-center d-xl-flex flex-xl-column logo">
                                <img src={cbcmLogo} />
                            </a>			</div>
                        <div className="col col-xl-7 cc-menu"></div>
                        <div className="col justify-content-xl-center d-xl-flex flex-xl-column cc-menu-end">
                            <div className="wrap text-end">
                                <button className="btn bg-red cl-white login"><span className="ejicon-user"></span><span className="button-text">Belépek</span></button>					<button className="btn btn-circle bg-white cl-red cc-navigation-menu-button"><span className="ejicon-menu"></span></button>
                            </div>
                        </div>
                    </div>



                </div>
            </div> */}
            <div className="content landing">
                <div className="section back-section left-top home-benned-van-nagy-kep">
                    <img className="nagykep" src={nagyKep} />
                    <div className="row gx-0">
                        <div className="col col-xl-4 offset-xl-8 nagykep-cnt">
                            <img className="register-calendar-svg" src={registerCalendar} />
                            <img className="register-bulb-svg" src={registerBulb} />
                        </div>
                    </div>
                </div>
                <div className="section section-first d-xl-flex flex-column justify-content-center home-regisztralj">
                    <div className="cc-container">
                        <div className="row section-title">
                            <div className="col-7">
                                <h1>Benned van a lehetőség! <br />Hozd ki magadból <br />a maximumot!</h1>
                            </div>
                        </div>
                        <div className="row section-text">
                            <div className="col col-3">
                                <p>Fedezd fel az #énjövőm program által nyújtott lehetőségeket - tanulj díjmentesen bárhol, bármikor, és találd meg a helyed a munkaerőpiacon.</p>
                                <p><a className="btn bg-red cl-white registration">Regisztrálok</a></p>
                            </div>
                            <div className="col col-6">
                                <div className="video">
                                    <div className="btn btn-big-circle d-flex video-button bg-red">
                                        <svg className="svg-white" width="30" height="34" viewBox="0 0 30 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M27 11.8038C31 14.1132 31 19.8868 27 22.1962L9 32.5885C5 34.8979 -1.70523e-06 32.0111 -1.50334e-06 27.3923L-5.94811e-07 6.60769C-3.92917e-07 1.98889 5 -0.897859 9 1.41154L27 11.8038Z" fill="white" />
                                        </svg>
                                    </div>
                                    <div className="video-thumbnail"><img src={landingYoutube} /></div>
                                </div>
                            </div>
                            <div className="col col-3">

                            </div>
                        </div>
                    </div>
                </div>

                <div className="section home-counter-group">
                    <div className="cc-container group-wrap">
                        <div className="row counter-row">
                            <div className="col">
                                <div className="counter ratio ratio-1x1">
                                    <div className="counter-wrap">
                                        <div className="icon cl-red bg-red-fade ejicon-user-tick">
                                        </div>
                                        <h2>7512</h2>
                                        <p>Regisztrált felhasználó</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="counter ratio ratio-1x1">
                                    <div className="counter-wrap">
                                        <div className="icon cl-red bg-red-fade ejicon-clock">
                                        </div>
                                        <h2>130 000</h2>
                                        <p>Tanulással töltött perc</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="counter ratio ratio-1x1">
                                    <div className="counter-wrap">
                                        <div className="icon cl-red bg-red-fade ejicon-hand-shake">
                                        </div>
                                        <h2>7</h2>
                                        <p>Partner</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="counter ratio ratio-1x1">
                                    <div className="counter-wrap">
                                        <div className="icon cl-red bg-red-fade ejicon-teacher">
                                        </div>
                                        <h2>30</h2>
                                        <p>#énjövőm mentor</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col">
                                <div className="counter ratio ratio-1x1">
                                    <div className="counter-wrap">
                                        <div className="icon cl-red bg-red-fade ejicon-members">
                                        </div>
                                        <h2>22</h2>
                                        <p>Szakértő tréner</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="section home-nezd-meg-mit-tanulhatsz">
                    <div className="cc-container">
                        <h1 className="text-center">Nézd meg, miről tanulhatsz nálunk!</h1>
                        <div className="tab-list">
                            <div className="tab-list-wrapper">
                                <div className="tab active">Legnépszerübbek</div>
                                <div className="tab">Kommunikáció</div>
                                <div className="tab">Önismeret</div>
                                <div className="tab">Vállalkozási ismeretek</div>
                            </div>
                        </div>
                        <div className="modul-list card-list">
                            <div className="card-list-wrapper">

                                <div className="modul">
                                    <div className="modul-header ratio ratio-16x9">
                                        <div className="modul-status">
                                            <div className="tag bg-green-fade cl-green"><span className="icon bg-green">
                                                <span className="cl-green-fade ejicon-tick"></span></span>
                                                <span className="status-text">Befejezve</span>
                                            </div>
                                        </div>
                                        <button className="btn btn-circle bg-white cl-red modul-bookmark"><span className="ejicon-bookmark"></span></button>
                                        <div className="modul-completion bg-white">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: '89%' }} aria-valuenow={89} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                            <div className="complete-text">89%</div>
                                        </div>
                                        <div className="modul-image">
                                            <img src={video} />
                                        </div>
                                    </div>
                                    <div className="modul-footer bg-white">
                                        <div className="modul-stats row">
                                            <div className="modul-ratings col row">
                                                <div className="modul-stars col">
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star star-empty cl-yellow"><span className="ejicon-star"></span></button>
                                                </div>
                                                <div className="modul-stars-num col">(89)</div>
                                            </div>
                                            <div className="modul-vievers col">
                                                <span className="ejicon-user"></span>
                                                <span className="viever-text">1100</span>
                                            </div>
                                        </div>
                                        <div className="modul-title">
                                            <h5>Kommunikáció és hálózatépítés </h5>
                                        </div>
                                        <div className="modul-description">
                                            <p>Kurzus rövid leírása dolor sit amet, consectetur adipiscing elit. Nullam auctor tempor ipsum...</p>
                                        </div>
                                        <div className="modul-tags">

                                        </div>
                                    </div>
                                </div>

                                <div className="modul">
                                    <div className="modul-header ratio ratio-16x9">
                                        <div className="modul-status">
                                            <div className="tag bg-green-fade cl-green"><span className="icon bg-green">
                                                <span className="cl-green-fade ejicon-tick"></span></span>
                                                <span className="status-text">Befejezve</span>
                                            </div>
                                        </div>
                                        <button className="btn btn-circle bg-white cl-red modul-bookmark"><span className="ejicon-bookmark"></span></button>
                                        <div className="modul-completion bg-white">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: '100%' }} aria-valuenow={100} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                            <div className="complete-text">100%</div>
                                        </div>
                                        <div className="modul-image">
                                            <img src={video} />
                                        </div>
                                    </div>
                                    <div className="modul-footer bg-white">
                                        <div className="modul-stats row">
                                            <div className="modul-ratings col row">
                                                <div className="modul-stars col">
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star star-empty cl-yellow"><span className="ejicon-star"></span></button>
                                                </div>
                                                <div className="modul-stars-num col">(9)</div>
                                            </div>
                                            <div className="modul-vievers col">
                                                <span className="ejicon-user"></span>
                                                <span className="viever-text">110</span>
                                            </div>
                                        </div>
                                        <div className="modul-title">
                                            <h5>Kommunikáció és hálózatépítés </h5>
                                        </div>
                                        <div className="modul-description">
                                            <p>Kurzus rövid leírása dolor sit amet, consectetur adipiscing elit. Nullam auctor tempor ipsum...</p>
                                        </div>
                                        <div className="modul-tags">

                                        </div>
                                    </div>
                                </div>

                                <div className="modul">
                                    <div className="modul-header ratio ratio-16x9">
                                        <div className="modul-status">
                                            <div className="tag bg-green-fade cl-green"><span className="icon bg-green">
                                                <span className="cl-green-fade ejicon-tick"></span></span>
                                                <span className="status-text">Befejezve</span>
                                            </div>
                                        </div>
                                        <button className="btn btn-circle bg-white cl-red modul-bookmark"><span className="ejicon-bookmark"></span></button>
                                        <div className="modul-completion bg-white">
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: '50%' }} aria-valuenow={50} aria-valuemin={0} aria-valuemax={100}></div>
                                            </div>
                                            <div className="complete-text">50%</div>
                                        </div>
                                        <div className="modul-image">
                                            <img src={video} />
                                        </div>
                                    </div>
                                    <div className="modul-footer bg-white">
                                        <div className="modul-stats row">
                                            <div className="modul-ratings col row">
                                                <div className="modul-stars col">
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star star-empty cl-yellow"><span className="ejicon-star"></span></button>
                                                    <button className="btn star star-empty cl-yellow"><span className="ejicon-star"></span></button>
                                                </div>
                                                <div className="modul-stars-num col">(890)</div>
                                            </div>
                                            <div className="modul-vievers col">
                                                <span className="ejicon-user"></span>
                                                <span className="viever-text">11000</span>
                                            </div>
                                        </div>
                                        <div className="modul-title">
                                            <h5>Kommunikáció és hálózatépítés </h5>
                                        </div>
                                        <div className="modul-description">
                                            <p>Kurzus rövid leírása dolor sit amet, consectetur adipiscing elit. Nullam auctor tempor ipsum...</p>
                                        </div>
                                        <div className="modul-tags">

                                        </div>
                                    </div>
                                </div>


                            </div>

                        </div>
                    </div>
                    <p className="text-center"><a className="btn bg-red cl-white registration">Megnézem az összeset</a></p>
                </div>

            </div>
            <div className="section order-3 home-kik-vagyunk-mi">
                <div className="cc-container">
                    <div className="kik-vagyunk row gx-0">
                        <div className="col col-xl-8 kik-vagyunk-kep"><img src={kikVagyunkMi} /></div>
                        <div className="col col-xl-4 row kik-vagyunk-card">
                            <div className="bg-white card-float">
                                <h2>Kik vagyunk mi</h2>
                                <p>Az #énjövőm program olyan 16-35 év közötti fiataloknak kínál online előadásokat, valamint ingyenes, egynapos önfejlesztő workshopokat, akik szeretnének tanulni, fejlődni és megtalálni a helyüket a munka világában.</p>
                                <p><a className="btn bg-red cl-white registration">Tudj meg többet!</a></p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div className="section order-4 home-quotes">
                <div className="cc-container">
                    <div className="quotes">
                        <div className="quotes-wrapper">
                            <div className="quotes">

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="section order-5 home-podcast">
                <div className="img-cnt">

                    <div className="cc-container row float-cnt">
                        <div className="col col-4 wrap">
                            <h3 className="cl-white">Megjelent az #énjövőm podcast legfrissebb epizódja! Hallgasd meg most!</h3>
                            <p><img src={podcastPlayer} /></p>
                            <p><a className="btn bg-red cl-white registration">Többi podcast</a></p>
                        </div>
                    </div>
                    <img src={podcast} />
                </div>
            </div>

            <div className="section order-6 order-sm-6 order-xl-6 home-fejleszd-magad">
                <div className="cc-container">
                    <div className="row">
                        <div className="col col-xl-6 text">
                            <h1>Fejleszd magad bárhol, bármikor</h1>
                            <p>Megújult felületünkön út közben sem kell lemondanod a tanulásról. Előadásainkat a saját tempódban, akár megszakításokkal is végezheted. Ha pedig szívesebben tanulnál kiscsoportban egy szakértő tréner segítségével, akkor gyere el személyes workshopjaink valamelyikére!</p>
                        </div>
                        <div className="col col-xl-6">
                            <img src={fejleszd} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Alkosa
