import axios from 'axios'

/* hohner_levente.pswd : 1944AAccdr! */

const authProvider: any = {
	login: (email: string, password: string, sendDataToComponent: Function) => {
		axios
			.post(
				`/api/user/login`,
				{ email: email, password: password },
				{ withCredentials: true },
			)
			.catch((error) => sendDataToComponent({ Error: error }))
			.then((response) => {
				if (response?.status === 200) {
					const accessToken = response.data.data.accessToken
					if (accessToken) {
						localStorage.setItem('access_token', accessToken)
					}
					return sendDataToComponent({ Error: null })
				}
				if (response) {
					return sendDataToComponent({ Error: response })
				}
			})
	},

	/* checkError: (error: any) => { }, */
	checkAuth: () => {
		const access_token = localStorage.getItem('access_token')
		if (access_token) {
			return true
		}
		return false
	},
	logout: () => {
		const accessToken = localStorage.getItem('access_token')

		axios
			.post(
				`/api/user/logout`,
				{},
				{ headers: { Authorization: `Bearer ${accessToken}` } },
			)
			.catch((error) => console.error(error))
			.then((response) => {
				localStorage.removeItem('access_token')
			})
	},
	registration: (userProfile: any, sendInfoToComponent: Function) => {
		axios
			.post(`/api/user/register`, userProfile)
			.catch((error) => {
				console.error(error)
				sendInfoToComponent(0)
			})
			.then((response: any) => {
				sendInfoToComponent(response.status)
			})
	},
	updateUserProfile: (userProfile: any, callback: Function) => {
		const accessToken = localStorage.getItem('access_token')
		axios
			.patch(`/api/user/profile`, userProfile, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
					ContentType: 'application/x-www-form-urlencoded',
				},
			})
			.catch((error) => console.error(error))
			.then((response) => {
				return callback(response)
			})
	},
	updateUserProfilePicture: (image: string) => {
		const accessToken = localStorage.getItem('access_token')
		axios
			.put(
				`/api/user/profile`,
				{ image: image },
				{ headers: { Authorization: `Bearer ${accessToken}` } },
			)
			.catch((error) => console.error(error))
			.then((response) => console.log(response))
	},

	refreshToken: (sendDataToComponent: Function) => {
		axios
			.post(`/api/user/authorize`, {})
			.catch((error) => sendDataToComponent(null))
			.then((response): any => {
				if (response?.status === 200) {
					const accessToken = response.data.data.accessToken
					if (accessToken) {
						localStorage.setItem('access_token', accessToken)
					}
					return sendDataToComponent(accessToken)
				}
				return sendDataToComponent({ Error: response })
			})
	},
	deleteUser: (callback: Function) => {
		const accessToken = localStorage.getItem('access_token')
		axios
			.delete(`/api/user/profile`, {
				headers: { Authorization: `Bearer ${accessToken}` },
			})
			.catch((error) => {
				return callback(error)
			})
			.then((response: any) => {
				return callback(response)
			})
	},
	passwordReset: (email: string, callback: Function) => {
		axios
			.post(`/api/user/password/reset`, { email: email })
			.catch((error) => {
				return callback(error)
			})
			.then((response: any) => {
				return callback(response)
			})
	},
	resetUserPassword: (pswd: string, token: string, callback: Function) => {
		axios
			.post(`/api/user/password/confirmation`, {
				token: token,
				password: pswd,
			})
			.catch((error) => {
				return callback(error)
			})
			.then((response: any) => {
				return callback(response)
			})
	},
}

export default authProvider
