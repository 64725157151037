import React from 'react';
import CheckBox from '../../core/checkbox/CheckBox';
import LinkWithIcon from '../../core/link/LinkWithIcon';
import dataProvider from '../../dataProvider';
import Input from '../../core/inputs/Input';

export const TodoList = () => {

    const [newTodoDescription, setNewTodoDescription] = React.useState('');
    const [recentTodosList, setRecentTodosList] = React.useState<any[]>([]);
    const [isInCreateMode, setIsInCreateMode] = React.useState(false);
    const [todoStatuses, setTodoStatuses] = React.useState<boolean[]>([]);

    const removeTodo = (todoId: number) => {
        setTodoStatuses(todoStatuses.map((todoStatus: boolean, index: number) => recentTodosList[index]?.id === todoId ? !todoStatus : todoStatus))
        setTimeout(() => {
            dataProvider.updateOne(`/todos/${todoId}/check`, {}, (response: any) => {
                if (response?.status === 200) {
                    refreshTodoList();
                }
            });
        }, 2000);
    };

    const refreshTodoList = () => {
        dataProvider.getMany('/todos', (response: any) => {
            if (response?.status === 200) {
                setRecentTodosList(response.data.data);
                setTodoStatuses(new Array(response?.data?.data?.length).fill(false))
            }
        });
    };

    const addTodo = () => {
        if (newTodoDescription.length) {
            dataProvider.createNew('/todos', { text: newTodoDescription }, (response: any) => {
                /*  console.log(response) */
                if (response.status === 200) {
                    setNewTodoDescription('');
                    refreshTodoList();
                    setIsInCreateMode(false);
                }
            });
        }
    };

    React.useEffect(() => {
        refreshTodoList();
    }, []);

    const handleKeyPress = (e: any) => {
        if (e?.keyCode === 13) {
            addTodo()
        }
    }

    return (
        <div className="card">
            <div className="card-body p-4">
                {recentTodosList.map((todo: any, index: number) => <>
                    {!todo.check &&
                        <div className="d-flex flex-row align-items-center mb-3" key={index}>
                            <CheckBox
                                value={todoStatuses[index] ? 'checked' : 'default'}
                                disabled={false}
                                handleValueChange={() => removeTodo(todo.id)} />
                            <span className="ms-3">{todo.text}</span>
                        </div>}
                </>
                )}
                {!isInCreateMode &&
                    <div className="" onClick={() => setIsInCreateMode(true)} style={{ padding: '10px 0px' }}>
                        <LinkWithIcon
                            icon='ejicon-plus'
                            label='Új tennivaló'
                            linkTo='#'
                            active={false} />
                    </div>}
                {isInCreateMode &&
                    <div className="d-flex w-100 flex-row align-items-center">
                        <div className="todo-icon-container" onClick={() => addTodo()}>
                            <LinkWithIcon
                                icon='ejicon-plus'
                                label=''
                                linkTo='#'
                                active={false} />
                        </div>
                        <div className="todo-input-container" onKeyDown={(e: any) => handleKeyPress(e)}>
                            <Input
                                disabled={false}
                                label=''
                                value={newTodoDescription}
                                leftIcon=''
                                rightIcon=''
                                onChangeHandler={(e: any) => setNewTodoDescription(e.target.value)}
                                error=''
                                type='text'
                                placeHolder='Tennivaló rövid leírása' />
                        </div>
                    </div>}
            </div>
        </div>
    );
};
