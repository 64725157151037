import React from 'react'
import Toggle from '../../core/toggle/Toggle'
import LinkWithIcon from '../../core/link/LinkWithIcon';
import './settings.css'
import dataProvider from '../../dataProvider';
import Button from '../../core/button/Button';
import authProvider from '../../authProvider';

function Settings(props: any) {

    const { modalPopper, closeModal } = props

    const [mySettings, setMySettings] = React.useState({
        calendarEvents: false,
        news: false,
        newCourses: false,
        courseReminder: false,
        newsletter: false
    })
    const [notifications, setNotifications] = React.useState(false);
    const getSettings = () => {
        dataProvider.getMany('/user/profile/settings', (response: any) => {
            if (response.status === 200) {
                let recentSettings = response.data.data;
                recentSettings.notifications = false;
                setMySettings(recentSettings);
            }
        })
    }

    React.useEffect(() => {
        getSettings();
    }, [])


    const trySendNewSettings = () => {
        let newSettings = {
            /* notifications: mySettings.notifications, */
            calendarEvents: mySettings.calendarEvents,
            news: mySettings.news,
            newCourses: mySettings.newCourses,
            courseReminder: mySettings.courseReminder,
            newsletter: mySettings.newsletter
        };
        dataProvider.updateOne('/user/profile/settings', newSettings, (response: any) => {
            if (response?.status === 200) {
                getSettings();
                modalPopper(<div onClick={closeModal}>
                    <h3 style={{padding:'24px'}}>A beállításaidat elmentettük.</h3>
                </div>)
            }
        })
    }


    const handleDeleteRequest = () => {
        authProvider.deleteUser((response: any) => {
            console.log(response)
            if (response?.status === 200) {
                modalPopper(
                    <DeleteRequestSuccessPage />
                )
            }
        })
    }

    const DeleteRequestSuccessPage = () => {
        return (
            <div>
                <h3 className='d-flex flex-row  login_greetings_container mt-2' onClick={closeModal}>
                    A törlés megerősítése céljából email üzenetet küldtünk neked.
            </h3>
                <br></br><br></br>
                <h5>
                    Kérjük, hogy nézd meg a postaládádat és kövesd az e-mailünkben található utasításokat!
            </h5>


            </div>
        )
    }

    const handleNotificationChange = () => {
        if (notifications) {
            setNotifications(false)
        } else {
            setNotifications(true);
            setMySettings(
                {
                    calendarEvents: true,
                    news: true,
                    newCourses: true,
                    courseReminder: true,
                    newsletter: true
                }
            )
        }
    }


    return (
        <>
            <div className="settings-container mt-5">
                <h2 className="mb-4">Beállítások</h2>
                <div className="card mb-5">
                    <div className="card-body p-5">
                        <h5>Értesítések</h5>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <label>Minden értesítés bekapcsolása</label>
                            </div>
                            <div className="col-auto">
                                <Toggle
                                    checked={notifications}
                                    disabled={false}
                                    handleValueChange={() => handleNotificationChange()}
                                />
                            </div>
                        </div>
                        <hr></hr>
                        <div className="row mb-3">
                            <div className="col d-flex align-items-center">
                                <label>Naptár események</label>
                            </div>
                            <div className="col-auto">
                                <Toggle
                                    checked={mySettings.calendarEvents}
                                    disabled={false}
                                    handleValueChange={() => {
                                        setMySettings({ ...mySettings, calendarEvents: !mySettings.calendarEvents })
                                        mySettings.calendarEvents && setNotifications(false)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col d-flex align-items-center">
                                <label>Hírek</label>
                            </div>
                            <div className="col-auto">
                                <Toggle
                                    checked={mySettings.news}
                                    disabled={false}
                                    handleValueChange={() => {
                                        setMySettings({ ...mySettings, news: !mySettings.news })
                                        mySettings.news && setNotifications(false)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col d-flex align-items-center">
                                <label>Új tananyagok</label>
                            </div>
                            <div className="col-auto">
                                <Toggle
                                    checked={mySettings.newCourses}
                                    disabled={false}
                                    handleValueChange={() => {
                                        setMySettings({ ...mySettings, newCourses: !mySettings.newCourses })
                                        mySettings.newCourses && setNotifications(false)
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <label>Megkezdett kurzus emlékeztető</label>
                            </div>
                            <div className="col-auto">
                                <Toggle
                                    checked={mySettings.courseReminder}
                                    disabled={false}
                                    handleValueChange={() => {
                                        setMySettings({ ...mySettings, courseReminder: !mySettings.courseReminder })
                                        mySettings.courseReminder && setNotifications(false)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="card mb-5">
                    <div className="card-body p-5">
                        <h5>Értesítések</h5>
                        <div className="row">
                            <div className="col d-flex align-items-center">
                                <label>Szeretnék hírlevelet kapni</label>
                            </div>
                            <div className="col-auto">
                                <Toggle
                                    checked={mySettings.newsletter}
                                    disabled={false}
                                    handleValueChange={() => {
                                        setMySettings({ ...mySettings, newsletter: !mySettings.newsletter })
                                        mySettings.newsletter && setNotifications(false)
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-flex col-12 justify-content-center mb-4" onClick={trySendNewSettings}>
                    <Button
                        type='primary'
                        label='Beállítások mentése'
                        disabled={false}
                        icon=''
                        icon_position='right' />
                </div>

                <div className="card">
                    <div className="card-body px-5 py-4"
                        onClick={handleDeleteRequest}
                    >
                        <LinkWithIcon
                            icon='ejicon-user-delete'
                            label={'Profilom törlése'}
                            linkTo='#'
                            active={true}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}





export default Settings
