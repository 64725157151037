import React from 'react'
import { Carousel } from 'react-bootstrap'
import { Helmet } from "react-helmet";

import Tag from '../../core/tag/Tag'
import PageBottomSendMessage from '../../components/page_bottom_send_message/PageBottomSendMessage'

import dataProvider from '../../dataProvider';

import './mentors.css'

import LakySzilvi from '../../assets/img/mentors/LakySzilvi.jpg';

function Mentors(props: any) {

  const { modalPopper, closeModal, userProfile } = props;

  const [mentorsList, setMentorsList] = React.useState<any[]>([])
  const [contentFromApi, setContentFromApi] = React.useState<any[]>([])


  const forcedIcons: { [key: string]: string; } = {
    'pályaválasztás': 'ejicon-self-knowledge',
    'állásinterjúra való felkészülés': 'ejicon-money-bill',
    'tárgyalás': 'ejicon-money-bill',
    'onboarding': 'ejicon-communication',
    'önéletrajzírás': 'ejicon-money-bill',
    'motivációs levél': 'ejicon-bullhorn',
    'projektmenedzsment': 'ejicon-communication',
    'értékesítési készségek': 'ejicon-self-knowledge',
    'vezetői kompetenciák': 'ejicon-money-bill',
    'vállalati kultúra': 'ejicon-bullhorn',
    'érdekérvényesítés': 'ejicon-communication',
    'csapatmunka': 'ejicon-communication',
    'visszatérés munkahelyre': 'ejicon-communication',
    'problémamegoldás': 'ejicon-bullhorn',
    'logisztika': 'ejicon-bullhorn',
    'céltudatosság': 'ejicon-communication',
    'vezetői készségek': 'ejicon-bullhorn',
    'munkakeresés': 'ejicon-communication',
    'pályakezdés kihívásai': 'ejicon-self-knowledge',
    'munka-magánélet egyensúlya': 'ejicon-bullhorn',
    'rugalmasság': 'ejicon-communication'
  }

  const handleToggle = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    const myElement = e.currentTarget
    const myClasses = myElement.classList;
    if (myClasses.contains('mentor-card-wrapper_hover')) {
      myElement.classList.remove('mentor-card-wrapper_hover')
    } else {
      myElement.classList.add('mentor-card-wrapper_hover')
    }
    if (myClasses.contains(':hover')) {
      myElement.classList.remove(':hover')
    }
  };

  React.useEffect(() => {
    dataProvider.getMany('/mentors', (response: any) => {
      /* console.log(response) */
      if (response.status === 200) {
        setMentorsList(response?.data?.data || [])
      }
    })
    dataProvider.getMany('/pages/mentoraink', (response: any) => {
      if (response?.status === 200) {
        setContentFromApi(response?.data?.data?.content || [])
      }
    })


  }, [])

  const calculateIcon = (label: string, icon: string | null): string => {
    if (icon !== null) return `ejicon-${icon}`
    if (forcedIcons[label] !== undefined) return forcedIcons[label]
    return 'ejicon-self-knowledge'
  }

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Mentorprogram - enjovom.hu</title>
        <meta name="description" content="A Coca-Cola Magyarország munkavállalói mentorként segítik az ingyenes #énjövőm program résztvevőinek fejlődését" />
      </Helmet>
      <div className="cc-content mentors">
        <div className="cc-section order-0 back-section left-top mentors-nagy-kep  mt-5" style={{ marginBottom: '0px' }}>
          <img
            className="nagykep"
            alt={contentFromApi[0]?.alt}
            src={contentFromApi[0]?.image?.sizes?.original?.url}
            style={{ marginTop: '-160px' }}
          />
        </div>
        <div className="cc-section d-xl-flex flex-column justify-content-center mentors-header">
          <div className="coca-container">
            <div className="row section-title">
              <div className="col-7 mt-0 mt-lg-5">
                <h1>Az #énjövőm program mentorai</h1>
              </div>
            </div>
            <div className="row section-text mt-1 mt-lg-5">
              <div className="col col-6">
                <div dangerouslySetInnerHTML={{ __html: contentFromApi[1]?.intro || '' }} />
              </div>
            </div>
            <div className="row mentors-counter mt-sm-2 mt-lg-5">
              <div className="col-12 d-block d-lg-none">
                <Carousel >
                  {
                    (contentFromApi[2]?.icons || []).map((counterDetails: any, index: number) =>
                      index % 2 === 0 &&
                      <Carousel.Item className='home_carousel_item justify-content-center' key={index}>
                        <div className='row justify-content-center' >
                          <div className={`col-${contentFromApi[2]?.icons[index + 1] ? '6' : '12'}`}>
                            <div className='home_stat_element'>
                              <div className="home_stat_element_icon">
                                <Tag color='lightred' label='' leftIcon={true} icon={`ejicon-${counterDetails?.icon}`} rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                              </div>
                              <div className="home_stat_element_no heading_two">
                                <h2>{counterDetails.number}</h2>
                              </div>
                              <div className="home_stat_element_desc body_small">
                                {counterDetails.description}
                              </div>
                            </div>
                          </div>
                          {contentFromApi[2]?.icons[index + 1] &&
                            <div className="col-6">
                              <div className='home_stat_element'>
                                <div className="home_stat_element_icon">
                                  <Tag color='lightred' label='' leftIcon={true} icon={`ejicon-${contentFromApi[2]?.icons[index + 1]?.icon}`} rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                </div>
                                <div className="home_stat_element_no heading_two">
                                  <h2>{contentFromApi[2]?.icons[index + 1]?.number}</h2>
                                </div>
                                <div className="home_stat_element_desc body_small">
                                  {contentFromApi[2]?.icons[index + 1]?.description}
                                </div>
                              </div>
                            </div>}
                        </div>
                      </Carousel.Item>
                    )
                  }
                </Carousel>
              </div>
              {(contentFromApi[2]?.icons || []).map((counterDetails: any, index: number) =>
                <div className="col-6 col-md-2 d-none d-lg-block" key={index}>
                  <div className={`home_stat_element ${index === 0 && 'mr-auto'}`}>
                    <div className="home_stat_element_icon">
                      <Tag color='lightred' label='' leftIcon={true} icon={`ejicon-${counterDetails?.icon}`} rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                    </div>
                    <div className="home_stat_element_no heading_two">
                      <h2>{counterDetails.number}</h2>
                    </div>
                    <div className="home_stat_element_desc body_small">
                      {counterDetails.description}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="cc-sections mentors-section mt-2 mt-lg-5 mb-4">
          <div className="coca-container">
            <h2>Ismerd meg fekete öves mentorainkat!</h2>
            <div className="row gx-0 mentors-list">
              {mentorsList.map((mentorDetails: any) =>
                <div className="col-12 col-md-6 col-lg-4 col-xl-3 flip-card" key={mentorDetails.name}>
                  <div className="mentor-card-wrapper" onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleToggle(e)} >
                    <div className='flip-card-inner'>
                      <div className="row gx-0 mentor-card mentor-card-front">
                        <div className="mentor-card-header ratio ratio-1x1">
                          <div className="mentor-badge">
                            <button className="btn btn-circle bg-dark cl-white">
                              <span className="ejicon-crown">
                              </span>
                            </button>
                          </div>
                          <div className="mentor-image">
                            <img alt={mentorDetails?.name} src={mentorDetails?.image?.sizes?.original?.url || LakySzilvi} />
                          </div>
                        </div>
                        <div className="mentor-card-footer">
                          <div className="mentor-name">
                            <h5>{mentorDetails?.name}</h5>
                          </div>
                          <div className="mentor-helper">Ezekben tud neked segíteni</div>
                          <div className="mentor-tags row">
                            {(mentorDetails?.mentorTags || []).map((tagDetails: any, index: number) =>
                              index < 2 && <div className="">
                                <div className='col mb-2' key={index}>
                                  <Tag color={'red'} label={tagDetails.name + String.fromCharCode(160)} leftIcon={true} icon={calculateIcon(tagDetails?.name || '', tagDetails?.icon || null)} rightIcon={false} removeTag={() => { }}></Tag>
                                </div>
                              </div>)}
                            {mentorDetails?.mentorTags?.length < 2 &&
                              <div className='col mb-2' style={{ visibility: 'hidden' }}>
                                <Tag color={'red'} label={'kjakfgagflsgffsjbfjbhjvajhsv' + String.fromCharCode(160)} leftIcon={true} icon={'ejicon-self-knowledge'} rightIcon={false} removeTag={() => { }}></Tag>
                              </div>}
                            <div className='col mentor_tags_plus'>{mentorDetails?.mentorTags?.length > 2 ? `+${mentorDetails?.mentorTags?.length - 2}` :
                              <div style={{ visibility: 'hidden' }}>1</div>
                            }</div>
                          </div>
                        </div>
                      </div>
                      <div className="row gx-0 mentor-card mentor-card-back ">
                        <div className="mentor-name">
                          <h5>{mentorDetails?.name}</h5>
                          <div className="mentor-level">
                            <div className="tag bg-dark cl-white">
                              <span className="icon bg-dark">
                                <span className="cl-white ejicon-crown">
                                </span>
                              </span>
                              <span className="status-text">fekete öves mentor</span>
                            </div>
                          </div>
                        </div>
                        <div className="mentor-qa">
                          {(mentorDetails?.answers || []).map((answerDetails: any) =>
                            <div className="" key={answerDetails.answer}>
                              <div className="mentor-qa-q body_small">
                                <strong>{answerDetails?.question || ''}</strong>
                              </div>
                              <div className="mentor-qa-a body_small_mentor">
                                {answerDetails?.answer || ''}
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="mentor-tags">
                          <div className="mentor-helper mb-2 ">
                            <strong>Ezekben tud neked segíteni</strong>
                          </div>
                          <div className="mentor-tags justify-content-start">
                            {(mentorDetails.mentorTags || []).map((tagDetails: any) =>
                              <div className='mentor_tag_float_left mb-2 mr-1'>
                                <Tag color={'red'} label={tagDetails.name + String.fromCharCode(160)} leftIcon={true} icon={calculateIcon(tagDetails?.name || '', tagDetails?.icon || null)} rightIcon={false} removeTag={() => { }}></Tag>
                              </div>)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>)}
            </div>
          </div>
        </div>
      </div>
      <PageBottomSendMessage
        modalPopper={modalPopper}
        closeModal={closeModal}
        userProfile={userProfile}
      />
    </div>
  )
}

export default Mentors