import React from 'react'
import './login_reg.css'

import Login from './Login';
import ForgottenPassword from './ForgottenPassword';
import Registration from './Registration'

/* 
acceptEmail: true
acceptEmails: false
acceptTos: true
age: "30+"
education: "középiskola"
email: "todi72@fggg.com"
firstName: "Tóth"
gender: "male"
jobInPast: false
jobPresent: true
lastName: "Béla"
password: "AAaa123456"
region: "Békés"
*/



function LoginReg() {


    const [selectedPage, setSelectedPage] = React.useState(0)
    const subPages = [
        <Login />,
        <Registration />,
        <ForgottenPassword />
    ]


    return (
        <div>
            {subPages[selectedPage]}
        </div>
    )
}

export default LoginReg
