import { Link } from 'react-router-dom';
import './link.css';


interface link_with_icon_properties {
    icon: string | null,
    label: string,
    linkTo: string,
    active: boolean,
    download?: boolean
}


function LinkWithIcon(props: link_with_icon_properties) {

    const { label, icon, linkTo, active, download } = props;

    return (
        <div>
            {download ?
                <a
                    className={`heading_six link_with_icon ${active ? 'link_active' : ''}`}
                    href={linkTo}
                    target='_blank'
                    rel="noopener noreferrer"
                    download
                >
                    {icon ? <span className={`${icon} link_icon`} /> : null}
                    {label}
                </a>
                :
                <Link
                    className={`heading_six link_with_icon ${active ? 'link_active' : ''}`}
                    to={linkTo}
                >
                    {icon ? <span className={`${icon} link_icon`} /> : null}
                    {label}
                </Link>}
        </div>
    )
}

export default LinkWithIcon
