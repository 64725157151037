import React from 'react'

const loginSample = {
    email: "hohner.levente@albapartiscum.hu",
    password: "1944AAccdr!"
}



function Alhole(props: any) {
    return (
        <div>
        </div>
    )
}

export default Alhole
