import React from 'react'
import { Modal } from 'react-bootstrap';

import Button from '../../core/button/Button';
import Circle from '../../core/circle/Circle';
import Tag from '../../core/tag/Tag';

import authProvider from '../../authProvider';
import dataProvider from '../../dataProvider';

import './eventcard.css'

import courseDetailCheckGreen from "../../assets/img/course_detail/check_green.svg";
import courseDetailCheckGrey from "../../assets/img/course_detail/check_grey.svg";

interface eventcard_properties {
    title: string,
    description: string,
    tags: Array<any>,
    mainPicture: string,
    handleParentClick: Function,
    foundation: string | undefined,
    schedule: string,
    presentors: Array<string>,
    attendants: Array<any>,
    placement?: string,
    registered?: boolean,
    id?: number,
    availableSeats?: number,
    popMeUp?: boolean
}

function EventCard(props: eventcard_properties) {

    const { title,
        description,
        tags,
        mainPicture,
        handleParentClick,
        foundation,
        schedule,
        presentors,
        attendants,
        placement,
        registered,
        id,
        availableSeats,
        popMeUp
    } = props;

    const [showDetails, setShowDetails] = React.useState(false);
    const [extraEventDetails, setExtraEventDetails] = React.useState<any>({})

    const setModal = () => {
        if (id) {
            dataProvider.getOne('/events', id, '', (response: any) => {
                setExtraEventDetails(response.data.data)
                setShowDetails(true)
            })
        }
    }

    React.useEffect(() => {
        if (popMeUp) {
            setModal()
        }
    }, [popMeUp])

    return (
        <>
            <div
                className={`eventcard_container ${foundation ? 'container_foundation' : ''}`}
                onClick={setModal}
            >
                <div className={`eventcard_img_elements ratio ratio-16x9 ${foundation ? 'eventcard_img_elements_foundation' : ''}`}>
                    {!foundation && authProvider.checkAuth() ?
                        <div className="eventcard_bookmark">
                            <Circle
                                icon={`${registered ? 'ejicon-tick' : 'ejicon-calendar-add'}`}
                                disabled={false}
                                type="red"
                            />
                        </div>
                        : null
                    }
                    <div className="eventcard_img">
                        <img src={mainPicture} width='100%' alt='' />
                    </div>
                </div>
                <div className="eventcard_textbox">
                    <div className="eventcard_pre_title_text">
                        {foundation ?
                            <div className='eventcard_foundation'>{foundation}</div>
                            :
                            <div className='eventcard_schedule body_small d-flex justify-content-between'>
                                <div>
                                    <span className='ejicon-calendar eventcard_schedule_icon'></span>
                                    {schedule}
                                </div>
                                <div className=" mx-3">
                                    {placement}
                                </div>
                            </div>}
                    </div>
                    <div className="eventcard_title heading_five">
                        <h5>{title}</h5>
                    </div>
                    <div className="eventcard_desc body_small">
                        {foundation ? description
                            :
                            <div className="eventcard_presentors">
                                Előadók: <span className="body_bold">{presentors.join(', ')}</span>
                            </div>}
                    </div>
                    {!foundation ?
                        <div className="eventcard_avatars_container">
                            <span className="body_small">{attendants.length} résztvevő</span>
                        </div>
                        :
                        <div className='eventcard_schedule body_small'>
                            <span className='ejicon-calendar eventcard_schedule_icon'></span>
                            {schedule}
                        </div>
                    }
                    <div className="eventcard_tags">
                        {tags.map((tagProps: any, index: number) =>
                            <div key={`tag-${index}`}>
                                <Tag color={tagProps.color} label={tagProps.label} leftIcon={false} icon='' rightIcon={false} removeTag={() => { }} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Modal
                show={showDetails}
                onHide={() => setShowDetails(false)}
                dialogClassName='event_details_modal_content'
            >
                <Modal.Body>
                    <div className="d-flex flex-column mx-2">
                        <div className="d-flex flex-row justify-content-between mb-3 body_small">
                            <div>
                                <span className='ejicon-calendar eventcard_schedule_icon'></span>
                                {schedule}
                            </div>
                            <div style={{ color: '#F40009' }}
                                onClick={() => setShowDetails(false)}>
                                <span className='ejicon-close'></span>
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3 align-items-center">
                            <div className='mx-2 eventcard_details_img'>
                                <img src={extraEventDetails.registered ? courseDetailCheckGreen : courseDetailCheckGrey} alt="" className="me-2" />
                            </div>
                            <div className="heading_three">
                                {title}
                            </div>
                        </div>
                        <div className="d-flex mb-3 event_details_img_container ratio ratio-4x3">
                            <img src={mainPicture} width='100%' alt='' className='event_details_img' />
                        </div>
                        <div className="d-flex flex-row mb-3">
                            <div className=' mx-2'>
                                <span className='ejicon-user'></span>
                            </div>
                            <div className="body_normal">
                                Előadók: <span className="body_bold">{presentors.join(', ')}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                            <div className=' mx-2'>
                                <span className='ejicon-pin'></span>
                            </div>
                            <div className="body_normal">
                                Cím: <span className="body_bold">{extraEventDetails.online ? 'online esemény' : extraEventDetails.address}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                            <div className=' mx-2'>
                                <span className='ejicon-calendar'></span>
                            </div>
                            <div className="body_normal">
                                Jelentkezési határidő: <span className="body_bold">{extraEventDetails.applicationDeadline || extraEventDetails.date}</span>
                            </div>
                        </div>
                        <div className="d-flex flex-row">
                            <div className=' mx-2'>
                                <span className='ejicon-members'></span>
                            </div>
                            <div className="body_normal">
                                {!extraEventDetails.online ?
                                    <div>Szabad férőhelyek száma: <span className="body_bold">{extraEventDetails?.availableSeats}</span></div>
                                    : <div><span className="body_bold mx-2">{extraEventDetails.registeredUsersCount}</span>résztvevő</div>
                                }
                            </div>
                        </div>

                        <div className="d-flex flex-row mb-3 mt-3">
                            <div className="body_normal" dangerouslySetInnerHTML={{ __html: extraEventDetails.description }}>
                                {/*  {extraEventDetails.description} */}
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3">
                            <div className=' mx-2'>
                                <span className={`ejicon-${extraEventDetails.registered && 'tick'}`}></span>
                            </div>
                            <div className="body_normal">
                                {extraEventDetails.registered ? 'Erre az eseményre már regisztráltál' : 'Nem vagy még regisztrálva az eseményre'}
                            </div>
                        </div>
                        <div className="d-flex flex-row mb-3 align-items-center mt-3 ">
                            <div onClick={() => {
                                handleParentClick()
                                setShowDetails(false)
                            }}>
                                <Button
                                    type='primary'
                                    label={!extraEventDetails.registered ? 'Hozzáadom a naptárhoz' : 'Eltávolítom a naptárból'}
                                    disabled={false}
                                    icon={null}
                                    icon_position='' />
                            </div>
                            <div className="body_normal mx-4 event_popup_back"
                                onClick={() => setShowDetails(false)}
                            >
                                <span className={`ejicon-share`}></span>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default EventCard