/* 
properties:
    title: string,
    date: string,                  ===> datum és helyszin
    description: string,  
    isCompact: boolean             ===> mobilos nézetre nincs hatással
    mainPicture: string            ===> kép neve
    handleParentClick: Function    ===> callback függvény, belépéshez

*/

import React from 'react'
import './newscard.css'


interface newscard_properties {
    title: string,
    date: string,
    description: string,
    mainPicture: string,
    isCompact: boolean,
    handleParentClick: Function,
    type?: string
}

function NewsCard(props: newscard_properties) {

    const { title, date, description, mainPicture, isCompact, handleParentClick, type } = props;

    return (
        <div
            className={`newscard_container ${isCompact ? 'newscard_container_compact' : ''}`}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleParentClick(e)}
        >
            <div className="newscard_img_elements ratio ratio-16x9">
                <div className="newscard_img">
                    <img src={mainPicture} width='100%' alt="hír kép" />
                </div>
            </div>
            <div className="newscard_textbox">
                <div className="newscard_stats">
                    <div className="newscard_icon ejicon-calendar" style={{ visibility: type === 'text' ? 'visible' : 'hidden' }}>
                    </div>
                    {type === 'text' &&
                        <div className="newscard_date_place body_small">
                            {date}
                        </div>}
                </div>
                <div className="newscard_title heading_five">
                    {title}
                </div>
                <div
                    className="newscard_desc body_small"
                    dangerouslySetInnerHTML={{ __html: description }}
                >
                </div>
            </div>
        </div>
    )
}

export default NewsCard
