import './link_share.css'

function LinkShare() {
    return (
        <div>
            LinkShare
        </div>
    )
}

export default LinkShare
