import React from 'react';
import Button from '../../core/button/Button';
import courseDetailCheckGreen from "../../assets/img/course_detail/check_green.svg";
import dataProvider from '../../dataProvider';

export const Degrees = (props: any) => {

    const { courseList } = props;
    const [certificates, setCertificates] = React.useState<any[]>([]);

    React.useEffect(() => {
        let recentCertificates: any[] = [];
        dataProvider.getMany('/user/profile/certificates', (response: any) => {
            if (response.status === 200) {
                const reponseData = response.data?.data || [];
                reponseData.forEach((certificateDetails: any) => {
                    recentCertificates[certificateDetails.courseId] = certificateDetails.certificate
                });
                setCertificates(recentCertificates.map((data: any) => data))
            }
        })
    }, [courseList])

    return (
        <>
            {
                courseList.map((courseDetails: any, index: number) =>
                    (courseDetails?.finished) && (courseDetails.type === 'course') &&
                    <div className="card mb-4" key={courseDetails.id}>
                        <div className="card-body p-4">
                            <div className="d-flex row">
                                <div className='col-12 col-lg-7 mb-3'>
                                    <div className='d-flex flex-row mt-2'>
                                        <img className="me-2" src={courseDetailCheckGreen} alt="" />
                                        <h3 className="mb-0">{courseDetails.title}</h3>
                                    </div>
                                </div>
                                <div className="col-12 col-lg-5 ms-auto">
                                    {certificates[courseDetails.id] &&
                                        <a href={certificates[courseDetails.id]} target='_blank'>
                                            <Button
                                                type='secondary'
                                                label='Letöltöm az oklevelem'
                                                disabled={false}
                                                icon='ejicon-document-sign'
                                                icon_position='left' />
                                        </a>}
                                </div>
                            </div>
                        </div>
                    </div>
                )}
        </>
    );
};
