import React, { useState } from 'react'
import { Carousel, Container, Modal } from 'react-bootstrap'
import mentorsMentors from "../../assets/img/mentors/mentors_mentors.png"
import mentorsQuestions from "../../assets/img/mentors/mentors_questions.png"
import Mentorcard from '../../components/mentor/Mentorcard'
import sampleMentor from '../../assets/img/mentors/mentors_black_lady.png'
import sampleMentor2 from '../../assets/img/mentors/mentors_asian_man.png'
import sampleMentor3 from '../../assets/img/mentors/mentors_white_lady.png'
import sampleMentor4 from '../../assets/img/mentors/mentors_white_lady2.png'
import mentor_vector from "../../assets/img/news/Vector-3.svg"

import nagyKep from '../../assets/img/landing_page_img_1.png'
import Input from '../../core/inputs/Input'
import Button from '../../core/button/Button'
import CheckBox from '../../core/checkbox/CheckBox'
import contactImg from '../../assets/img/contact_img.png'
import telephoneImg from '../../assets/icons/telephone.svg'
import Tag from '../../core/tag/Tag'

import { Helmet } from "react-helmet";
import GulsenPeter from '../../assets/img/mentors/GulsenPeter.jpg';
import HajduPetra from '../../assets/img/mentors/HajduPetra.jpg';
import HarmatiTamas from '../../assets/img/mentors/HarmatiTamas.jpg';
import LakySzilvi from '../../assets/img/mentors/LakySzilvi.jpg';
import RecznitzerJudit from '../../assets/img/mentors/RecznitzerJudit.jpg';
import './albasa.css'



function Albasa() {
  const values = [true, 'sm-down', 'md-down', 'lg-down', 'xl-down', 'xxl-down'];
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);

  function handleShow(breakpoint: any) {
    setFullscreen(breakpoint);
    setShow(true);
  }


  return (
    <>

      <div onClick={() => handleShow(true)}>
        katt
      </div>
      <Modal
        className='fullscreen_modal modal-fullscreen'
        show={show}
        onHide={() => setShow(false)}
        /* fullScreen={true} */
        backdropClassName='modal-backdrop-blur'
        dialogClassName='modal-fullscreen'
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          {/**https://static.moonproject.io/coca-cola/enjovom/targyalasi_keszsegek/story.html*/}
          {/**https://enjovom.hu/storage/curriculum/az_idogazdalkodas_muveszete/story.html */}
          <iframe className='w-100 h-100' src="https://static.moonproject.io/coca-cola/enjovom/targyalasi_keszsegek/story.html">

          </iframe>
        </Modal.Body>

      </Modal>
    </>
  );
}

export default Albasa
