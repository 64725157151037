export const validate: any = {
    email: (email: string) => {
        const regexEmail = /^[a-zA-Z0-9.]+@[a-zA-Z0-9]+\.[A-Za-z]+$/
        return regexEmail.test(email)
    },
    password: (password: string) => {
        const regexPassword = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/
        return regexPassword.test(password)
    },
    simpleString: (inputString: string) => {
        return (inputString.length && inputString.length < 256)
    },
    longString: (inputString: string) => {
        return (inputString.length && inputString.length < 3001)
    }
}

