import React from 'react'
import { Carousel, Col, Container } from 'react-bootstrap'
import Tag from '../../core/tag/Tag'
import './about_us.css'
import us_vector from "../../assets/img/news/Vector-3.svg"

import { Helmet } from "react-helmet";
import PageBottomSendMessage from '../../components/page_bottom_send_message/PageBottomSendMessage'

import dataProvider from '../../dataProvider'

function AboutUs(props: any) {

    const { modalPopper, closeModal, userProfile } = props;

    const [contentFromApi, setContentFromApi] = React.useState<any[]>([])

    React.useEffect(() => {
        dataProvider.getMany('/pages/rolunk', (response: any) => {
            console.log('rolunk content')
            console.log(response?.data?.data)
            if (response?.status === 200) {
                setContentFromApi(response?.data?.data?.content || [])
            }
        })
    }, [])

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Rólunk - enjovom.hu</title>
                <meta name="description" content="Ismerd meg a Coca-Cola Magyarország fiatalok munkaerőpiaci elhelyezkedését támogató programját" />
            </Helmet>
            <div className="us-full-width-container1">
                <div className='row mt-5 ms-1 me-1 us_title'>
                    <h2>#énjövőm – mert benned is ott van a lehetőség, hogy kihozd magadból a maximumot!</h2>
                </div>
                <div className="col row gx-0 mt-5 section-images">
                    <div className="col-12 col-lg-6 d-none d-lg-flex text-start">
                        <img
                            alt={contentFromApi[0]?.alt}
                            style={{ width: '98%', borderRadius: '0px' }}
                            src={contentFromApi[0]?.image?.sizes?.original?.url} />
                    </div>

                    <div className="col-12 col-lg-6 ml-3 text-end">
                        <img alt={contentFromApi[1]?.alt} style={{ width: '98%', borderRadius: '0px' }} src={contentFromApi[1]?.image?.sizes?.original?.url} />
                    </div>
                </div>
            </div>
            <div className="us-full-width-container2 mt5 ">
                <Container>
                    <div className='row us_kikvagyunk mt-5 justify-content-center'>
                        <div className='col-12 us_title'>
                            <h3 className=''>{contentFromApi[2]?.title}</h3>
                        </div>
                        <div className='col-12 col-lg-9 body_normal text-left mt-2 mt-lg-4'>
                            <div dangerouslySetInnerHTML={{ __html: contentFromApi[2]?.intro || '' }} />

                        </div>
                    </div>
                </Container>
                <img className="us_bg-vector" src={us_vector} alt="" />
            </div>
            <Container className='mt-5'>
                <div className='row home_stats justify-content-md-center'>
                    <div className="col-12 d-block d-lg-none">
                        <Carousel >
                            {
                                (contentFromApi[3]?.icons || []).map((counterDetails: any, index: number) =>
                                    index % 2 === 0 &&
                                    <Carousel.Item className='home_carousel_item justify-content-center' key={index}>
                                        <div className='row justify-content-center' >
                                            <div className={`col-${contentFromApi[3]?.icons[index + 1] ? '6' : '12'}`}>
                                                <div className='home_stat_element'>
                                                    <div className="home_stat_element_icon">
                                                        <Tag color='lightred' label='' leftIcon={true} icon={`ejicon-${counterDetails?.icon}`} rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                                    </div>
                                                    <div className="home_stat_element_no heading_two">
                                                        <h2>{counterDetails.number}</h2>
                                                    </div>
                                                    <div className="home_stat_element_desc body_small">
                                                        {counterDetails.description}
                                                    </div>
                                                </div>
                                            </div>
                                            {contentFromApi[3]?.icons[index + 1] &&
                                                <div className="col-6">
                                                    <div className='home_stat_element'>
                                                        <div className="home_stat_element_icon">
                                                            <Tag color='lightred' label='' leftIcon={true} icon={`ejicon-${contentFromApi[3]?.icons[index + 1]?.icon}`} rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                                        </div>
                                                        <div className="home_stat_element_no heading_two">
                                                            <h2>{contentFromApi[3]?.icons[index + 1]?.number}</h2>
                                                        </div>
                                                        <div className="home_stat_element_desc body_small">
                                                            {contentFromApi[3]?.icons[index + 1]?.description}
                                                        </div>
                                                    </div>
                                                </div>}
                                        </div>
                                    </Carousel.Item>
                                )
                            }
                        </Carousel>
                    </div>
                    {(contentFromApi[3]?.icons || []).map((counterDetails: any, index: number) =>
                        <div className="col-6 col-md-2 d-none d-lg-block" key={index}>
                            <div className={`home_stat_element ${index === 0 && 'mr-auto'}`}>
                                <div className="home_stat_element_icon">
                                    <Tag color='lightred' label='' leftIcon={true} icon={`ejicon-${counterDetails?.icon}`} rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                </div>
                                <div className="home_stat_element_no heading_two">
                                    <h2>{counterDetails.number}</h2>
                                </div>
                                <div className="home_stat_element_desc body_small">
                                    {counterDetails.description}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </Container>
            <Container>
                <div className='row justify-content-center'>
                    <div className='col-12 col-lg-9 mb-5'>
                        <div className='row mt-5'>
                            <div className='col-12 mt-5 mb-2 text-center'>
                                <h3>{contentFromApi[4]?.title}</h3>
                            </div>
                        </div>
                        {(contentFromApi[4]?.cards || []).map((cardDetails: any, index: number) =>
                            index % 2 === 0 ?
                                <div className='row mt-5' key={index}>
                                    <div className='col-12 col-md-5 text-center my-auto'>
                                        <div className='us_img text-center'>
                                            <img src={cardDetails?.image?.sizes?.original?.url} alt={cardDetails.alt} />
                                        </div>
                                    </div>
                                    <div className='col-12 col-md-7 my-auto text-center text-md-start'>
                                        <h3 className='mt-4 mt-md-0'>{cardDetails?.title || ''}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: cardDetails.content }} />
                                    </div>
                                </div>
                                :
                                <div className='row mt-5'>
                                    <Col md={{ order: 2 }} className='col-12 col-md-5 text-center my-auto'>
                                        <div className='us_img text-center'>
                                            <img src={cardDetails?.image?.sizes?.original?.url} alt={cardDetails.alt} />
                                        </div>
                                    </Col>
                                    <Col md={{ order: 1 }} className='col-12 col-md-7 my-auto text-center text-md-start'>
                                        <h3 className='mt-4 mt-md-0'>{cardDetails?.title || ''}</h3>
                                        <div dangerouslySetInnerHTML={{ __html: cardDetails.content }} />
                                    </Col>
                                </div>
                        )}
                    </div>
                </div>
            </Container>
            <PageBottomSendMessage
                modalPopper={modalPopper}
                closeModal={closeModal}
                userProfile={userProfile}
            />
        </div>
    )
}

export default AboutUs