import Home from '../pages/home/Home'
import News from '../pages/news/News'
import CourseDetail from '../pages/course_detail/CourseDetail'
import CourseList from '../pages/course_list/CourseList'
import CourseSlide from '../pages/course_slide/CourseSlide'
import Dashboard from '../pages/dashboard/Dashboard'
import Events from '../pages/events/Events'
import LinkShare from '../pages/link_share/LinkShare'
import LoginReg from '../pages/login_reg/LoginReg'
import NewsDetail from '../pages/news_detail/NewsDetail'
import Partners from '../pages/partners/Partners'
import Profile from '../pages/profile/Profile'
import Settings from '../pages/settings/Settings'
import AboutUs from '../pages/us/AboutUs'
import Mentors from '../pages/mentors/Mentors'
import Alkosa from '../pages/developer_pages/Alkosa';
import Albasa from '../pages/developer_pages/Albasa';
import Alhole from '../pages/developer_pages/Alhole';
import Login from '../pages/login_reg/Login';
import Registration from '../pages/login_reg/Registration'
import ForgottenPassword from '../pages/login_reg/ForgottenPassword'




export const devMenuItems: Array<any> = [
    {
        path: '/forgotten',
        name: 'Alkosa',
        component: <ForgottenPassword />
    },
    {
        path: '/registration',
        name: 'Alkosa',
        component: <Registration />
    },
    {
        path: '/login',
        name: 'Alkosa',
        component: <Login />
    },
    {
        path: '/alkosa',
        name: 'Alkosa',
        component: <Alkosa />
    },
    {
        path: '/albasa',
        name: 'Albasa',
        component: <Albasa />
    },
    {
        path: '/alhole',
        name: 'Alhole',
        component: <Alhole />
    },
    {
        path: '/',
        name: 'Kezdőlap',
        component: <Home />
    },
    {
        path: '/news',
        name: 'Hírek',
        component: <News />
    },
    {
        path: '/course_detail/:id',
        name: 'Képzés részletei',
        component: <CourseDetail />
    },
    {
        path: '/course_slide/:id',
        name: 'course_slide?',
        component: <CourseSlide />
    },
    {
        path: '/course_list',
        name: 'Kurzus lista',
        component: <CourseList />
    },
    {
        path: '/dashboard',
        name: 'dashboard?',
        component: <Dashboard />
    },
    {
        path: '/events',
        name: 'Események',
        component: <Events />
    },
    {
        path: '/link_share',
        name: 'link_share?',
        component: <LinkShare />
    },
    {
        path: '/login_reg',
        name: 'Bejelentkezés/regisztráció',
        component: <LoginReg />
    },
    {
        path: '/mentors',
        name: 'Mentorok',
        component: <Mentors />
    },
    {
        path: '/news_detail/:id',
        name: 'Hír részletei',
        component: <NewsDetail />
    },
    {
        path: '/partners',
        name: 'Partnerek',
        component: <Partners />
    },
    {
        path: '/profile',
        name: 'Profil',
        component: <Profile />
    },
    {
        path: '/settings',
        name: 'Beálljtások',
        component: <Settings />
    },
    {
        path: '/about_us',
        name: 'Rólunk',
        component: <AboutUs />
    }
]