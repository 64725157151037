/* 
properties:
  disabled: true | false
  label: label
  leftIcon: left icon name
  rightIcon: right icon name
  value: current input value
  onChangeHandler: parent function for handling value changes
*/

import React from 'react'
import './inputs.css'

interface props {
    disabled: boolean,
    label: string,
    leftIcon: string | null,
    rightIcon: string | null,
    value: string,
    onChangeHandler: any,
    error: string,
    type: string,
    placeHolder: string,
    textArea?: boolean,
    handleRightIconClick?: Function,
    forcerightIconColor?: string,
    inputStyle?: object,
}

function Input(props: props) {

    const { disabled,
        label,
        leftIcon,
        rightIcon,
        value,
        onChangeHandler,
        error,
        type,
        placeHolder,
        textArea,
        handleRightIconClick,
        forcerightIconColor,
        inputStyle,
    } = props

    const disableInputClass = disabled ? 'input_disabled' : '';
    const errorMessage = error && error.length > 0 && !disabled ? <p className='body_tiny input_error_message'>{error}</p> : null
    const errorClass = error && error.length > 0 && !disabled ? 'input_error' : '';

    return (
        <>
            <div className={`input_container ${disableInputClass} ${errorClass}`}  >
                {leftIcon ? <div className={`${leftIcon} icon_left`}></div> : null}
                <div className='input_text_container'>
                    {label ? <p className="input_label body_tiny">{label}</p> : null}
                    {textArea ?
                        <textarea
                            className={`body_small textarea_field ${errorClass}`}
                            disabled={disabled}
                            value={value}
                            onChange={onChangeHandler}
                            placeholder={placeHolder}
                            style={inputStyle}
                        />
                        : <input
                            type={type}
                            className={`body_small input_field ${errorClass}`}
                            disabled={disabled}
                            value={value}
                            onChange={onChangeHandler}
                            placeholder={placeHolder}
                            style={inputStyle}
                            />}
                </div>
                {rightIcon ?
                    <div
                        className={`${rightIcon} icon_right`}
                        onClick={handleRightIconClick ? () => handleRightIconClick() : () => { }}
                        style={{ color: forcerightIconColor || undefined }}
                    >
                    </div>
                    : null}
            </div>
            <div>
                {errorMessage || String.fromCharCode(160)}
            </div>
        </>
    )
}

export default Input
