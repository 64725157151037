import React from 'react'
import { Container } from 'react-bootstrap'

import './footer.css'

import Tag from '../../core/tag/Tag'
import Input from '../../core/inputs/Input'
import CheckBox from '../../core/checkbox/CheckBox'
import Button from '../../core/button/Button'

import dataProvider from '../../dataProvider';
import { validate } from '../../validate';

/* import aszf from '../../assets/pdf/Terms_of_use_for_CCHBC_website_Youth_HU.pdf';
import adatkezelesi from '../../assets/pdf/CCHBC_Privacy_and_Cookie_Notice_Website_Youth_UPDATE_HU.pdf'; */
import deezerIcon from '../../assets/img/deezer.svg';
import spotifyIcon from '../../assets/img/spotify_icon.svg';
import applemusicIcon from '../../assets/img/deezer_icon.svg';
import googlepodcastIcon from '../../assets/img/googlepodcast_icon.svg';
import ccmologo from '../../assets/img/ccmo-02.svg'
import AppleMusicIcon from './applemusic_icon'
import SpotifyIcon from './spotify_icon'
import DeezerIcon from './deezer_icon'
import GooglePodcastIcon from './google_podcast_icon'

function Footer(props: any) {

    const { modalPopper, closeModal, userProfile } = props;

    const [adatkezelesi, setAdatkezelesi] = React.useState<string>('');
    const [aszf, setAszf] = React.useState<string>('');

    React.useEffect(() => {
        dataProvider.getMany('/site/settings', (response: any) => {
            console.log(response);
            if (response.status === 200) {
                setAdatkezelesi(response?.data?.data?.privacyPolicy || '');
                setAszf(response?.data?.data?.tos || '')
            }
        })
    }, [])

    return (
        <div className='footer'>
            <Container className=''>
                <div className='row footer_container'>
                    <div className='col-xl-6 col-md-6 col-sm-12 footer_logoc'>
                        <div className="row footer_logoc_row">
                            <img src={ccmologo} alt='Coca-Cola Magyarország' />
                        </div>
                        <div className="footer_container_left_text_field footer_menu_item">
                            A programot a <a href='https://www.coca-cola.hu/' target='_blank' rel="noreferrer"> <span className='footer_link_bold'>Coca-Cola Magyarország</span> </a> működteti.
                        </div>
                        <div className="footer_container_left_text_field footer_menu_item">
                            Az oldal tartalmát a <a href='https://www.coca-colahellenic.com/' target='_blank' rel="noreferrer"><span className='footer_link_bold'>Coca-Cola HBC</span></a> által kidolgozott módszertan és eredeti tananyagok alapján a <a href='https://www.bsscommunications.com/' target='_blank' rel="noreferrer"><span className='footer_link_bold'>BSS Communications Kft</span></a> kezeli.
                        </div>
                        <div className="footer_container_left_text_field footer_menu_item">
                            Az oldalt az <a href='https://ergomania.hu/' target='_blank' rel="noreferrer"><span className='footer_link_bold'> Ergománia</span> </a>fejlesztette.
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-6 col-6'>
                        <h5 className='footer_menu_title'>Segítség</h5>
                        <div className='col footer_menu_item'>
                            <a href='/rolunk'>Rólunk</a>
                        </div>
                        <div className='col footer_menu_item'
                            onClick={event => {
                                event.preventDefault(); modalPopper(<SendErrorReport
                                    closeModal={closeModal}
                                    userProfile={userProfile}
                                    adatkezelesi={adatkezelesi}
                                />)
                            }}>
                            <a href='#'>Hibajelentés</a>
                        </div>
                        <div className='col footer_menu_item'>
                            <a target='_blank' href={adatkezelesi} rel="noreferrer">Adatvédelem</a>
                        </div>
                        <div className='col footer_menu_item'>
                            <a target='_blank' href={aszf} rel="noreferrer">ÁSZF</a>
                        </div>
                        <div className='col footer_menu_item'
                            onClick={event => {
                                event.preventDefault(); modalPopper(
                                    <SendMessage
                                        closeModal={closeModal}
                                        userProfile={userProfile}
                                        adatkezelesi={adatkezelesi}
                                    />)
                            }}>
                            <a href='#'>Kérdésed van?</a>
                        </div>
                    </div>
                    <div className='col-xl-3 col-md-3 col-sm-6 col-6'>
                        <div className='col align-self-start'>
                            <div className='row justify-content-left footer_icons'>
                                <h5>Kövess minket</h5>
                                <div className='col-2 footer_icons_link' title="Facebook #énjövőm">
                                    <a href='https://www.facebook.com/enjovom' target='_blank' rel="noreferrer">
                                        <Tag color='red' label='' leftIcon={true} icon='ejicon-facebook' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                    </a>
                                </div>
                                <div className='col-2 footer_icons_link' title="Instagram #énjövőm">
                                    <a href='https://www.instagram.com/enjovom/' target='_blank' rel="noreferrer">
                                        <Tag color='red' label='' leftIcon={true} icon='ejicon-instagram' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                    </a>
                                </div>
                                <div className='col-2 footer_icons_link' title="Youtube #énjövőm">
                                    <a href='https://www.youtube.com/channel/UC15fDFNgDlubzHCNLxPLL6Q' target='_blank' rel="noreferrer">
                                        <Tag color='red' label='' leftIcon={true} icon='ejicon-youtube' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                    </a>
                                </div>
                            </div>
                            <div className='row row justify-content-left footer_icons'>
                                <h5>Hallgasd a podcastunkat</h5>
                                <div className='col-2 footer_icons_link' title="Apple music podcast #énjövőm">
                                    <a href='https://podcasts.apple.com/hu/podcast/%C3%A9nj%C3%B6v%C5%91m-podcast/id1556416766' target='_blank' rel="noreferrer">
                                        <AppleMusicIcon />
                                    </a>
                                    {/*<Tag color='red' label='' leftIcon={true} icon='ejicon-spotify' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />*/}
                                </div>
                                <div className='col-2 footer_icons_link' title="Spotify podcast #énjövőm">
                                    <a href='https://open.spotify.com/show/2eorUtgHsUKk2QGNto7obc' target='_blank' rel="noreferrer">
                                        <SpotifyIcon/>
                                    </a>
                                    {/*<Tag color='red' label='' leftIcon={true} icon='ejicon-spotify' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />*/}
                                </div>
                                <div className="w-100 d-block d-md-none"></div>
                                <div className='col-2 footer_icons_link' title="Deezer podcast #énjövőm">
                                    <a href='https://www.deezer.com/hu/show/2373432' target='_blank' rel="noreferrer">
                                        <DeezerIcon />
                                    </a>
                                    {/*<Tag color='red' label='' leftIcon={true} icon='ejicon-applemusic' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />*/}
                                </div>
                                <div className='col-2 footer_icons_link' title="Google podcast #énjövőm">
                                    <a href='https://podcasts.google.com/feed/aHR0cHM6Ly9mZWVkcy5idXp6c3Byb3V0LmNvbS8xNzIxMDIyLnJzcw==' target='_blank' rel="noreferrer">
                                        <GooglePodcastIcon />
                                    </a>
                                    {/*<Tag color='red' label='' leftIcon={true} icon='ejicon-google-podcast' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

const SendErrorReport = (props: any) => {
    const { closeModal, userProfile, adatkezelesi } = props;
    const [email, setEmail] = React.useState<string>(userProfile?.email || '');
    const [problemDescription, setProblemDescription] = React.useState('');
    const [cbIndex, setcbIndex] = React.useState(1);
    const [attachment, setAttachment] = React.useState<File | null>(null)
    const [confirmation, setConfirmation] = React.useState('');
    const checkBoxValues = [
        'checked', 'default'
    ]

    const hiddenFileInput = React.createRef<HTMLInputElement>()

    const handleClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };
    const valid = validate.email(email) &&
        validate.longString(problemDescription) &&
        cbIndex === 0;

    const trySendErrorReport = () => {
        if (valid) {
            console.log(attachment)
            const accessToken = localStorage.getItem('access_token');
            let formData = new FormData();
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${accessToken}`);
            formData.append('email', email);
            formData.append("message", problemDescription);
            if (attachment) {
                formData.append('attachment', attachment);
            }
            fetch(`/api/contact/error-report`, {
                method: 'POST',
                body: formData,
                headers: myHeaders
            })
                .then(res => {
                    if (res?.status === 200) {
                        setConfirmation('A hibaüzenet sikeresen elküldve');
                    } else {
                        /* valami hiba történt */
                    }
                })
                .catch(err => console.error(err))
        }
    }

    const handleChange = (e: any) => {
        const attachmentFile = e?.target?.files[0] || null;
        if (attachmentFile) {
            setAttachment(attachmentFile);
        }
    }

    return (
        <div>
            <div className='row form_box'>
                {
                    confirmation.length
                        ?
                        <div className='col-12'>
                            <div className='mt-2'>
                                <div className='row'>
                                    <div className='col-8'>
                                        <h5>{confirmation}</h5>
                                    </div>
                                    <div className='col-4 text-end' onClick={closeModal}>
                                        <span className='ejicon-close' style={{ color: '#F40000' }} />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4 col-12 error_button' onClick={closeModal}>
                                <Button type='primary' label='Rendben' disabled={false} icon={null} icon_position={null} />
                            </div>
                        </div>
                        :
                        <>
                            <div className='col-12'>
                                <div className='mt-2'>
                                    <div className='row'>
                                        <div className='col-8'>
                                            <h5>Hibajelentés</h5>
                                        </div>
                                        <div className='col-4 text-end' onClick={closeModal} style={{cursor: "pointer"}}>
                                            <span className='ejicon-close' style={{ color: '#F40000' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <Input
                                        disabled={userProfile?.email}
                                        label=''
                                        value={email}
                                        leftIcon=''
                                        rightIcon=''
                                        onChangeHandler={(e: any) => setEmail(e.target.value)}
                                        error=''
                                        type='text'
                                        placeHolder='Email címed'
                                    />
                                </div>
                                <div className='mt-3 hibajelentes_problema'>
                                    <Input
                                        disabled={false}
                                        label=''
                                        value={problemDescription}
                                        leftIcon=''
                                        rightIcon=''
                                        onChangeHandler={(e: any) => setProblemDescription(e.target.value)}
                                        error=''
                                        type='text'
                                        placeHolder='Írd le röviden a problémát'
                                        textArea={true}
                                    />
                                </div>
                                <div className='mt-3 row'>
                                    <div className='col-6'>
                                        <h6>Fájl csatolása</h6>
                                    </div>
                                    <div className='col-6'>
                                        <span className='ejicon-link-chain upload_file_container' onClick={handleClick} />
                                        <input type="file" name="image" accept="image/*" multiple={false} id="upload-photo" onChange={(e: any) => handleChange(e)} ref={hiddenFileInput} />
                                    </div>
                                    <span>{attachment ? attachment.name : ''}</span>
                                </div>
                                <div className='mt-3'>
                                    <div className='row'>
                                        <div className='col-1'>
                                            <CheckBox
                                                value={checkBoxValues[cbIndex]}
                                                disabled={false}
                                                handleValueChange={() => setcbIndex((cbIndex + 1) % 2)}
                                            />
                                        </div>
                                        <div className='col-10 body_small'>
                                            Az üzenet elküldésével elfogadom az
                                            <a className='alink_red' target='_blank' rel="noreferrer" href={adatkezelesi}>Adatkezelési tájékoztatóban</a> foglaltakat.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4 col-12 error_button' onClick={trySendErrorReport}>
                                <Button
                                    type='primary'
                                    label='Küldés'
                                    disabled={!valid}
                                    icon={null}
                                    icon_position={null}
                                />
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

const SendMessage = (props: any) => {
    const { closeModal, userProfile, adatkezelesi } = props;
    const fullName = userProfile?.firstName ? `${userProfile.lastName} ${userProfile.firstName}` : ''
    const [name, setName] = React.useState(fullName);
    const [email, setEmail] = React.useState<string>(userProfile?.email || '');
    const [message, setMessage] = React.useState('');
    const [cbIndex, setcbIndex] = React.useState(1);
    const [confirmation, setConfirmation] = React.useState('');

    const checkBoxValues = [
        'checked', 'default'
    ]

    const valid = validate.email(email) &&
        validate.simpleString(message) &&
        validate.simpleString(name) && cbIndex === 0;

    const trySendMessage = () => {
        if (valid) {
            const messageData = {
                name: name,
                email: email,
                message: message,
                acceptTos: true
            }

            dataProvider.createNew('/contact', messageData, (response: any) => {
                if (response?.status === 200) {
                    setConfirmation('Az üzenet sikeresen elküldve');
                }
            })
        }
    }

    return (
        <div>
            <div className='row form_box'>
                {
                    confirmation.length
                        ?
                        <div className='col-12'>
                            <div className='mt-2'>
                                <div className='row'>
                                    <div className='col-8'>
                                        <h5>{confirmation}</h5>
                                    </div>
                                    <div className='col-4 text-end' onClick={closeModal}>
                                        <span className='ejicon-close' style={{ color: '#F40000' }} />
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4 col-12 error_button' onClick={closeModal}>
                                <Button type='primary' label='Rendben' disabled={false} icon={null} icon_position={null} />
                            </div>
                        </div>
                        :
                        <>
                            <div className='col-12'>
                                <div className='mt-2'>
                                    <div className='row'>
                                        <div className='col-8'>
                                            <h5>Írj nekünk</h5>
                                        </div>
                                        <div className='col-4 text-end' onClick={closeModal} style={{cursor: "pointer"}}>
                                            <span className='ejicon-close' style={{ color: '#F40000' }} />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <Input
                                        disabled={userProfile?.firstName}
                                        label=''
                                        value={name}
                                        leftIcon=''
                                        rightIcon=''
                                        onChangeHandler={(e: any) => setName(e.target.value)}
                                        error=''
                                        type='text'
                                        placeHolder='Neved'
                                    />
                                </div>
                                <div className='mt-3'>
                                    <Input
                                        disabled={userProfile?.email}
                                        label=''
                                        value={email}
                                        leftIcon=''
                                        rightIcon=''
                                        onChangeHandler={(e: any) => setEmail(e.target.value)}
                                        error=''
                                        type='text'
                                        placeHolder='Email címed'
                                    />
                                </div>
                                <div className='mt-3 hibajelentes_problema'>
                                    <Input
                                        disabled={false}
                                        label=''
                                        value={message}
                                        leftIcon=''
                                        rightIcon=''
                                        onChangeHandler={(e: any) => setMessage(e.target.value)}
                                        error=''
                                        type='textarea'
                                        textArea={true}
                                        placeHolder='Kérünk, röviden írd le, mivel kapcsolatban fordulsz most hozzánk!'
                                    />
                                </div>
                                <div className='mt-3'>
                                    <div className='row'>
                                        <div className='col-1'>
                                            <CheckBox
                                                value={checkBoxValues[cbIndex]}
                                                disabled={false}
                                                handleValueChange={() => setcbIndex((cbIndex + 1) % 2)}
                                            />
                                        </div>
                                        <div className='col-10 body_small'>
                                            Az üzenet elküldésével elfogadom az <a className='alink_red' target='_blank' rel="noreferrer" href={adatkezelesi}>Adatkezelési tájékoztatóban</a> foglaltakat.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='mt-4 col-12 error_button' onClick={trySendMessage}>
                                <Button type='primary' label='Küldés' disabled={!valid} icon={null} icon_position={null} />
                            </div>
                        </>
                }
            </div>
        </div>
    )
}

export default Footer
