import React from 'react'
import { Container } from 'react-bootstrap'

import Button from '../../core/button/Button'
import CheckBox from '../../core/checkbox/CheckBox'
import Input from '../../core/inputs/Input'

import dataProvider from '../../dataProvider'
import { validate } from '../../validate';

import contactImg from '../../assets/img/contact_img.png'
import telephoneImg from '../../assets/icons/telephone.svg'
/* import adatkezelesi from '../../assets/pdf/CCHBC_Privacy_and_Cookie_Notice_Website_Youth_UPDATE_HU.pdf'; */

function PageBottomSendMessage(props: any) {
    const { modalPopper, closeModal, userProfile } = props;
    const fullName = userProfile?.firstName ? `${userProfile.lastName} ${userProfile.firstName}` : ''
    const [name, setName] = React.useState(fullName);
    const [email, setEmail] = React.useState<string>(userProfile?.email || '');
    const [message, setMessage] = React.useState('');
    const [cbIndex, setcbIndex] = React.useState(1);
    const [adatkezelesi, setAdatkezelesi] = React.useState<string>('');
    const checkBoxValues = [
        'checked', 'default'
    ]

    const valid = validate.email(email) &&
        validate.simpleString(message) &&
        validate.simpleString(name) && cbIndex === 0;

    React.useEffect(() => {
        dataProvider.getMany('/site/settings', (response: any) => {
            console.log(response);
            if (response.status === 200) {
                setAdatkezelesi(response?.data?.data?.privacyPolicy || '');
            }
        })
    }, [])


    const trySendMessage = () => {
        if (valid) {
            const messageData = {
                name: name,
                email: email,
                message: message,
                acceptTos: true
            }
            dataProvider.createNew('/contact', messageData, (response: any) => {
                console.log(response)
                if (response?.status === 200) {
                    modalPopper(
                        <Confirmation
                            closeModal={closeModal}
                            confirmation={'Az üzenet sikeresen elküldve'}
                        />
                    )
                } else {
                }
            })
        }
    }

    return (
        <Container>
            <div className='row mentors_form'>
                <div className='col-12 col-lg-6 mentors_form_inputs'>
                    <div className='mt-2'>
                        <h3>Kérdésed van?</h3>
                        <h3>Lépj velünk kapcsolatba!</h3>
                    </div>
                    <div className='mt-3'>
                        <Input
                            disabled={userProfile?.firstName}
                            label=''
                            value={name}
                            leftIcon=''
                            rightIcon=''
                            onChangeHandler={(e: any) => setName(e.target.value)}
                            error=''
                            type='text'
                            placeHolder='Neved'
                        />
                    </div>
                    <div className='mt-3'>
                        <Input
                            disabled={userProfile?.email}
                            label=''
                            value={email}
                            leftIcon=''
                            rightIcon=''
                            onChangeHandler={(e: any) => setEmail(e.target.value)}
                            error=''
                            type='text'
                            placeHolder='E-mail címed'
                        />
                    </div>
                    <div className='mt-3 hibajelentes_problema_1'>
                        <Input
                            inputStyle={{"padding-top": '10px'}}
                            disabled={false}
                            label=''
                            value={message}
                            leftIcon=''
                            rightIcon=''
                            onChangeHandler={(e: any) => setMessage(e.target.value)}
                            error=''
                            type='text'
                            placeHolder='Miben segíthetünk?'
                            textArea={true}
                        />
                    </div>
                    <div className='mt-3'>
                        <div className='row'>
                            <div className='col-1'>
                                <CheckBox
                                    value={checkBoxValues[cbIndex]}
                                    disabled={false}
                                    handleValueChange={() => setcbIndex((cbIndex + 1) % 2)}
                                />
                            </div>
                            <div className='col-10 body_small'>
                                Az üzenet elküldésével elfogadom az&nbsp;
                                <a target='_blank' rel="noreferrer" className='alink_red'
                                    href={adatkezelesi}>Adatkezelési tájékoztatóban</a> foglaltakat.
                            </div>
                        </div>
                    </div>
                    <div className='mt-4 col-12 error_button' onClick={trySendMessage}>
                        <Button type='primary' label='Küldés' disabled={!valid} icon={null} icon_position={null} />
                    </div>
                </div>
                <div className='ml-auto col-md-6 d-none d-lg-block mentors_kapcsolat_kep' >
                    <div className='mentors_form_telephone'>
                        <img src={telephoneImg} alt="telefon" />
                    </div>
                    <div className='mentors_form_img'>
                        <img src={contactImg} alt="kapcsolat" />
                    </div>
                </div>
            </div>
        </Container>
    )
}

const Confirmation = (props: any) => {

    const { confirmation, closeModal } = props

    return (
        <div className='row form_box'>
            <div className='col-12'>
                <div className='mt-2'>
                    <div className='row'>
                        <div className='col-8'>
                            <h5>{confirmation}</h5>
                        </div>
                        <div className='col-4 text-end' onClick={closeModal}>
                            <span className='ejicon-close' style={{ color: '#F40000' }} />
                        </div>
                    </div>
                </div>
                <div className='mt-4 col-12 error_button' onClick={closeModal}>
                    <Button type='primary' label='Rendben' disabled={false} icon={null} icon_position={null} />
                </div>
            </div>
        </div>
    )
}

export default PageBottomSendMessage
