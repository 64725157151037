/* 
properties:
color: string
label: string
leftIcon: boolean
icon: icon_name     ===> ejicon , ha van right_icon  ejicon-close
rightIcon: boolean 
removeTag : Function ====> Tag eltávolítása a szülő komponensből vagy () => {}         
*/


import React from 'react'
import './tag.css'

interface props {
    color: string,
    label: string,
    leftIcon: boolean,
    icon: string,
    rightIcon: boolean,
    removeTag: Function,
}

/* const predefinedTags: any = {
    Kommunikáció: {
        color: 'orange',
        icon: 'ejicon-communication'
    },
    Pénzügyek: {
        color: 'blue',
        icon: 'ejicon-money-bill'
    },
    Értékesítés: {
        color: 'purple',
        icon: 'ejicon-label-tag'
    },
    Marketing: {
        color: 'yellow',
        icon: 'ejicon-bullhorn'
    },
    Önismeret: {
        color: 'pink',
        icon: 'ejicon-self-knowledge'
    }
} */

const predefinedTags: any = {
    "önismeret és önfejlesztés": {
        color: 'orange',
        icon: 'ejicon-self-knowledge'
    },
    "projektmenedzsment": {
        color: 'blue',
        icon: 'ejicon-money-bill'
    },
    "vállalkozói készségek": {
        color: 'purple',
        icon: ''
    },
    "üzleti készségek": {
        color: 'yellow',
        icon: ''
    },
    "kommunikáció": {
        color: 'pink',
        icon: ''
    },
    "kapcsolatépítés": {
        color: 'lightblue',
        icon: ''
    },
    "vezetői készségek": {
        color: 'green',
        icon: ''
    },
    /* "önismeret": {
        color: 'orange',
        icon: 'ejicon-self-knowledge'
    },
    "motiváció": {
        color: 'orange',
        icon: 'ejicon-self-knowledge'
    },
    "álláskeresés": {
        color: 'red',
        icon: 'ejicon-self-knowledge'
    },
 */
}

function Tag(props: props) {


    let forcedColor: string | undefined = undefined;
    let forcedIcon: string | undefined = undefined;
    const { color, label, leftIcon, icon, rightIcon, removeTag } = props

    if (predefinedTags[label]) {
        forcedColor = predefinedTags[label].color;
        forcedIcon = predefinedTags[label].icon;
    }
    const isThereJustIconClass: string = label && label.length > 1 ? 'tag_lefticon' : 'just_icon';

    return (
        <div className={`tag_container tag_${forcedColor || color}`}>
            { leftIcon ?
                <div className={isThereJustIconClass}>
                    <div className={forcedIcon || icon} >
                    </div>
                </div>
                : null}

            { label && label.length > 1 ?
                <div className="tag_text body_bold body_small">
                    {label}
                </div> :
                null
            }
            { rightIcon ?
                <div className="tag_righticon">
                    <div className='ejicon-close' onClick={(e: any) => removeTag(e)}>
                    </div>
                </div>
                : null}
        </div>
    )
}

export default Tag
