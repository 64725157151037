import React from 'react'
import './dashboard.css'

import dashboardProjectRadio from "../../assets/img/dashboard/dashboard_radio.png"
import dashboardempty1 from "../../assets/img/dashboard/empty-1.png"
import emojiApplause from '../../assets/icons/emoji/applause.png';
import books from '../../assets/icons/emoji/books.png';
import clock from '../../assets/icons/emoji/clock.png';
import medal from '../../assets/icons/badge/gold_medal_alt.png';
import dashboardcase from '../../assets/icons/case.svg';
import ModulCard from '../../components/modul/ModulCard'
import Button from '../../core/button/Button'
import Circle from '../../core/circle/Circle'
import ProgressCircle from '../../core/progress_circle/ProgressCircle'
import { useHistory } from 'react-router-dom'
import dataProvider from '../../dataProvider'
import { TodoList } from '../profile/TodoList'
import { LineChart, Line, CartesianGrid, XAxis, YAxis } from 'recharts';
import { Helmet } from 'react-helmet'
import authProvider from '../../authProvider'
import dashboardBg from '../../assets/img/dashboard/dashboard_bg.svg'
import PodcastPlayer from "../../components/podcast-player/PodcastPlayer";


function Dashboard(props: any) {

    const { userProfile, courseList, eventList, refreshCourseList } = props
    const history = useHistory();

    const [subscribedCoursesList, setSubscribedCoursesList] = React.useState<any[]>([])
    const [bookmarkedCoursesList, setBookmarkedCoursesList] = React.useState<any[]>([])
    const [recommendedCoursesList, setRecommendedCoursesList] = React.useState<any[]>([])
    const [completedCoursesAmount, setCompletedCoursesAmount] = React.useState<number>(0);
    const [filteredEventList, setFilteredEventList] = React.useState([]);
    const [statistics, setStatistics] = React.useState<any>({});
    const [drawableData, setDrawableData] = React.useState<any[]>([]);
    const [totalWeeklyHours, setTotalWeeklyHours] = React.useState(0);
    const dayAbbrevations = ['V', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo'];
    const englishDayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    const [width, setWidth] = React.useState(0);
    const [height, setHeight] = React.useState(0);
    const chartHolderRef = React.createRef<HTMLDivElement>()


    React.useEffect(() => {
        !authProvider.checkAuth() && history.push('/')
        const subscribedList: any[] = [];
        const bookmarkedList: any[] = [];
        const recommendedList: any[] = [];

        courseList.forEach((course: any) => {
            if (course.bookmarked && !course.subscribed) { bookmarkedList.push(course) };
            if (course.subscribed) { subscribedList.push(course) };
            if (!course.bookmarked && !course.subscribed && recommendedList.length < 2) { recommendedList.push(course) };
        })
        setSubscribedCoursesList(subscribedList);
        setCompletedCoursesAmount(subscribedList.filter(sub => sub.finished).length);
        setBookmarkedCoursesList(bookmarkedList);
        setRecommendedCoursesList(recommendedList);
    }, [courseList, JSON.stringify(courseList)])

    React.useEffect(() => {
        let filteredList: any = [];
        const dateNow = new Date().getTime();
        eventList.forEach((eventDetails: any) => {
            if (new Date(eventDetails?.date).getTime() > dateNow && filteredList.length < 2 && eventDetails.registered) {
                filteredList.push(eventDetails);
            }
        })
        setFilteredEventList(filteredList)
    }, [eventList])

    const handleHHistoryChange = (id: number | string) => {
        history.push(`/tananyagok/${id}`)
    }


    React.useEffect(() => {
        const currentDay = new Date().getDay();
        let newDataArray: any[] = []
        let totalHours = 0;
        if (statistics?.dailyHours) {
            for (let index = currentDay + 1; index < currentDay + 8; index++) {
                newDataArray.push(
                    {
                        name: dayAbbrevations[index % 7],
                        hours: statistics?.dailyHours[englishDayNames[index % 7]]
                    }
                )
                totalHours += statistics?.dailyHours[englishDayNames[index % 7]]
            }
        }
        setDrawableData(newDataArray);
        setTotalWeeklyHours(totalHours);
    }, [statistics])


    if (chartHolderRef) {
        setTimeout(() => {
            const rect: any = chartHolderRef.current?.getBoundingClientRect()
            if (rect) {
                setHeight(rect.width * 0.6);
                setWidth(rect.width * 0.95);
            }
        }, 100);

    }
    React.useEffect(() => {
        dataProvider.getMany('/user/statistics', (response: any) => {
            setStatistics(response?.data?.data || []);
        })
        refreshCourseList()
    }, [])

    const removeBookmarked = (courseId: number) => {
        dataProvider.updateSpecial('/courses', courseId, 'bookmark', {}, (response: any) => refreshCourseList());
        /*  refreshCourseList() */
    }

    const calculateHHMMFromMinutes = (weeklyMinutes: number): string => {
        const MINUTES = weeklyMinutes % 60
        const HOURS = (weeklyMinutes - MINUTES) / 60
        return `${HOURS} óra ${MINUTES} perc`
    }
    
    return (
        <>

            <div className="dashboard-container">

                <img src={dashboardBg} alt="énjövőm kezelőpult" />
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-12 col-lg-8 ">
                            <div className="d-flex flex-row align-items-center mb-5">
                                <img src={emojiApplause} className='me-3' alt="" />
                                <h2 className="dashboard_h2 mb-0">Szia, <span>{userProfile.firstName}</span></h2>
                            </div>
                            {subscribedCoursesList.length > 0 && completedCoursesAmount < subscribedCoursesList.length &&
                                <h5>Megkezdett moduljaid</h5>}
                            {
                                subscribedCoursesList.map((courseDetails) =>
                                    <div key={`s-${courseDetails.id}`}>
                                        {courseDetails?.progress?.progress < 100 &&
                                            <div className="card mb-4"  >
                                                <div className="card-body">
                                                    {/* Csak tablet kijelzőtől*/}
                                                    <div className="d-none d-md-flex flex-row align-items-center justify-content-between">
                                                        <div className="d-flex flex-row align-items-center">
                                                            <img src={courseDetails?.cover?.sizes?.course?.url} alt="" style={{ objectFit: 'cover', height: '80px', width: '80px' }} />
                                                            <p className="mb-0 ms-4"><b>{courseDetails.title}</b></p>
                                                        </div>

                                                        {/* <img className="me-5 ms-auto" src={dashboardChart} alt="" /> */}
                                                        <div className="me-5 ms-auto">
                                                            <div className="progress_container">
                                                                <ProgressCircle progress={courseDetails?.progress?.progress || 0} />
                                                            </div>
                                                        </div>
                                                        <div className="" onClick={() => handleHHistoryChange(courseDetails.slug)}>
                                                            <Button
                                                                type='primary'
                                                                disabled={false}
                                                                icon={null}
                                                                label='Folytatom'
                                                            />
                                                        </div>
                                                    </div>
                                                    {/* Csak tablet kijelzőtől*/}
                                                    {/* Csak mobil kijelzőn*/}
                                                    <div className="d-flex d-md-none flex-row align-items-center">
                                                        <img src={courseDetails?.cover?.sizes?.course?.url} alt="" className="resize-img-size" />
                                                        <div className="ms-3">
                                                            <p className="mb-3"><b>{courseDetails.title}</b></p>
                                                            <div className="d-flex flex-row align-items-center">
                                                                <div className="" onClick={() => handleHHistoryChange(courseDetails.slug)}>
                                                                    <Button type='primary' disabled={false} icon={null} label='Folytatom' />
                                                                </div>
                                                                <div className="progress_container_mobile">
                                                                    <ProgressCircle progress={courseDetails?.progress?.progress || 0} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Csak mobil kijelzőn*/}
                                                </div>
                                            </div>}
                                    </div>
                                )}

                            {bookmarkedCoursesList.length > 0 && <h5 className="mt-5">Kezd el mentett moduljaid</h5>}
                            {bookmarkedCoursesList.map((courseDetails) =>
                                <div className="card mb-4" key={`b-${courseDetails.id}`}>
                                    <div className="card-body">
                                        {/* Csak tablet kijelzőtől*/}
                                        <div className="d-none d-md-flex flex-row align-items-center justify-content-between">
                                            <div className="d-flex flex-row align-items-center">
                                                <img src={courseDetails?.cover?.sizes?.course?.url} alt="" style={{ objectFit: 'cover', height: '80px', width: '80px' }} />
                                                <p className="mb-0 ms-4"><b>{courseDetails.title}</b></p>
                                            </div>
                                            <div className="ms-auto d-flex flex-row">
                                                <div className="d-flex" onClick={() => removeBookmarked(courseDetails.id)}>
                                                    <Circle icon='ejicon-bookmark' disabled={false} type="white" />
                                                </div>
                                                <div className="ms-3">
                                                    <div className="" onClick={() => handleHHistoryChange(courseDetails.slug)}>
                                                        <Button type='primary' disabled={false} icon={null} label='Elkezdem' />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        {/* Csak tablet kijelzőtől*/}
                                        {/* Csak mobil kijelzőn*/}
                                        <div className="d-flex d-md-none flex-row align-items-center">
                                            <img src={courseDetails?.cover?.sizes?.course?.url} alt="" className="resize-img-size" />
                                            <div className="ms-3">
                                                <p className="mb-3"><b>{courseDetails.title}</b></p>
                                                <div className="d-flex flex-row align-items-center">
                                                    <div className="" onClick={() => handleHHistoryChange(courseDetails.slug)}>
                                                        <Button type='primary' disabled={false} icon={null} label='Elkezdem' />
                                                    </div>
                                                    <div className="ms-2">
                                                        <Circle icon='ejicon-bookmark' disabled={false} type="white" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* Csak mobil kijelzőn*/}
                                    </div>
                                </div>
                            )}
                            <h5 className="mt-5">
                                <div className="d-flex flex-row justify-content-between">
                                    <span>Neked ajánlott modulok</span>
                                    <span className="text-cc-primary font-size-16 d-none d-md-inline" onClick={() => history.push('/tananyagok')}>Összes modul </span>
                                </div>
                            </h5>
                            <div className="row">
                                {recommendedCoursesList.map((courseDetails) =>
                                    <div className="col-12 col-md-6" onClick={refreshCourseList} key={courseDetails.id}>
                                        <ModulCard
                                            title={courseDetails.title}
                                            description={courseDetails.shortDescription}
                                            rating={courseDetails.rating}
                                            nrOfRatings={courseDetails.reviewsCount}
                                            nrOfStudents={courseDetails.students}
                                            tags={['networking', 'kommunikáció']}
                                            percentage={0}
                                            mainPicture={courseDetails?.cover?.sizes?.course?.url}
                                            handleParentClick={() => handleHHistoryChange(courseDetails.slug)}
                                            id={courseDetails.id}
                                            bookmarked={courseDetails.bookmarked}
                                            refresher={refreshCourseList}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="d-block d-md-none text-center text-cc-primary font-size-16"><span>Összes modul</span></div>
                            <h5 className="mt-5">Újdonságok</h5>
                            <div className="cc-project-radio mb-4">
                                <div className="content">
                                    <h1 className='d-none d-xl-block'>Megjelent az #énjövőm podcast legfrissebb epizódja!</h1>
                                    <h2 className='heading_two d-block d-xl-none' style={{ color: '#ffffff' }}>Megjelent az #énjövőm podcast legfrissebb epizódja!</h2>
                                    <div className='mb-3'>
                                        <PodcastPlayer/>
                                    </div>

                                    <div className="d-none d-md-block">
                                        <a href='https://www.buzzsprout.com/1721022' target='_blank' rel="noreferrer" className="btn bg-red cl-white registration">Összes epizód</a>
                                    </div>
                                    <div className="d-block d-md-none podcast-button-thing">
                                        <a href='https://www.buzzsprout.com/1721022' target='_blank' rel="noreferrer" className="btn bg-red cl-white registration">Összes epizód</a>
                                    </div>
                                </div>
                                <img className="backdrop-img" src={dashboardProjectRadio} alt="" />
                            </div>
                        </div>
                        <div className="d-block col-12 col-lg-4">

                            <h5>Statisztikáid</h5>
                            {!(subscribedCoursesList.length + bookmarkedCoursesList.length) ? <div className="card mb-3">
                                <div className="card-body text-center p-4 d-flex flex-column align-items-center">
                                    <img src={dashboardempty1} alt="" height="56" width="56" />
                                    <p className="text-center mt-3"><b>Még nincs elkezdett modulod</b></p>
                                    <div className="" onClick={() => history.push('/tananyagok')}>
                                        <Button
                                            type='primary'
                                            disabled={false}
                                            icon={null}
                                            label='Megnézem a modulokat'
                                        />
                                    </div>
                                </div>
                            </div>
                                :
                                <>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="d-flex flex-row justify-content-start">
                                                <div className="sidebar-iconholder sidebar-iconholder-grey">
                                                    <img src={books} alt="" />
                                                </div>
                                                <div className="ms-3">
                                                    <p className="mb-0">{statistics.completedCourses} modult</p>
                                                    <p className="mb-0">végeztél el</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card mb-3">
                                        <div className="card-body">
                                            <div className="d-flex flex-row justify-content-start">
                                                <div className="sidebar-iconholder sidebar-iconholder-grey">
                                                    <img src={medal} alt="" />
                                                </div>
                                                <div className="ms-3">
                                                    <p className="mb-0">{statistics?.badgeCount} jelvényt</p>
                                                    <p className="mb-0">szereztél eddig</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="d-flex flex-row justify-content-start">
                                                <div className="sidebar-iconholder sidebar-iconholder-grey">
                                                    <img src={clock} alt="" />
                                                </div>
                                                <div className="ms-3">
                                                    <p className="mb-0">{statistics.totalHours} órát</p>
                                                    <p className="mb-0">töltöttél tanulással</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h5 className="mt-5">Ennyi órát tanultál a héten</h5>
                                    <div className="card ">
                                        <div className="card-body" ref={chartHolderRef}>
                                            <h4 >{calculateHHMMFromMinutes(totalWeeklyHours)} </h4>
                                        </div>
                                        <LineChart width={width} height={height} data={drawableData}>
                                            <Line type="monotone" dataKey="hours" stroke="#FF9901" strokeWidth={2} />
                                            <CartesianGrid stroke="#FFEFF0;" />
                                            <XAxis dataKey="name" />
                                            <YAxis />
                                        </LineChart>
                                        <div className='d-flex '>
                                            <p className="mb-0 ps-3 mb-3">Csak így tovább, lenyűgöző szorgalom!</p>
                                        </div>
                                    </div>
                                </>}
                            <h5 className="mt-5">Tennivalók</h5>
                            <TodoList />
                            <h5 className="mt-5">
                                <div className="d-flex flex-xl-row justify-content-between">
                                    <span>Közelgő eseményeid</span>
                                    <span
                                        className="text-cc-primary font-size-16 d-none d-md-inline"
                                        onClick={() => history.push(`/esemenyek`)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        Megnézem a naptáram
                                    </span>
                                </div>
                            </h5>
                            {filteredEventList.map((eventDetails: any, index: number) =>
                                <div className="card mb-3" style={{ cursor: 'pointer' }} key={index}>
                                    <div
                                        className="card-body"
                                        onClick={() => history.push(`/esemenyek/${eventDetails.slug}`)}
                                    >
                                        <div className="d-flex flex-xl-row justify-content-lg-center justify-content-xl-start">
                                            <div className="sidebar-iconholder sidebar-iconholder-blue">
                                                <img src={dashboardcase} alt="" />
                                            </div>
                                            <div className="ms-3">
                                                <p className="mb-0 text-primary">{eventDetails.date.split(' ')[0]}</p>
                                                <p className="mb-0">{eventDetails.title}</p>
                                            </div>
                                            <div className="ms-auto">
                                                <span>{eventDetails.city || eventDetails.online && 'online'}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>)}
                            <div
                                className="d-block d-md-none text-center text-cc-primary font-size-16"
                                onClick={() => history.push(`/esemenyek`)}
                            ><span>Megnézem a naptáram</span></div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}


export default Dashboard
