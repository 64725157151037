





import React from 'react'
import './notification.css'

interface notification_properties {
    title: string,
    description: string,
    date: string,
    type: string,
    profil: boolean,
    isReaded: boolean,
    handleClose?: Function
}

function Notification(props: notification_properties) {

    const { title, description, date, type, profil, isReaded, handleClose } = props
    const leftIcon = type === 'warn' ? 'ejicon-bell' : type === 'error' ? 'ejicon-exclamation-alt' : 'ejicon-tick';
    const rightIcon = !profil ? 'ejicon-close' : !isReaded ? 'notification_dot' : '';
    const color = type === 'warn' ? 'orange' : type === 'error' ? 'red' : 'green';
    const isClosable = !profil;
    const backgroundColor = profil ? 'white' : type === 'warn' ? 'yellow' : type === 'error' ? 'red' : 'green';


    return (
        <div
            className={`notification_container_alt notification_notification_container_color_${backgroundColor}${!isReaded ? '_with_shadow' : ''}`}
            title="Értesítések"
        >
            <div
                className={`notification_lefticon_pic_bell notification_lefticon_color_${color} notification_left_icon`}
            >
                <div
                    className={`${leftIcon} notification_lefticon_pic notification_lefticon_pic_color`}
                >
                </div>
            </div>
            <div className="notification_text_container_alt ">
                <div className={`notification_text_title_color_${color} heading_six`}>
                    <div> {title}  </div>
                </div>
                <div className="notification_text_desc body_small">
                    {description}
                </div>
                {date && date?.length > 0 ? <div className="notification_text_date body_small">
                    {date}
                </div>
                    : null}
            </div>
            <div className={`notification_righticon notification_righticon_color_${color}`}
                onClick={handleClose ? (e: any) => handleClose(e) : () => { }}
            >
                <div className={`${rightIcon} notification_righticon_pic`} >
                </div>
            </div>
        </div>
    )
}

export default Notification
