import React from 'react'
import Button from '../../core/button/Button'
import './login_reg.css'
import { Container } from 'react-bootstrap'
import './login.scss'
import enjovom_bejelentkezes_csoportkep from '../../assets/img/enjovom_bejelentkezés_csoportkép.jpg'
import Input from '../../core/inputs/Input'
import DropInput from '../../core/inputs/DropInput'
import { countiesList, agesList, engHunGenderTranslator, educationList } from '../../constants/userData';
import RadioButton from '../../core/radio_button/RadioButton'
import CheckBox from '../../core/checkbox/CheckBox'
import authProvider from '../../authProvider';
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import dataProvider from '../../dataProvider'
import { validate } from '../../validate'

let ageDropArray: Array<string> = []
for (let index = 0; index < 101; index++) {
    ageDropArray[index] = JSON.stringify(index)
}

interface user_profile {
    lastName: string,
    firstName: string,
    age: string,
    gender: string,
    region: string,
    education: string,
    jobPresent: boolean,
    jobInPast: boolean,
    email: string,
    acceptEmails: boolean,
    password: string,
    acceptTos: boolean
}

const emptyProfile: user_profile = {
    lastName: "",
    "firstName": "",
    "age": "",
    "gender": "",
    "region": "",
    "education": "",
    "jobPresent": true,
    "jobInPast": true,
    "email": "",
    "acceptEmails": false,
    "password": '',
    "acceptTos": false
}

function Registration(props: any) {
    const { modalPopper, closeModal } = props;
    /* const { setSelectedPage } = props */
    const [userProfile, setUserProfile] = React.useState(emptyProfile);
    const history = useHistory();

    const [adatkezelesi, setAdatkezelesi] = React.useState<string>('');
    const [aszf, setAszf] = React.useState<string>('');


    React.useEffect(() => {
        authProvider.checkAuth() && history.push('/kezelopult');
        dataProvider.getMany('/site/settings', (response: any) => {
            console.log(response);
            if (response.status === 200) {
                setAdatkezelesi(response?.data?.data?.privacyPolicy || '');
                setAszf(response?.data?.data?.tos || '')
            }
        })
    }, [])


    return (
        <Container fluid>
            <div className='d-flex flex-lg-row flex-xl-row flex-column-reverse flex-md-column-reverse'>
                <div className='login_container d-flex align-items-start flex-column col-xl-6 col-lg-6 col-md-12 col-12'>
                    <Page
                        userProfile={userProfile}
                        modalPopper={modalPopper}
                        closeModal={closeModal}
                        adatkezelesi={adatkezelesi}
                        aszf={aszf}
                    />
                </div>
                <div className="login_picture_container col-xl-6 col-lg-6 col-md-12 col-12">
                    <Container fluid className='login_picture_container_inside text-end '>
                        <img src={enjovom_bejelentkezes_csoportkep} alt="" className="login_responsive_img" />
                        <div className="login_maximum col-xl-8 col-lg-8 col-md-10 col-10 heading_two">
                            <div className='col login_hozd'>Hozd ki magadból<br />a maximumot!</div>

                        </div>
                    </Container>
                </div>
            </div>
        </Container>
    )
}

const Page = (props: any) => {

    const { userProfile, closeModal, adatkezelesi, aszf } = props
    const [pageNumber, setPageNumber] = React.useState(1);
    const [liveUserData, setLiveUserData] = React.useState(userProfile);
    const [pswdInputType, setPswdInputType] = React.useState('password')
    const registrationTop = React.useRef<null | HTMLDivElement>(null);

    const baseGenderButtons = [
        {
            label: 'female',
            rightIcon: 'ejicon-women',
            checked: liveUserData.gender === 'female'
        },
        {
            label: 'male',
            rightIcon: 'ejicon-men',
            checked: liveUserData.gender === 'male'
        },
        {
            label: 'NA',
            rightIcon: '',
            checked: liveUserData.gender === 'NA'
        },

    ]
    const [genderRadioButtons, setGenderRadioButtons] = React.useState(baseGenderButtons)
    const [manualEducationSelection, setManualEducationSelection] = React.useState(false);

    const scrollToPageTop = () => {
        return registrationTop!.current!.scrollIntoView();
    };

    const handleInputChange = (key: string, value: any): void => {
        let newValues = { ...liveUserData }
        newValues[key] = value;
        setLiveUserData(newValues);
    }

    const handleGenderChange = (index: number) => {
        let newGenderArray = genderRadioButtons;
        newGenderArray = newGenderArray.map((buttonProps, buttonIndex) => ({ ...buttonProps, checked: index === buttonIndex }))
        handleInputChange('gender', genderRadioButtons[index].label);
        setGenderRadioButtons(newGenderArray);
    }

    const handleEducationDrop = (targetValue: any) => {
        if (targetValue === 'egyéb, kérjük add meg') {
            setManualEducationSelection(true);
        }
        else {
            handleInputChange('education', targetValue)
        }
    }

    const validatePage1 = (): boolean => {
        return ((liveUserData.lastName.length > 0 &&
            liveUserData.firstName.length > 0 &&
            liveUserData.age.length > 0 &&
            liveUserData.gender.length > 0 &&
            liveUserData.region.length > 0 &&
            liveUserData.education.length > 0
        ))
    }

    const valdatePage2 = (): boolean => {
        return validate.email(liveUserData.email) 
            && validate.password(liveUserData.password) 
            && liveUserData.acceptTos
    }

    const handlePageChange = (): void => {
        const valid = validatePage1();
        if (valid) {
            setPageNumber(2)
            scrollToPageTop()
        }
    }

    const tryToRegister = (): void => {
        console.log(liveUserData);
        authProvider.registration(liveUserData, (responseStatus: any) => {
            /*  routing to? */

            if (responseStatus === 201) {
                setPageNumber(3)
                scrollToPageTop()
            }
            /* else {
                 console.log('Valami hiba történt: ' + responseStatus)
             }*/
        })
    }

    const SuccessPage = () => {
        return (
            <div>
                <h3 className='d-flex flex-row  login_greetings_container mt-2' onClick={closeModal}>
                    Regisztrációd megerősítése céljából email üzenetet küldtünk neked.
                </h3>
                <br></br><br></br>
                <h5>
                    Kérjük, hogy nézd meg a postaládádat és kövesd az e-mailünkben található utasításokat!
                </h5>


            </div>
        )
    }



    const toggleShowPassword = () => {
        pswdInputType === 'password' ? setPswdInputType('text') : setPswdInputType('password')
    }

    return (
        <div ref={registrationTop}>

            {pageNumber === 1 ?
                <>
                    {/* page 1 */}
                    <div className='d-flex align-items-start flex-column'>
                        <div className='d-flex flex-row heading_one login_greetings_container mt-2 mt-lg-5 mb-0'>
                            <h1 className='d-none d-lg-block'>Örülünk, hogy úgy döntöttél, csatlakozol hozzánk!</h1>
                            <h2 className='d-block d-lg-none'>Örülünk, hogy úgy döntöttél, csatlakozol hozzánk!</h2>
                        </div>
                        <div className='reg_text'>Szeretnénk jobban megismerni téged, hogy személyre szabottan segíthessünk a továbbiakban. </div>
                        <div className='d-flex  login_forgot body_small flex-column flex-xl-row flex-md-column flex-lg-row mt-2 mt-lg-3'>
                            <div className='d-flex flex-row login_stay justify-content-start '>
                                <div className='col reg_van_mar_text'>Van már fiókod?</div>
                                {/* routing Login */}
                                <div /* onClick={() => setSelectedPage(0)} */ className='body_bold login_page_changer col'>
                                    <Link className='body_bold login_page_changer align-middle' to='/bejelentkezes'>
                                        Belépek
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='heading_four  login_bejelentkezes'>
                        <h4>Regisztráció</h4>
                    </div>
                    <div className='inputs_container col-12'>
                        <div className="heading_six login_question mb-2">Mi a neved?</div>
                        <div className="input_holder d-flex flex-column ">
                            <div className="col-12 col-lg-10 mx-1 mb-2">
                                <Input
                                    disabled={false}
                                    label='Vezetéknév'
                                    value={liveUserData.lastName}
                                    leftIcon=''
                                    rightIcon=''
                                    onChangeHandler={(e: any) => handleInputChange('lastName', e.target.value)}
                                    error=''
                                    type='text'
                                    placeHolder='' />
                            </div>
                            <div className="col-12 col-lg-10 mx-1">
                                <Input
                                    disabled={false}
                                    label='Keresztnév'
                                    value={liveUserData.firstName}
                                    leftIcon=''
                                    rightIcon=''
                                    onChangeHandler={(e: any) => handleInputChange('firstName', e.target.value)}
                                    error=''
                                    type='text'
                                    placeHolder='' />
                            </div>
                        </div>
                        <div className="heading_six login_question mb-2 mt-4">Hány éves vagy?</div>
                        <div className="col-12 col-lg-10 mb-2">
                            <DropInput
                                disabled={false}
                                leftIcon=''
                                label=''
                                type='radio'
                                onChangeHandler={(selectionArray: any) => handleInputChange('age', selectionArray[0])}
                                dropList={agesList}
                                initialSelection={[liveUserData.age]}
                            />
                        </div>
                        <div className="heading_six login_question mb-1 mt-4">Milyen nemű vagy?</div>
                        <div className="mb-2 col-12 justify-content-start">
                            {genderRadioButtons.map((genderButton, index) =>
                                <div
                                    onClick={(e: any) => handleGenderChange(index)}
                                    className='milyen_nem '
                                    key={`gender-${index}`}
                                >
                                    <RadioButton
                                        simple={false}
                                        checked={genderButton.checked}
                                        disabled={false}
                                        label={engHunGenderTranslator(genderButton.label)}
                                        rightIcon={genderButton.rightIcon}
                                    />
                                </div>
                            )}
                        </div>
                        <div className='milyen_nem_clear'></div>
                        <div className="heading_six login_question mb-2 mt-4">Melyik megyében laksz?</div>
                        <div className="col-12 col-lg-10 mb-2">
                            <DropInput
                                disabled={false}
                                leftIcon=''
                                label=''
                                type='radio'
                                onChangeHandler={(selectionArray: any) => handleInputChange('region', selectionArray[0])}
                                dropList={countiesList}
                                initialSelection={[liveUserData.region]}
                            />
                        </div>
                        <div className="heading_six login_question mb-2 mt-4">Mi a legmagasabb végzettséged?</div>
                        <div className="col-12 col-lg-10 mb-2">
                            {manualEducationSelection
                                ?
                                <Input
                                    disabled={false}
                                    label=''
                                    value={liveUserData.education}
                                    leftIcon=''
                                    rightIcon=''
                                    onChangeHandler={(e: any) => handleInputChange('education', e.target.value)}
                                    error=''
                                    type='text'
                                    placeHolder='' />
                                :
                                <DropInput
                                    disabled={false}
                                    leftIcon=''
                                    label=''
                                    type='radio'
                                    onChangeHandler={(selectionArray: any) => handleEducationDrop(selectionArray[0])}
                                    dropList={educationList}
                                    initialSelection={[liveUserData.education]}
                                />
                            }
                        </div>
                        <div className="heading_six login_question mb-2 mt-4">Jelenleg van munkahelyed?</div>
                        <div className='d-flex flex-row col-12 mb-2'>
                            <div
                                onClick={() => handleInputChange('jobPresent', true)}
                                className=" mb-2"
                            >
                                <RadioButton
                                    simple={false}
                                    checked={liveUserData.jobPresent}
                                    disabled={false}
                                    label='Van'
                                    rightIcon={null}
                                />
                            </div>
                            <div className="col-1"></div>
                            <div onClick={() => handleInputChange('jobPresent', false)}
                                className=" mb-2"
                            >
                                <RadioButton
                                    simple={false}
                                    checked={!liveUserData.jobPresent}
                                    disabled={false}
                                    label='Nincs'
                                    rightIcon={null}
                                />
                            </div>
                        </div>
                        <div className="heading_six login_question mb-2 mt-4">Korábban volt munkahelyed?</div>
                        <div className='d-flex flex-row col-12 mb-2'>
                            <div
                                onClick={() => handleInputChange('jobInPast', true)}
                                className="mb-2 mr-2"
                            >
                                <RadioButton
                                    simple={false}
                                    checked={liveUserData.jobInPast}
                                    disabled={false}
                                    label='Volt'
                                    rightIcon={null}
                                />
                            </div>
                            <div className="col-1"></div>
                            <div
                                onClick={() => handleInputChange('jobInPast', false)}
                                className="mb-2"
                            >
                                <RadioButton
                                    simple={false}
                                    checked={!liveUserData.jobInPast}
                                    disabled={false}
                                    label='Még nem volt'
                                    rightIcon={null}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='mt-5' onClick={() => handlePageChange()}>
                        <Button
                            type='primary'
                            disabled={!validatePage1()}
                            icon={null}
                            icon_position={null}
                            label='Tovább'
                        />
                    </div>
                </>
                :
                <>
                    {/* Page 2 */}
                    {pageNumber === 2 &&
                        <>
                            <div className='d-flex align-items-start flex-column'>
                                <div className='d-flex flex-row heading_one login_greetings_container mt-2'>
                                    <h1>Örülünk, hogy megismerhetünk! 😊</h1>
                                </div>
                                <div className='d-flex  login_forgot body_small flex-column flex-xl-row flex-md-column flex-lg-row'>
                                    <div className='d-flex flex-row login_stay'>
                                        <div >Már csak néhány adatot szeretnénk kérni.</div>
                                    </div>

                                </div>
                            </div>
                            <div className='heading_four  login_bejelentkezes'>
                                Regisztráció
                            </div>
                            <div className='col-12'>
                                <div className="heading_six login_question mb-2">Mi az email címed?</div>
                                <div className="d-flex flex-column mb-6">
                                    <div className="col-12 col-lg-8 mx-1 mb-2">
                                        <Input
                                            disabled={false}
                                            label=''
                                            value={liveUserData.email}
                                            leftIcon=''
                                            rightIcon=''
                                            onChangeHandler={(e: any) => handleInputChange('email', e.target.value)}
                                            error=''
                                            type='text'
                                            placeHolder='' />
                                    </div>
                                    <div className="heading_six login_question mb-2">Add meg a jelszavad</div>
                                    <div className="col-12 col-lg-8 mb-2">
                                        <Input
                                            disabled={false}
                                            label=''
                                            leftIcon=''
                                            rightIcon={'ejicon-eye'}
                                            value={liveUserData.password}
                                            onChangeHandler={(e: any) => handleInputChange('password', e.target.value)}
                                            error=''
                                            type={pswdInputType}
                                            placeHolder=''
                                            handleRightIconClick={toggleShowPassword}
                                        />
                                    </div>
                                    <div className='d-flex flex-row col-12 col-lg-8 body_tiny mb-4'>
                                        <div >A jelszónak 6-20 karaktert kell tartalmaznia, szám, nagybetű és kisbetű is legyen benne.</div>
                                    </div>
                                    <div className=' col-12 d-flex flex-row mb-2 container-border'>
                                        <div className='mx-2'>
                                            <CheckBox
                                                value={liveUserData.acceptTos ? 'checked' : 'default'}
                                                disabled={false}
                                                handleValueChange={() => setLiveUserData({ ...liveUserData, acceptTos: !liveUserData.acceptTos })}
                                            />
                                        </div>
                                        <div className="d-flex flex-row flex-wrap">
                                            <div className="mx-1">
                                                Elfogadom a
                                            </div>
                                            <div className="mx-1">
                                                <a className='alink_red' target='_blank' rel="noreferrer" href={aszf}><b>felhasználási feltételeket</b></a>
                                                {/*<LinkWithIcon
                                            icon=''
                                            label=' felhasználási feltételeket '
                                            linkTo='#'
                                            active={true}
                                        />*/}
                                            </div>
                                            <div className="mx-1">
                                                és az

                                            </div>
                                            <div className="">
                                                <a className='alink_red' target='_blank' rel="noreferrer" href={adatkezelesi}><b>adatkezelési irányelveket</b></a>
                                                {/* 
                                        <LinkWithIcon
                                            icon=''
                                            label=' adatkezelési irányelveket'
                                            linkTo='{adatkezelesi}'
                                            active={true}
                                    />*/}
                                            </div>
                                            .
                                        </div>
                                    </div>
                                    <div className='col-12 d-flex flex-row mb-2 container-border'>
                                        <div className='mx-2'>
                                            <CheckBox
                                                value={liveUserData.acceptEmails ? 'checked' : 'default'}
                                                disabled={false}
                                                handleValueChange={() => setLiveUserData({ ...liveUserData, acceptEmails: !liveUserData.acceptEmails })}
                                            />
                                        </div>
                                        <div className='col-12 '>
                                            Hozzájárulok, hogy a Coca-Cola Magyarország nem marketingcélú,
                                            kizárólag az oktatási anyagokkal, programmal kapcsolatos
                                            leveleket küldjön nekem.
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-5 '>
                                    <Button
                                        type='primary'
                                        disabled={!valdatePage2()}
                                        icon={null}
                                        icon_position={null}
                                        onClick={() => tryToRegister()}
                                        label='Regisztrálok'
                                    />
                                </div>
                            </div>
                        </>}
                    {pageNumber === 3 && <SuccessPage />}
                </>}
        </div>
    )
}





export default Registration
