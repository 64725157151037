import React from 'react'
import './news_detail.css'

import news_vector from "../../assets/img/news/Vector-3.svg"

import Tag from '../../core/tag/Tag'
import NewsCard from '../../components/news/Newscard'
import { Link, useParams } from 'react-router-dom'

import event1 from '../../assets/img/events/1.jpg'

import EventCard from '../../components/event/EventCard'
import { useHistory } from 'react-router-dom';
import dataProvider from '../../dataProvider'

import { FacebookShareButton } from "react-share";

function NewsDetail(props: any) {

    const { eventList, refreshEventList, textNews } = props

    const history = useHistory();
    const params: any = useParams()
    const slug = params.id;

    const [labelVagolap, setLabelVagolap] = React.useState('');
    const [mainNewsId, setMainNewsId] = React.useState(0);
    const [smallNews, setSmallNews] = React.useState<any[]>([]);

    let myLink = window.location.href;
    let myLinkEdin = `https://www.linkedin.com/sharing/share-offsite/?url=${myLink}`

    const toggleBookmarkEvent = (eventId: number, currentValue: boolean | undefined) => {
        !currentValue && dataProvider.updateSpecial('/events', eventId, 'registration', {}, (response: any) => {
            if (response?.status === 200) {
                setTimeout(() => {
                    refreshEventList()
                }, 100);
            }
        })
        currentValue && dataProvider.updateSpecial('/events', eventId, 'unregister', {}, (response: any) => {
            if (response?.status === 200) {
                setTimeout(() => {
                    refreshEventList()
                }, 100);
            }
        })
    }

    const copyMylink = () => {
        navigator.clipboard.writeText(myLink);
        setLabelVagolap("link másolva");
    }

    React.useEffect(() => {
        let mainId = 0;
        let recommendedList: any[] = [];
        textNews.forEach((newsDetails: any, index: number) => {
            if (slug === newsDetails.slug) {
                mainId = index
            } else if (recommendedList.length < 4) {
                recommendedList.push(newsDetails)
            }
        })
        setMainNewsId(mainId);
        setSmallNews(recommendedList);
    }, [textNews, slug])

    const myDetails = textNews[mainNewsId];


    const addZero = (string: string): string => {
        if (string.length === 1) return `0${string}`
        return string
    }

    const formatDateYYYYMMDD = (date: any): string => {
        const DATE = new Date(date);
        const YEAR = `${DATE.getFullYear()}`;
        const MONTH = addZero(`${DATE.getMonth() + 1}`);
        const DAY = addZero(`${DATE.getDate()}`);
        return `${YEAR}-${MONTH}-${DAY}`
    }


    return (
        <>
            <div className="news-detail-container mt-5">
                <h2 className="mb-3">{myDetails?.title || ''}</h2>
                <p className="mb-4 text-muted">
                    <i className="ejicon ejicon-calendar me-2"></i>
                    {formatDateYYYYMMDD(myDetails?.publishedAt)}</p>
            </div>
            <div className="new-full-width-container">
                <div className="news-detail-container">
                    <img src={myDetails?.cover?.sizes?.original?.url} alt="" className="w-100 mb-5" />
                </div>
                <img className="bg-vector" src={news_vector} alt="" />
            </div>
            <div className="news-detail-container">
                <div dangerouslySetInnerHTML={{ __html: myDetails?.content }}>
                </div>
                <h5 className="mt-5">
                    Ha érdekesnek találtad, oszd meg ismerőseiddel is!
                </h5>
                <div className='d-flex flex-row justify-content-left mt-3'>
                    <div className='me-3 share_button'>
                        <FacebookShareButton
                            url={myLink}
                            quote={"Mi vár rád az #énjövőm oldalán?"}
                            hashtag="#énjövőm"
                        >
                            <Tag color='red' label='' leftIcon={true} icon='ejicon-facebook' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                        </FacebookShareButton>
                    </div>
                    <div className='me-3 share_button'>
                        <a href={myLinkEdin} target='_blank'>
                            <Tag color='red' label='' leftIcon={true} icon='ejicon-linkedin' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                        </a>
                    </div>
                    <div className='me-3 share_button'>
                        <Link to='#' onClick={copyMylink} title="Hivatkozás másolása" >
                            <Tag color='red' label={labelVagolap} leftIcon={true} icon='ejicon-link-chain' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="container mt-5">
                <div className="d-flex flex-row align-items-end mt-5 mb-4 justify-content-between">
                    <h3 className="mb-0">További híreink</h3>
                    <Link to='/hirek' className="text-cc-primary"><b>Összes hír</b></Link>
                </div>
                <div className="row">
                    {smallNews.map((newsDetails: any) => <div className="col-12 col-lg-3">
                        <NewsCard
                            title={newsDetails?.title}
                            date='2021.10.02. Budapest'
                            description={newsDetails?.excerpt}
                            isCompact={false}
                            mainPicture={newsDetails?.cover?.sizes?.original?.url}
                            handleParentClick={(e: Event) => history.push(`/hirek/${newsDetails?.slug}`)} />
                    </div>
                    )}
                </div>
            </div>
            <div className="container mt-5">
                <div className="d-flex flex-row align-items-end mt-5 mb-4 justify-content-between">
                    <h3 className="mb-0">Gyere el workshopjainkra is!</h3>
                    <Link to='/esemenyek' className="text-cc-primary"><b>Összes esemény</b></Link>
                </div>
                <div className="row">
                    {eventList.map((eventDetails: any, index: number) =>
                        index < 4 &&
                        <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12">
                            <EventCard
                                foundation={undefined}
                                title={eventDetails.title}
                                tags={eventDetails?.tags?.map((tagDetails: any) =>
                                ({
                                    color: 'orange',
                                    label: tagDetails.name,
                                    leftIcon: false,
                                    icon: '',
                                    rightIcon: false,
                                    removeTag: { function() { } },
                                })
                                )}
                                description={eventDetails.description}
                                mainPicture={eventDetails.cover.sizes.original.url || event1}
                                handleParentClick={() => toggleBookmarkEvent(eventDetails.id, eventDetails.registered)}
                                schedule={eventDetails.date.split(' ')[0]}
                                presentors={[eventDetails.lecturers]}
                                attendants={new Array(eventDetails.registeredUsersCount)}
                                placement={eventDetails.online ? "online" : eventDetails.city || ''}
                                registered={eventDetails.registered}
                                availableSeats={eventDetails.availableSeats}
                                id={eventDetails.id}
                            />
                        </div>
                    )}
                </div>
            </div>
        </>
    )
}

export default NewsDetail