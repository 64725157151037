import React from 'react';
import './landing.css';

import news_vector from "../../assets/img/news/Vector-3.svg";

import Tag from '../../core/tag/Tag';
import { Link, useParams } from 'react-router-dom';

import dataProvider from '../../dataProvider';

import { FacebookShareButton } from "react-share";
import NotFound from '../notfound/NotFound';
import { Helmet } from 'react-helmet';

function Landing(props: any) {
    const params: any = useParams()
    const slug: any = params.slug;

    const [labelVagolap, setLabelVagolap] = React.useState('');
    const [myDetails, setMyDetails] = React.useState<any>(undefined);

    let myLink = window.location.href;
    let myLinkEdin = `https://www.linkedin.com/sharing/share-offsite/?url=${myLink}`

    const copyMylink = () => {
        navigator.clipboard.writeText(myLink);
        setLabelVagolap("link másolva");
    }

    React.useEffect(() => {
        dataProvider.getMany(`/landings/${slug}`, (resp: any) => {
            if (resp.error || resp.status !== 200) {
                setMyDetails(null);
                return;
            }

            setMyDetails(resp.data.data);
        });
    }, [slug]);

    const addZero = (string: string): string => {
        if (string.length === 1) return `0${string}`;
        return string;
    }

    const formatDateYYYYMMDD = (date: any): string => {
        const DATE = new Date(date);
        const YEAR = `${DATE.getFullYear()}`;
        const MONTH = addZero(`${DATE.getMonth() + 1}`);
        const DAY = addZero(`${DATE.getDate()}`);
        return `${YEAR}-${MONTH}-${DAY}`;
    }

    return (
        myDetails === null ? <NotFound/> :
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{myDetails?.title || ''}</title>
                <meta name="description" content="Hírek a Coca-Cola Magyarország fiatalok munkaerőpiaci elhelyezkedését támogató #énjövőm programjának mindennapjaiból. " />
            </Helmet>
            <div className="news-detail-container mt-5">
                <h2 className="mb-3">{myDetails?.title || ''}</h2>
                <p className="mb-4 text-muted">
                    <i className="ejicon ejicon-calendar me-2"></i>
                    {formatDateYYYYMMDD(myDetails?.publishedAt)}</p>
            </div>
            <div className="new-full-width-container">
                <div className="news-detail-container">
                    <img src={myDetails?.cover?.sizes?.original?.url} alt="" className="w-100 mb-5" />
                </div>
                <img className="bg-vector" src={news_vector} alt="" />
            </div>
            <div className="news-detail-container">
                <div dangerouslySetInnerHTML={{ __html: myDetails?.content }}>
                </div>
                <h5 className="mt-5">
                    Ha érdekesnek találtad, oszd meg ismerőseiddel is!
                </h5>
                <div className='d-flex flex-row justify-content-left mt-3'>
                    <div className='me-3 share_button' title="Facebook #énjövőm">
                        <FacebookShareButton
                            url={myLink}
                            quote={"Mi vár rád az #énjövőm oldalán?"}
                            hashtag="#énjövőm"
                        >
                            <Tag color='red' label='' leftIcon={true} icon='ejicon-facebook' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                        </FacebookShareButton>
                    </div>
                    <div className='me-3 share_button' title="Linkedin #énjövőm">
                        <a href={myLinkEdin} target='_blank'>
                            <Tag color='red' label='' leftIcon={true} icon='ejicon-linkedin' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                        </a>
                    </div>
                    <div className='me-3 share_button'>
                        <Link to='#' onClick={copyMylink} title="Hivatkozás másolása" >
                            <Tag color='red' label={labelVagolap} leftIcon={true} icon='ejicon-link-chain' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                        </Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Landing