import React from 'react'

import 'bootstrap/dist/css/bootstrap.min.css'
import '../../style.scss'

import { Link } from 'react-router-dom'
import Button from '../../core/button/Button'
import TabList from '../../core/tab/TabList'
import { Carousel, Container } from 'react-bootstrap'
import Tag from '../../core/tag/Tag'
import sampleImg from '../../assets/img/modul_sample_bg.png'
import ModulCard from '../../components/modul/ModulCard'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'

import './home.scss'
import './home.css'

import workshop from '../../assets/img/landing/1.jpg'
import kikvagyunkmi from '../../assets/img/landing/kik-vagyunk-mi.jpg'
import fejleszdmagad from '../../assets/img/landing/fejleszd.png'
import authProvider from '../../authProvider'
import dataProvider from '../../dataProvider'
import PodcastPlayer from '../../components/podcast-player/PodcastPlayer'

import { ReactComponent as LeftArrow } from '../../assets/icons/arrow-left-solid.svg'
import { ReactComponent as RightArrow } from '../../assets/icons/arrow-right-solid.svg'
import { CarouselRef } from 'react-bootstrap/esm/Carousel'

function Home(props: any) {
	const { courseList, refreshCourseList } = props
	const history = useHistory()

	const tabList: Array<any> = [
		{
			label: 'összes',
			onClickHandler: () => {
				setFilter(' ')
				setCourseListIndex(0)
			},
		},
		{
			label: 'önismeret és önfejlesztés',
			onClickHandler: () => {
				setFilter('önismeret és önfejlesztés')
				setCourseListIndex(0)
			},
		},
		{
			label: 'kommunikáció',
			onClickHandler: () => {
				setFilter('kommunikáció')
				setCourseListIndex(0)
			},
		},
		{
			label: 'projektmenedzsment',
			onClickHandler: () => {
				setFilter('projektmenedzsment')
				setCourseListIndex(0)
			},
		},
		{
			label: 'vállalkozói készségek',
			onClickHandler: () => {
				setFilter('vállalkozói készségek')
				setCourseListIndex(0)
			},
		},
		{
			label: 'üzleti készségek',
			onClickHandler: () => {
				setFilter('üzleti készségek')
				setCourseListIndex(0)
			},
		},
		{
			label: 'vezetői készségek',
			onClickHandler: () => {
				setFilter('vezetői készségek')
				setCourseListIndex(0)
			},
		},
		{
			label: 'kapcsolatépítés',
			onClickHandler: () => {
				setFilter('kapcsolatépítés')
				setCourseListIndex(0)
			},
		},
	]

	const handleCourseListSelect = (selectedIndex: number, e: any) => {
		setCourseListIndex(selectedIndex)
	}

	const handleCourseSelect = (courseId: number) => {
		history.push(`/tananyagok/${courseId}`)
	}
	const [windowWidth, setWidowWidth] = React.useState(window.innerWidth)
	const [filteredCourseList, setFilteredCourseList] = React.useState(
		courseList.map((course: any) => course),
	)
	const [filter, setFilter] = React.useState(' ')
	const [courseListIndex, setCourseListIndex] = React.useState(0)
	const [contentFromApi, setContentFromApi] = React.useState<any[]>([])

	React.useEffect(() => {
		dataProvider.getMany('/pages/fooldal', (response: any) => {
			console.log('landing')
			console.log(response?.data?.data)
			if (response?.status === 200) {
				setContentFromApi(response?.data?.data?.content)
			}
		})
		window.addEventListener('resize', (e: any) =>
			setWidowWidth(window.innerWidth),
		)
		return window.removeEventListener('resize', (e: any) =>
			setWidowWidth(window.innerWidth),
		)
	}, [])

	React.useEffect(() => {
		let newCourseList: any[] = []
		courseList.forEach(
			(courseDetails: any) =>
				JSON.stringify(courseDetails).includes(filter || ' ') &&
				newCourseList.push(courseDetails),
		)
		setFilteredCourseList(newCourseList)
	}, [filter, courseList])

	const carouselItemsNumber =
		windowWidth < 992 ? 1 : windowWidth < 1200 ? 2 : 3

	const carouselItemColNumber = (): string => {
		switch (carouselItemsNumber) {
			case 1:
				return '11'
			case 2:
				return '5'
			default:
				return '4'
		}
	}

	const carouselRefs: Array<any> = [
		React.createRef<CarouselRef>(),
		React.createRef<CarouselRef>(),
		React.createRef<CarouselRef>(),
		React.createRef<CarouselRef>(),
	]
	const carouselPrev = (index: number) => {
		carouselRefs[index].current.prev()
	}
	const carouselNext = (index: number) => {
		carouselRefs[index].current.next()
	}

	const customLeftArrow = () => {
		return (
			<LeftArrow
				className="svg-arrow"
				style={{ width: '16px', height: '16px', fill: 'var(--red)' }}
			/>
		)
	}
	const customRightArrow = () => {
		return (
			<RightArrow
				className="svg-arrow"
				style={{ width: '16px', height: '16px', fill: 'var(--red)' }}
			/>
		)
	}

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<title>enjovom.hu - Benned van a lehetőség!</title>
				<meta
					name="description"
					content="Tanulj díjmentesen bárhol, bármikor,  és találd meg a helyed a munkaerőpiacon. Böngészd tananyagainkat, vegyél részt workshopjainkon, vagy fordulj személyesen mentorainkhoz."
				/>
			</Helmet>

			{/* Home szekcio 1*/}
			<div className="cc-content landing mb-5">
				<div className="cc-section order-0 back-section left-top landing-benned-van-nagy-kep">
					<img
						className="nagykep"
						alt={contentFromApi[0]?.alt}
						src={
							contentFromApi[0]?.image?.sizes?.original?.url ||
							workshop
						}
						style={{ marginTop: '-112px' }}
					/>
				</div>
				<div className="cc-section section-first d-xl-flex flex-column justify-content-top landing-regisztralj mb-0 mb-lg-5">
					<div className="coca-container mb-2 mb-lg-5">
						<div className="row section-title">
							<div className="col-7">
								<h1
									className="home_h1_title"
									dangerouslySetInnerHTML={{
										__html: contentFromApi[0]?.title || '',
									}}
								></h1>
							</div>
						</div>
						<div className="row section-text mt-2 mt-leg-5">
							<div className="col col-4">
								<div
									dangerouslySetInnerHTML={{
										__html:
											contentFromApi[0]?.content || '',
									}}
								/>
								<p className="mt-5 mb-5">
									{!authProvider.checkAuth() && (
										<Link to="/regisztracio">
											<Button
												type="primary"
												disabled={false}
												icon={null}
												label="Regisztrálok"
											/>
										</Link>
									)}
								</p>
							</div>
							<div className="col col-6">
								<div className="video">
									<div className="video-thumbnail">
										<iframe
											className="home_iframe_style"
											style={{}}
											src={`https://www.youtube.com/embed/${contentFromApi[0]?.videoId}?hl=hu`}
											title="YouTube video player"
											allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
											allowFullScreen
										></iframe>
									</div>
								</div>
							</div>
							<div className="col col-1"></div>
						</div>
					</div>
				</div>

				{/* END: Home szekcio 1*/}

				{/* Home szekcio 2: statisztika minta adattal*/}
				{/* React-bootsrtrap Carousel*/}
				<div className="row home_stats justify-content-md-center ">
					<div className="col-12 d-block d-lg-none">
						<Carousel
							ref={carouselRefs[0]}
							prevIcon={customLeftArrow()}
							nextIcon={customRightArrow()}
						>
							{(contentFromApi[1]?.icons || []).map(
								(counterDetails: any, index: number) =>
									index % 2 === 0 && (
										<Carousel.Item
											className="home_carousel_item justify-content-center"
											key={`carousel-${index}`}
										>
											<div className="row justify-content-center">
												<div
													className={`col-${
														contentFromApi[1]
															?.icons[index + 1]
															? '6'
															: '12'
													}`}
												>
													<div className="home_stat_element">
														<div className="home_stat_element_icon">
															<Tag
																color="lightred"
																label=""
																leftIcon={true}
																icon={`ejicon-${counterDetails?.icon}`}
																rightIcon={
																	false
																}
																removeTag={(
																	e: any,
																) => {
																	console.log(
																		e,
																	)
																}}
															/>
														</div>
														<div className="home_stat_element_no heading_two">
															<h2>
																{
																	counterDetails.number
																}
															</h2>
														</div>
														<div className="home_stat_element_desc body_small">
															{
																counterDetails.description
															}
														</div>
													</div>
												</div>
												{contentFromApi[1]?.icons[
													index + 1
												] && (
													<div className="col-6">
														<div className="home_stat_element">
															<div className="home_stat_element_icon">
																<Tag
																	color="lightred"
																	label=""
																	leftIcon={
																		true
																	}
																	icon={`ejicon-${
																		contentFromApi[1]
																			?.icons[
																			index +
																				1
																		]?.icon
																	}`}
																	rightIcon={
																		false
																	}
																	removeTag={(
																		e: any,
																	) => {
																		console.log(
																			e,
																		)
																	}}
																/>
															</div>
															<div className="home_stat_element_no heading_two">
																<h2>
																	{
																		contentFromApi[1]
																			?.icons[
																			index +
																				1
																		]
																			?.number
																	}
																</h2>
															</div>
															<div className="home_stat_element_desc body_small">
																{
																	contentFromApi[1]
																		?.icons[
																		index +
																			1
																	]
																		?.description
																}
															</div>
														</div>
													</div>
												)}
											</div>
										</Carousel.Item>
									),
							)}
						</Carousel>
					</div>
					{(contentFromApi[1]?.icons || []).map(
						(counterDetails: any, index: number) => (
							<div
								key={index}
								className="col-6 col-md-2 d-none d-lg-block"
							>
								<div
									className={`home_stat_element ${
										index === 0 && 'mr-auto'
									}`}
								>
									<div className="home_stat_element_icon">
										<Tag
											color="lightred"
											label=""
											leftIcon={true}
											icon={`ejicon-${counterDetails?.icon}`}
											rightIcon={false}
											removeTag={(e: any) => {
												console.log(e)
											}}
										/>
									</div>
									<div className="home_stat_element_no heading_two">
										<h2>{counterDetails.number}</h2>
									</div>
									<div className="home_stat_element_desc body_small">
										{counterDetails.description}
									</div>
								</div>
							</div>
						),
					)}
				</div>
				{/* END: Home szekcio 2: statisztika minta adattal*/}

				{/* Home szekcio 3: Modul kártyák minta adattal*/}
				{/* React-bootsrtrap Carousel*/}
				<Container className="home_tanulhatsz">
					<div className="home_tanulhatsz_title mt-5 mt-lg-3 mb-2 mb-lg-3 text-center">
						<h3>Nézd meg, miről tanulhatsz nálunk!</h3>
					</div>
					<div className="home_tanulhatsz_tab justify-content-start justify-content-lg-center">
						<TabList tabList={tabList} />
					</div>

					<div className="col-12 d-block">
						<Carousel
							ref={carouselRefs[1]}
							activeIndex={courseListIndex}
							onSelect={handleCourseListSelect}
							prevIcon={customLeftArrow()}
							nextIcon={customRightArrow()}
						>
							{filteredCourseList.map(
								(courseDetails: any, index: number) =>
									carouselItemsNumber === 1 ? (
										<Carousel.Item
											className="home_carousel_item justify-content-center"
											key={`coruosel-${index}`}
										>
											<div className="row justify-content-center">
												<div className="col-11 justify-content-center">
													<ModulCard
														title={
															courseDetails.title
														}
														description={
															courseDetails.shortDescription
														}
														rating={
															courseDetails.rating
														}
														nrOfRatings={
															courseDetails.reviewsCount
														}
														nrOfStudents={
															courseDetails.students
														}
														tags={courseDetails.tags.map(
															(
																tagDetails: any,
															) => ({
																label: tagDetails.name,
																color: 'orange',
															}),
														)}
														percentage={0}
														mainPicture={
															courseDetails.cover
																.sizes.course
																.url ||
															sampleImg
														}
														handleParentClick={() =>
															handleCourseSelect(
																courseDetails.slug,
															)
														}
														id={courseDetails.id}
														bookmarked={
															courseDetails.bookmarked
														}
													/>
												</div>
											</div>
										</Carousel.Item>
									) : carouselItemsNumber === 2 &&
									  index % 2 === 0 ? (
										<Carousel.Item
											className="home_carousel_item justify-content-center"
											key={`coruosel-${index}`}
										>
											<WrapMoreModuleCards
												elements={[
													filteredCourseList[index],
													filteredCourseList[
														index + 1
													] || null,
												]}
												handleCourseSelect={
													handleCourseSelect
												}
												carouselItemColNumber={carouselItemColNumber()}
												refreshCourseList={
													refreshCourseList
												}
											/>
										</Carousel.Item>
									) : (
										carouselItemsNumber === 3 &&
										index % 3 === 0 && (
											<Carousel.Item
												className="home_carousel_item justify-content-center"
												key={`coruosel-${index}`}
											>
												<WrapMoreModuleCards
													elements={[
														filteredCourseList[
															index
														],
														filteredCourseList[
															index + 1
														] || null,
														filteredCourseList[
															index + 2
														] || null,
													]}
													handleCourseSelect={
														handleCourseSelect
													}
													carouselItemColNumber={carouselItemColNumber()}
												/>
											</Carousel.Item>
										)
									),
							)}
						</Carousel>
					</div>

					<div className="home_tanulhatsz_all heading_six mb-5 justify-content-center">
						<Link to="/tananyagok">
							<Button
								type="primary"
								disabled={false}
								icon={null}
								label="Megnézem az összeset"
							/>
						</Link>
					</div>
				</Container>
				{/* END: Home szekcio 3: Modul kártyák minta adattal*/}

				{/* Home szekcio 4: Kik vagyunk*/}

				<div className="cc-section landing-kik-vagyunk-mi mt-5">
					<div className="coca-container">
						<div className="kik-vagyunk row gx-0">
							<div className="col col-xl-8 kik-vagyunk-kep">
								<img
									alt={contentFromApi[2]?.alt || ''}
									src={
										contentFromApi[2]?.image?.sizes
											?.original?.url || kikvagyunkmi
									}
								/>
							</div>
							<div className="col col-xl-4 row kik-vagyunk-card">
								<div className="bg-white card-float">
									<h2>{contentFromApi[2]?.title || ''}</h2>
									<div
										dangerouslySetInnerHTML={{
											__html:
												contentFromApi[2]?.content ||
												'',
										}}
									/>
									<p>
										<Link
											to={
												contentFromApi[2]?.buttonLink ||
												'/rolunk'
											}
											className="btn bg-red cl-white registration"
										>
											{contentFromApi[2]?.buttonLabel ||
												''}
										</Link>
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>
				{/* END: Home szekcio 4: Kik vagyunk*/}

				{/* Home szekcio 4: Idézetek minta adattal */}
				{/* React-bootsrtrap Carousel*/}
				{/* Bootstrap md (más sorrend és megjelenés mobil nézetben)*/}

				<div className="home_quotes_main mt-3 order-xl-4 order-lg-5 order-sm-5 mb-2 mb-md-5">
					<div className="col-12 justify-content-center text-center mb-4 rolunk_mondtak_title">
						<h2>{contentFromApi[3]?.title || ''}</h2>
					</div>
					<div className="col-12 d-block d-md-none">
						<Carousel
							ref={carouselRefs[2]}
							prevIcon={customLeftArrow()}
							nextIcon={customRightArrow()}
						>
							{(contentFromApi[3]?.testimonials || []).map(
								(testimonialDetails: any, index: number) => (
									<Carousel.Item
										className="home_carousel_item justify-content-center"
										key={`coruosel-${index}`}
									>
										<div className="row justify-content-center">
											<div className="col-12 justify-content-center">
												<div className="cc-quote">
													<div className="quotes-text">
														{testimonialDetails?.content ||
															''}
													</div>
													<div className="row quotes-footer">
														<div className="col quotes-footer-mentor">
															<h6 className="mentor-name">
																{testimonialDetails?.name ||
																	''}
															</h6>
														</div>
														<div className="col mentor-profile-pic">
															<div className="mentor-profile-pic-wrapper ratio ratio-1x1">
																<img
																	alt={
																		testimonialDetails?.alt ||
																		''
																	}
																	src={
																		testimonialDetails
																			?.image
																			?.sizes
																			?.original
																			?.url
																	}
																/>
															</div>
														</div>
														<p className="course-name">
															{testimonialDetails?.position ||
																''}
														</p>
													</div>
												</div>
											</div>
										</div>
									</Carousel.Item>
								),
							)}
						</Carousel>
					</div>

					<div className="col-12 d-none d-md-block d-xl-none">
						<Carousel
							ref={carouselRefs[3]}
							prevIcon={customLeftArrow()}
							nextIcon={customRightArrow()}
						>
							{(contentFromApi[3]?.testimonials || []).map(
								(testimonialDetails: any, index: number) =>
									index % 2 === 0 && (
										<Carousel.Item
											className="home_carousel_item justify-content-center"
											key={`coruosel-${index}`}
										>
											<div className="row justify-content-center quotes_equal">
												<div className="col-6 justify-content-center">
													<div className="cc-quote">
														<div className="quotes-text">
															{testimonialDetails?.content ||
																''}
														</div>
														<div className="row quotes-footer">
															<div className="col quotes-footer-mentor">
																<h6 className="mentor-name">
																	{testimonialDetails?.name ||
																		''}
																</h6>
															</div>
															<div className="col mentor-profile-pic">
																<div className="mentor-profile-pic-wrapper ratio ratio-1x1">
																	<img
																		alt={
																			testimonialDetails?.alt ||
																			''
																		}
																		src={
																			testimonialDetails
																				?.image
																				?.sizes
																				?.original
																				?.url
																		}
																	/>
																</div>
															</div>
															<p className="course-name">
																{testimonialDetails?.position ||
																	''}
															</p>
														</div>
													</div>
												</div>
												{contentFromApi[3]
													?.testimonials[
													index + 1
												] && (
													<div className="col-6 justify-content-center">
														<div className="cc-quote">
															<div className="quotes-text">
																{contentFromApi[3]
																	?.testimonials[
																	index + 1
																]?.content ||
																	''}
															</div>
															<div className="row quotes-footer">
																<div className="col quotes-footer-mentor">
																	<h6 className="mentor-name">
																		{contentFromApi[3]
																			?.testimonials[
																			index +
																				1
																		]
																			?.name ||
																			''}
																	</h6>
																</div>
																<div className="col mentor-profile-pic">
																	<div className="mentor-profile-pic-wrapper ratio ratio-1x1">
																		<img
																			alt={
																				contentFromApi[3]
																					?.testimonials[
																					index +
																						1
																				]
																					?.alt ||
																				''
																			}
																			src={
																				contentFromApi[3]
																					?.testimonials[
																					index +
																						1
																				]
																					?.image
																					?.sizes
																					?.original
																					?.url
																			}
																		/>
																	</div>
																</div>
																<p className="course-name">
																	{contentFromApi[3]
																		?.testimonials[
																		index +
																			1
																	]
																		?.position ||
																		''}
																</p>
															</div>
														</div>
													</div>
												)}
											</div>
										</Carousel.Item>
									),
							)}
						</Carousel>
					</div>
					<div className="col-12 d-none d-xl-block">
						<Carousel
							prevIcon={customLeftArrow()}
							nextIcon={customRightArrow()}
						>
							{(contentFromApi[3]?.testimonials || []).map(
								(testimonialDetails: any, index: number) =>
									index % 3 === 0 && (
										<Carousel.Item
											className="home_carousel_item justify-content-center"
											key={`coruosel-${index}`}
										>
											<div className="row justify-content-center ">
												<div className="col-4 justify-content-center">
													<div className="cc-quote">
														<div className="quotes-text">
															{testimonialDetails?.content ||
																''}
														</div>
														<div className="row quotes-footer">
															<div className="col quotes-footer-mentor">
																<h6 className="mentor-name">
																	{testimonialDetails?.name ||
																		''}
																</h6>
															</div>
															<div className="col mentor-profile-pic">
																<div className="mentor-profile-pic-wrapper ratio ratio-1x1">
																	<img
																		alt={
																			testimonialDetails?.alt ||
																			''
																		}
																		src={
																			testimonialDetails
																				?.image
																				?.sizes
																				?.original
																				?.url
																		}
																	/>
																</div>
															</div>
															<p className="course-name">
																{testimonialDetails?.position ||
																	''}
															</p>
														</div>
													</div>
												</div>
												{contentFromApi[3]
													?.testimonials[
													index + 1
												] && (
													<div className="col-4 justify-content-center">
														<div className="cc-quote">
															<div className="quotes-text">
																{contentFromApi[3]
																	?.testimonials[
																	index + 1
																]?.content ||
																	''}
															</div>
															<div className="row quotes-footer">
																<div className="col quotes-footer-mentor">
																	<h6 className="mentor-name">
																		{contentFromApi[3]
																			?.testimonials[
																			index +
																				1
																		]
																			?.name ||
																			''}
																	</h6>
																</div>
																<div className="col mentor-profile-pic">
																	<div className="mentor-profile-pic-wrapper ratio ratio-1x1">
																		<img
																			alt={
																				contentFromApi[3]
																					?.testimonials[
																					index +
																						1
																				]
																					?.alt ||
																				''
																			}
																			src={
																				contentFromApi[3]
																					?.testimonials[
																					index +
																						1
																				]
																					?.image
																					?.sizes
																					?.original
																					?.url
																			}
																		/>
																	</div>
																</div>
																<p className="course-name">
																	{contentFromApi[3]
																		?.testimonials[
																		index +
																			1
																	]
																		?.position ||
																		''}
																</p>
															</div>
														</div>
													</div>
												)}
												{contentFromApi[3]
													?.testimonials[
													index + 2
												] && (
													<div className="col-4 justify-content-center">
														<div className="cc-quote">
															<div className="quotes-text">
																{contentFromApi[3]
																	?.testimonials[
																	index + 2
																]?.content ||
																	''}
															</div>
															<div className="row quotes-footer">
																<div className="col quotes-footer-mentor">
																	<h6 className="mentor-name">
																		{contentFromApi[3]
																			?.testimonials[
																			index +
																				2
																		]
																			?.name ||
																			''}
																	</h6>
																</div>
																<div className="col mentor-profile-pic">
																	<div className="mentor-profile-pic-wrapper ratio ratio-1x1">
																		<img
																			alt={
																				contentFromApi[3]
																					?.testimonials[
																					index +
																						2
																				]
																					?.alt ||
																				''
																			}
																			src={
																				contentFromApi[3]
																					?.testimonials[
																					index +
																						2
																				]
																					?.image
																					?.sizes
																					?.original
																					?.url
																			}
																		/>
																	</div>
																</div>
																<p className="course-name">
																	{contentFromApi[3]
																		?.testimonials[
																		index +
																			2
																	]
																		?.position ||
																		''}
																</p>
															</div>
														</div>
													</div>
												)}
											</div>
										</Carousel.Item>
									),
							)}
						</Carousel>
					</div>
				</div>

				{/* END: Home szekcio 4: Idézetek minta adattal*/}

				{/* Home szekcio 5: podcast*/}
				<div className="cc-section order-xl-5 order-lg-4 order-sm-4 landing-podcast">
					<div className="img-cnt">
						<div className="coca-container row float-cnt justify-content-center">
							<div className="col col-12 col-lg-6 wrap">
								<h3 className="cl-white mb-3">
									{contentFromApi[4]?.title || ''}
								</h3>
								<PodcastPlayer />
								<p>
									<a
										href="https://www.buzzsprout.com/1721022"
										target="_blank"
										rel="noreferrer"
										className="btn bg-red cl-white registration"
									>
										Összes epizód
									</a>
								</p>
							</div>
						</div>
						<img
							alt={contentFromApi[4]?.alt || ''}
							src={
								contentFromApi[4]?.image?.sizes?.original
									?.url ||
								'src/assets/images/pics/landing/podcast.png'
							}
						/>
					</div>
				</div>

				{/* END: Home szekcio 5: podcast*/}

				{/* Home szekcio 6: Idézetek minta adattal */}
				{/* React-bootsrtrap Carousel*/}
				{/* Bootstrap xs (más sorrend és megjelenés mobil/desktop nézetben)*/}

				{/* END: Home szekcio 6: Idézetek minta adattal*/}
				{/* END: Home szekcio 6: Mobil sorrend és carousel*/}

				{/* Home szekcio 7: Fejleszt magad */}
				<div className="cc-section order-6 order-sm-6 order-tb-6 landing-fejleszd-magad">
					<div className="coca-container mt-5">
						<div className="row">
							<div className="col col-xl-6 text-start my-auto">
								<h1 className="mb-2 mb-lg-5 landing_fejleszd_title">
									{contentFromApi[5]?.title || ''}
								</h1>
								<div
									dangerouslySetInnerHTML={{
										__html:
											contentFromApi[5]?.content || '',
									}}
								/>
							</div>
							<div className="col col-xl-6">
								<img
									alt={contentFromApi[5]?.alt || ''}
									src={
										contentFromApi[5]?.image?.sizes
											?.original?.url || fejleszdmagad
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

const WrapMoreModuleCards = (props: any) => {
	const {
		elements,
		handleCourseSelect,
		carouselItemColNumber,
		refreshCourseList,
	} = props

	return (
		<>
			<div className="row justify-content-center">
				{elements.length === 2 ? <div className="col-1"></div> : null}
				{elements.map((courseDetails: any, index: number) => (
					<div
						className={`col-${carouselItemColNumber}`}
						key={`course-${index}`}
					>
						{courseDetails ? (
							<ModulCard
								title={courseDetails.title}
								description={courseDetails.shortDescription}
								rating={courseDetails.rating}
								nrOfRatings={courseDetails.reviewsCount}
								nrOfStudents={courseDetails.students}
								tags={courseDetails.tags.map(
									(tagDetails: any) => ({
										label: tagDetails.name,
										color: 'orange',
									}),
								)}
								percentage={0}
								mainPicture={
									courseDetails.cover.sizes.course.url ||
									sampleImg
								}
								handleParentClick={() =>
									handleCourseSelect(courseDetails.slug)
								}
								id={courseDetails.id}
								bookmarked={courseDetails.bookmarked}
								refresher={refreshCourseList}
							/>
						) : null}
					</div>
				))}
				{elements.length === 2 ? <div className="col-1"></div> : null}
			</div>
		</>
	)
}

export default Home
