import React from 'react'
import './news.css'
import newsRadio from "../../assets/img/news/news_radio.png"
import newsRadioMobile from "../../assets/img/news/news_radio_mobile.png"
import news_vector from "../../assets/img/news/Vector-3.svg"

import NewsCard from '../../components/news/Newscard';
import EventCard from '../../components/event/EventCard'
import { Helmet } from "react-helmet";
import event1 from '../../assets/img/events/1.jpg'

import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom';
import hir3 from '../../assets/img/news/hír - 2021.10.02.jpg'
import KepzesSelye from '../../assets/img/news/enjovom_selye.jpg'
import dataProvider from '../../dataProvider'

function News(props: any) {

    const history = useHistory();

    const { eventList, refreshEventList, textNews, videoNews } = props

    const [featuredVideoIndex, setFeaturedVideoIndex] = React.useState(0);
    const [smallVideoNews, setSmallVideoNews] = React.useState<any[]>([]);

    React.useEffect(() => {
        let featuredVideoIndex = 0;
        let videoSelection: any[] = []
        videoNews.forEach((videoDetails: any, index: number) => {
            if (videoDetails.featured) {
                featuredVideoIndex = index
            }
        })
        videoNews.forEach((videoDetails: any, index: number) => {
            if (!videoDetails.featured && index !== featuredVideoIndex && videoSelection.length < 4) {
                videoSelection.push(videoDetails)
            }
        })
        setFeaturedVideoIndex(featuredVideoIndex);
        setSmallVideoNews(videoSelection || []);
    }, [videoNews])

    const toggleBookmarkEvent = (eventId: number, currentValue: boolean | undefined) => {
        !currentValue && dataProvider.updateSpecial('/events', eventId, 'registration', {}, (response: any) => {
            if (response?.status === 200) {
                setTimeout(() => {
                    refreshEventList()
                }, 100);
            }
        })
        currentValue && dataProvider.updateSpecial('/events', eventId, 'unregister', {}, (response: any) => {
            if (response?.status === 200) {
                setTimeout(() => {
                    refreshEventList()
                }, 100);
            }
        })
    }

    const addZero = (string: string): string => {
        if (string.length === 1) return `0${string}`
        return string
    }

    const formatDateYYYYMMDD = (date: any): string => {
        const DATE = new Date(date);
        const YEAR = `${DATE.getFullYear()}`;
        const MONTH = addZero(`${DATE.getMonth() + 1}`);
        const DAY = addZero(`${DATE.getDate()}`);
        return `${YEAR}-${MONTH}-${DAY}`
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Hírek - enjovom.hu</title>
                <meta name="description" content="Hírek a Coca-Cola Magyarország fiatalok munkaerőpiaci elhelyezkedését támogató #énjövőm programjának mindennapjaiból. " />
            </Helmet>
            <div className="container mt-5">
                <h2 className="mb-4">Hírek</h2>
                <div className="row mb-4">
                    <div className="col-12 col-lg-8">
                        <div className="row">
                            {/* nem volt megadva 4 a limit ? */}
                            {
                                textNews.map((newsDetails: any, index: number) =>
                                    /* index < 4 && */
                                    <div className="col-12 col-lg-6">
                                        <NewsCard
                                            title={newsDetails.title}
                                            date={`${formatDateYYYYMMDD(newsDetails.publishedAt)} ${newsDetails.location ? newsDetails.location : ''}`}
                                            description={newsDetails.excerpt}
                                            isCompact={false}
                                            mainPicture={newsDetails?.cover?.sizes?.original?.url || hir3}
                                            handleParentClick={(e: Event) => history.push(`/hirek/${newsDetails.slug}`)}
                                            type='text'
                                        />
                                    </div>
                                )}
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <h3 className="mb-4">#énjövőm podcast</h3>
                        <div className="new-large-radio-container">
                            <img className="d-none d-lg-block w-100 mb-0" src={newsRadio} alt="#énjövőm podcast" />
                            <img className="d-block d-lg-none w-100 mb-0" src={newsRadioMobile} alt="#énjövőm podcast" />
                            <div className="content-div">
                                <h2 className="mb-4"> Ismerd meg munkánkat és partnereinket!<br></br>Hallgasd rendszeresen havonta jelentkező podcastunkat!</h2>
                                <a href='https://www.buzzsprout.com/1721022' target='_blank' rel="noreferrer" className="btn bg-red cl-white">Összes epizód</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="new-full-width-container">
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-12 col-lg-8">
                            <div className='ratio ratio-16x9'>
                                <iframe
                                    className="iframe_style_2 w-100 h-100"
                                    src={`https://www.youtube.com/embed/${videoNews[featuredVideoIndex]?.videoId}?hl=hu`}
                                    title={videoNews[featuredVideoIndex]?.title}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen />
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <p className="text-muted mt-4">{formatDateYYYYMMDD(videoNews[featuredVideoIndex]?.publishedAt)}</p>
                            <h3 className="mb-4">{videoNews[featuredVideoIndex]?.title}</h3>
                            <div>
                                <p
                                    className="text-muted"
                                    dangerouslySetInnerHTML={{ __html: videoNews[featuredVideoIndex]?.excerpt }}
                                />
                                {/*<br></br><br></br><br></br>
                                 Jó tanulást!
                                <br></br><br></br><br></br> */}
                            </div>
                        </div>
                    </div>
                    <img className="bg-vector" src={news_vector} alt="" />
                </div>
            </div>
            <div className="container mt-5">
                <h3 className="mb-4">Nézd meg beszámolóinkat korábbi eseményeinkről!</h3>
                <div className="row">
                    {/**https://img.youtube.com/vi/q5BO8dovEhY/maxresdefault.jpg */}

                    {smallVideoNews.map((videoDetails: any) => <div className="col-12 col-lg-6 col-xl-3">
                        <NewsCard
                            title={videoDetails?.title || ''}
                            date='2020.06.02.'
                            description={videoDetails?.excerpt || ''}
                            isCompact={false}
                            mainPicture={videoDetails?.cover?.sizes?.original?.url || KepzesSelye}
                            handleParentClick={(e: Event) => window.open(`https://www.youtube.com/watch?v=${videoDetails.videoId}`, "_blank")} />
                    </div>)}
                </div>
            </div>
            <div className="container mt-5">
                <div className="d-flex flex-row align-items-end mt-5 mb-4 justify-content-between">
                    <h3 className="mb-0">Gyere el workshopjainkra is!</h3>
                    <Link to='/esemenyek' className="d-none d-lg-flex text-cc-primary"><b>Összes esemény</b></Link>
                </div>

                <div className="row">
                    {eventList.map((eventDetails: any, index: number) =>
                        index < 4 &&
                        <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12" key={`event-${eventDetails.id}`}>
                            <EventCard
                                foundation={undefined}
                                title={eventDetails.title}
                                tags={eventDetails?.tags?.map((tagDetails: any) =>
                                ({
                                    color: 'orange',
                                    label: tagDetails.name,
                                    leftIcon: false,
                                    icon: '',
                                    rightIcon: false,
                                    removeTag: { function() { } },
                                })
                                )}
                                description={eventDetails.description}
                                mainPicture={eventDetails.cover.sizes.original.url || event1}
                                handleParentClick={() => toggleBookmarkEvent(eventDetails.id, eventDetails.registered)}
                                schedule={eventDetails.date.split(' ')[0]}
                                presentors={[eventDetails.lecturers]}
                                attendants={new Array(eventDetails.registeredUsersCount)}
                                placement={eventDetails.online ? "online" : eventDetails.city || ''}
                                registered={eventDetails.registered}
                                availableSeats={eventDetails.availableSeats}
                                id={eventDetails.id}
                            />
                        </div>
                    )}
                    <div className='mt-5 col-12 d-flex d-lg-none text-center justify-item-center'>
                        <Link to='/esemenyek' className=" text_center_all_events "><b>Összes esemény</b></Link>
                    </div>
                </div>
            </div>
        </>
    )
}

export default News
