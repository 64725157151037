import React from 'react'
import LinkWithIcon from '../link/LinkWithIcon'
import './tab.css'

interface tablist_2_properties {
    linkList: Array<any>,
    activeLink: number
}

/// itt szükség lehet egy callback függvény hívásra!!
/// A kapott sablon értelmezhetetlen, a formázást egyeztetni kell

function TabList2(props: tablist_2_properties) {

    const { linkList, activeLink } = props;
    /* const [selected, setSelected] = React.useState(0);
*/
    const handleLinkClick = (indexOfLink: number) => {
        /* setSelected(indexOfLink); */
        linkList[indexOfLink].onClickHandler();
    }

    const amIActive = (indexOfLink: number) => {
        return indexOfLink === activeLink ? 'active_link' : ''
    }

    return (
        <div className='tab_list_2_container'>
            {linkList.map((linkDetails, index) =>
                <div
                    className={`tab_list_2_link_container 
               ${amIActive(index)}`}
                    onClick={() => handleLinkClick(index)}
                    key={index}
                >
                    <LinkWithIcon
                        icon={linkDetails.icon}
                        label={linkDetails.label}
                        linkTo={linkDetails.linkTo}
                        active={index === activeLink}
                    />
                </div>
            )}

        </div>
    )

}

export default TabList2
