import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'

import Notification from '../../core/notification/Notification'

import authProvider from '../../authProvider'
import dataProvider from '../../dataProvider'

import enjovom_logo from '../../assets/img/cbcmlogo.svg'
import { ReactComponent as DownArrow } from '../../assets/icons/chevron-down-solid.svg'

import './header.css'
import './style_szt.css'
import { Toggle } from 'rsuite'
import { Dropdown } from 'react-bootstrap'

const dropList: Array<string> = ['Profilom', 'Beállítások', 'Kijelentkezés']

function Header(props: any) {
	const styles: Array<React.CSSProperties> = [
		{
			visibility: 'visible',
			transition: 'all 0.5s',
			background: 'none',
			position: 'absolute',
			zIndex: 99,
		},
		{
			visibility: 'visible',
			transition: 'all 0.5s',
			backgroundColor: '#FFFFFF',
			position: 'fixed',
			zIndex: 99,
		},
		{
			visibility: 'hidden',
			transition: 'all 0.5s',
			transform: 'translateY(-100%)',
		},
	]
	/* Menü elemek map-oláshoz,
  jelenlegi oldal megállapításához, aktiv link feloldásához
   oldal alapján, a menü színezését csak scripttel tudtam megoldani*/
	const [dropIsOpen, setDropIsOpen] = React.useState(false)

	let history = useHistory()

	const menuItems = [
		{
			linkTo: '/kezelopult',
			label: 'Kezelőpult',
		},
		{
			linkTo: '',
			label: 'Tananyagok',
			children: [
				{
					linkTo: '/onfejlesztes',
					label: 'Önfejlesztés',
				},
				{
					linkTo: '/fenntarthatosag',
					label: 'Fenntarthatóság',
				},
			],
		},
		{
			linkTo: '/hirek',
			label: 'Hírek',
		},
		{
			linkTo: '/esemenyek',
			label: 'Események',
		},
		{
			linkTo: '/rolunk',
			label: 'Rólunk',
		},
		{
			linkTo: '/mentorok',
			label: 'Mentoraink',
		},
		{
			linkTo: '/partnerek',
			label: 'Partnereink',
		},
	]
	const [currentPage, setCurrentPage] = React.useState(-1)
	const [notifications, setNotifications] = React.useState<any[]>([])
	const [isNavbarOpen, setIsNavbarOpen] = React.useState(true)
	const [isMobileView, setIsMobileView] = React.useState(false)
	const [isLeftMenuShow, setIsLeftMenuShow] = React.useState(false)

	const toggleNavbar = () => {
		setIsNavbarOpen((current) => !current)
	}

	React.useEffect(() => {
		setIsMobileView(window.innerWidth < 1200)
	}, [window.innerWidth])

	const location = useLocation()

	React.useEffect(() => {
		let found = false
		menuItems.forEach((menuItem: any, index: number) => {
			if (location.pathname.includes(menuItem.linkTo)) {
				setCurrentPage(index)
				found = true
			}
			if (!found) {
				setCurrentPage(-1)
			}
		})
	}, [location.pathname])
	/*
  Kényszerített színezés beállítások vége
  */

	React.useEffect(() => {
		authProvider.checkAuth() &&
			dataProvider.getMany('/user/notifications', (response: any) => {
				if (response?.status === 200) {
					const responseData = response?.data?.data || []
					let limitedAmountOfNotifications: any[] = []
					responseData.forEach(
						(notification: any, index: number) =>
							index < 3 &&
							limitedAmountOfNotifications.push(notification),
					)
					setNotifications(limitedAmountOfNotifications || [])
				}
			})
	}, [location])

	const removeNotification = (notifIndex: number, e: Event) => {
		e.stopPropagation()
		let newNotifications = notifications.map((notif: any) => notif)
		setNotificationReaded(notifications[notifIndex].id)
		newNotifications.splice(notifIndex, 1)
		setNotifications(newNotifications)
	}

	// storing this to get the scroll direction
	const [lastScrollTop, setLastScrollTop] = React.useState(0)
	// the offset of the document.body
	const [bodyOffset, setBodyOffset] = React.useState(
		document.body.getBoundingClientRect(),
	)
	// the vertical direction
	const [scrollY, setScrollY] = React.useState(bodyOffset.top)
	// the horizontal direction
	const [scrollX, setScrollX] = React.useState(bodyOffset.left)
	// scroll direction would be either up or down
	const [scrollDirection, setScrollDirection] = React.useState<
		string | undefined
	>()

	const listener = (e: Event) => {
		setBodyOffset(document.body.getBoundingClientRect())
		setScrollY(window.scrollY)
		setScrollX(bodyOffset.left)
		setScrollDirection(lastScrollTop > -bodyOffset.top ? 'down' : 'up')
		setLastScrollTop(-bodyOffset.top)
	}

	React.useEffect(() => {
		window.addEventListener('scroll', listener, true)
		return () => {
			window.removeEventListener('scroll', listener, true)
		}
	})

	const {
		accessToken,
		userProfile,
		setAccessToken,
		isDarkMode,
		toggleDarkMode,
	} = props

	const calculateFirstLetters = (): string => {
		if (
			userProfile.firstName.length > 0 &&
			userProfile.lastName.length > 0
		) {
			const letters =
				userProfile.lastName.charAt(0) + userProfile.firstName.charAt(0)
			return letters
		}
		return ''
	}

	const spacerRef = React.createRef<HTMLDivElement>()
	const menuButtonRef = React.createRef<HTMLButtonElement>()

	const handleDropClick = (selectedMenuName: string) => {
		switch (selectedMenuName) {
			case 'Profilom':
				history.push('/profil')
				setDropIsOpen(false)
				break
			case 'Beállítások':
				history.push('/beallitasok')
				setDropIsOpen(false)
				break
			case 'Kijelentkezés':
				authProvider.logout()
				history.push('/')
				setDropIsOpen(false)
				setAccessToken(null)
				break
			default:
				setDropIsOpen(false)
				break
		}
	}

	const dropdownHandler = (
		e: React.MouseEvent<HTMLSpanElement, MouseEvent>,
	) => {
		e.stopPropagation()
		window.addEventListener('click', (e: Event) => outOfDropDownHandler(e))
		setDropIsOpen(!dropIsOpen)
	}

	const outOfDropDownHandler = (e: Event) => {
		setDropIsOpen(false)
		window.removeEventListener('click', (e: Event) =>
			outOfDropDownHandler(e),
		)
	}

	const showSideMenu = (
		e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
	) => {
		e.stopPropagation()
		if (
			spacerRef &&
			menuButtonRef &&
			menuButtonRef.current &&
			spacerRef.current
		) {
			const myDiv = spacerRef.current
			myDiv.classList.add('left_menu_show')
			menuButtonRef.current.style.display = 'none'
			setIsLeftMenuShow(true)
		}
	}

	const hideSideMenu = () => {
		if (
			spacerRef &&
			menuButtonRef &&
			menuButtonRef.current &&
			spacerRef.current
		) {
			const myDiv = spacerRef.current
			myDiv.classList.remove('left_menu_show')
			menuButtonRef.current.style.display = 'inline'
			setIsLeftMenuShow(false)
		}
	}

	const setNotificationReaded = (notifId: number) => {
		dataProvider.createNew(
			`/user/notifications/read/${notifId}`,
			{},
			(response: any) => {
				console.log(response)
			},
		)
	}

	type CustomToggleProps = {
		children: React.ReactNode
		onClick: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
	}

	const CustomToggle = React.forwardRef(
		(
			{ children, onClick }: CustomToggleProps,
			ref: React.LegacyRef<HTMLAnchorElement>,
		) => (
			// eslint-disable-next-line jsx-a11y/anchor-is-valid
			<a
				href=""
				ref={ref}
				onClick={(e) => {
					e.preventDefault()
					onClick(e)
				}}
				className="cc-navigation-menu-link error_report"
			>
				{children}
			</a>
		),
	)

	return (
		<>
			<div className=" notifications_container mx-2">
				{notifications.map(
					(notificationDetails: any, index: number) => (
						<div
							className=""
							onClick={() => {
								setNotificationReaded(notificationDetails.id)
								history.push(notificationDetails.link)
							}}
							key={`n-${index}`}
						>
							<Notification
								title={notificationDetails.title}
								description={notificationDetails.message}
								type="warn"
								profil={false}
								date={
									notificationDetails?.date?.split(' ')[0] ||
									''
								}
								isReaded={true}
								handleClose={(e: any) =>
									removeNotification(index, e)
								}
							/>
						</div>
					),
				)}
			</div>
			<div className="menu_holder">
				<div
					className="cc-navigation"
					style={isNavbarOpen ? styles[1] : styles[2]}
				>
					<div className="coca-container spacer" ref={spacerRef}>
						<div
							onClick={hideSideMenu}
							className="position-fixed"
							style={{
								inset: 0,
								pointerEvents: isLeftMenuShow ? 'auto' : 'none',
							}}
						></div>
						<div className="row gx-0 container">
							<div className="col cc-logo"></div>
							<div className="col col-xl-7 cc-menu">
								<div
									className="cc-navigation-menu-button d-block"
									onClick={() => hideSideMenu()}
									title="Navigációs menü bezárása"
								>
									<button className="btn btn-circle bg-white cl-red ">
										<span className="ejicon-close"></span>
									</button>
								</div>
								<ul className="d-xl-flex justify-content-xl-between cc-navigation-menu">
									{menuItems.map(
										(menuElement: any, index: number) => (
											<>
												{(index > 0 ||
													authProvider.checkAuth()) && (
													<li
														className="cc-navigation-menu-item d-block"
														style={{
															fontWeight: 'bold',
														}}
														key={`menu-${index}`}
													>
														{menuElement.children &&
														menuElement.children
															.length ? (
															<Dropdown>
																<Dropdown.Toggle
																	as={
																		CustomToggle
																	}
																>
																	{
																		menuElement.label
																	}
																</Dropdown.Toggle>

																<Dropdown.Menu>
																	{menuElement.children.map(
																		(
																			item: any,
																			index: number,
																		) => (
																			<Dropdown.Item
																				key={
																					index
																				}
																				href={
																					item.linkTo
																				}
																			>
																				{
																					item.label
																				}
																			</Dropdown.Item>
																		),
																	)}
																</Dropdown.Menu>
															</Dropdown>
														) : (
															<Link
																to={
																	menuElement.linkTo
																}
																className={`cc-navigation-menu-link error_report ${
																	index ===
																	currentPage
																		? 'menu-activated'
																		: ''
																}`}
																onClick={() =>
																	hideSideMenu()
																}
																style={{
																	color: `${
																		index ===
																		currentPage
																			? 'var(--red)'
																			: ''
																	}`,
																}}
															>
																{
																	menuElement.label
																}
															</Link>
														)}
													</li>
												)}
											</>
										),
									)}
									<li className="cc-navigation-menu-item d-block">
										{isMobileView && (
											<>
												<div
													style={{ display: 'flex' }}
													onClick={(e: any) =>
														e.stopPropagation()
													}
												>
													<span
														style={{
															color: 'var(--white)',
															fontSize: '18px',
															padding:
																'1rem 1rem',
															display: 'block',
															cursor: 'pointer',
															fontWeight: 600,
														}}
														onClick={toggleDarkMode}
													>
														Kontraszt Mód
													</span>
													<div
														style={{
															marginTop: '15px',
														}}
													>
														<Toggle
															size="lg"
															checked={isDarkMode}
															onChange={(
																_: any,
																e: any,
															) => {
																toggleDarkMode()
															}}
														/>
													</div>
												</div>
											</>
										)}
									</li>
								</ul>
							</div>
							<div className="col justify-content-xl-center d-xl-flex flex-xl-column cc-menu-end"></div>
						</div>
					</div>
					<div className="coca-container buttons">
						<div className="row gx-0">
							<div className="col col-xl-auto cc-logo">
								<a
									href="/"
									className="justify-content-xl-center d-xl-flex flex-xl-column logo"
								>
									<img alt="#énjövőm" src={enjovom_logo} />
								</a>
							</div>
							<div className="col col-xl-7 cc-menu"></div>
							<div className="col justify-content-xl-center d-xl-flex flex-xl-column cc-menu-end">
								<div className="wrap text-end justify-content-start">
									{!isMobileView && (
										<Toggle
											style={{ paddingRight: '20px' }}
											size="lg"
											checkedChildren="Kontraszt Mód"
											unCheckedChildren="Kontraszt Mód"
											onChange={toggleDarkMode}
										/>
									)}
									{accessToken ? (
										<>
											<button className="button_with_logged_in btn">
												<span
													className="cl-white ikon_with_big justify-content-center align-items-center"
													onClick={(
														e: React.MouseEvent<
															HTMLSpanElement,
															MouseEvent
														>,
													) => dropdownHandler(e)}
												>
													{userProfile?.profilePictureUrl ? (
														<img
															src={
																userProfile.profilePictureUrl
															}
															alt="#énjövőm profil"
															style={{
																objectFit:
																	'cover',
																height: '100%',
																width: '100%',
															}}
														/>
													) : (
														<span className="header_letters d-flex justify-content-center align-items-center body_bold">
															{calculateFirstLetters()}
														</span>
													)}
												</span>
											</button>
										</>
									) : (
										<Link to="/bejelentkezes">
											<button className="btn bg-red cl-white login">
												<span className="cl-white button-text menubtn_nagyban_latszik">
													Belépek
												</span>
												<span className="cl-white ikon_with_small ejicon-user justify-content-center menubtn_kicsiben_latszik"></span>
											</button>
										</Link>
									)}
									{dropIsOpen ? (
										<div className="header_drop_container d-flex fit-content flex-column col-2 text-start">
											{dropList.map(
												(list_element, index) => (
													<>
														<li
															key={index}
															className={`droplist_element droplist_radio`}
															onClick={() =>
																handleDropClick(
																	list_element,
																)
															}
														>
															{index === 0 ? (
																<span className="ejicon-user"></span>
															) : null}
															{index === 1 ? (
																<span className="ejicon-settings"></span>
															) : null}
															{index === 2 ? (
																<span className="ejicon-exit"></span>
															) : null}
															{list_element}
														</li>
														{index === 1 ? (
															<hr className="droplist_ruler" />
														) : null}
													</>
												),
											)}
										</div>
									) : null}
									<button
										className="btn btn-circle bg-white cl-red cc-navigation-menu-button"
										onClick={(
											e: React.MouseEvent<
												HTMLButtonElement,
												MouseEvent
											>,
										) => showSideMenu(e)}
										ref={menuButtonRef}
										title="Navigációs menü megnyitása"
									>
										<span className="ejicon-menu"></span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					className={`cc-navigation-toggle cc-navigation-toggle-hide-${
						isNavbarOpen ? 'open' : 'closed'
					}`}
				>
					<div
						className="cc-navigation-toggle-space"
						onClick={toggleNavbar}
					>
						<DownArrow
							className={
								'svg-red arrow-down' +
								(isNavbarOpen ? ' open' : '')
							}
							style={{
								width: '20px',
							}}
						/>
					</div>
				</div>
			</div>
		</>
	)
}

export default Header
