import React from 'react'
import { useHistory } from 'react-router-dom'

import Tag from '../../core/tag/Tag';
import Button from '../../core/button/Button';
import Notification from '../../core/notification/Notification';
import Circle from '../../core/circle/Circle';
import ProgressCircle from '../../core/progress_circle/ProgressCircle';

import { Interests } from './Interests';
import { CoursesDone } from './CoursesDone';
import { Honors } from './Honors';
import { PersonalData } from './PersonalData';
import { Degrees } from './Degrees';
import { TodoList } from './TodoList';

import books from '../../assets/icons/emoji/books.png'
import clock from '../../assets/icons/emoji/clock.png'
import medal from '../../assets/icons/badge/gold_medal_alt.png'

import dataProvider from '../../dataProvider';
import authProvider from '../../authProvider';

import './profile.css'

function Profile(props: any) {

    const { userProfile, setUserProfile, courseList, eventList, modalPopper, closeModal } = props;

    const [selectedTag, setSelectedTag] = React.useState(0);
    const [hoveredTag, setHoveredTag] = React.useState(-1);
    const [subscribedCoursesList, setSubscribedCoursesList] = React.useState<any[]>([])
    const [bookmarkedCoursesList, setBookmarkedCoursesList] = React.useState<any[]>([])
    const [recommendedCoursesList, setRecommendedCoursesList] = React.useState<any[]>([])
    const [completedCoursesAmount, setCompletedCoursesAmount] = React.useState<number>(0);
    const [filteredEventList, setFilteredEventList] = React.useState([]);
    const [statistics, setStatistics] = React.useState<any>({});
    const [notifications, setNotifications] = React.useState<any[]>([])

    const tagList = [
        {
            icon: 'ejicon-heart',
            label: 'Érdeklődések',
            component: <Interests
                modalPopper={modalPopper}
                closeModal={closeModal}
            />
        },
        {
            icon: 'ejicon-tick',
            label: 'Elvégzett tananyagok',
            component: <CoursesDone courseList={subscribedCoursesList} />
        },
        {
            icon: 'ejicon-eye',
            label: 'Kitüntetések',
            component: <Honors statistics={statistics} />
        },
        {
            icon: 'ejicon-user',
            label: 'Személyes adatok',
            component: <PersonalData userProfile={userProfile} setUserProfile={setUserProfile} />
        },
        {
            icon: 'ejicon-document-sign',
            label: 'Oklevelek',
            component: <Degrees courseList={subscribedCoursesList} />
        },
    ]


    const history = useHistory();
    const hiddenFileInput = React.createRef<HTMLInputElement>()

    React.useEffect(() => {
        !authProvider.checkAuth() && history.push('/')

        const subscribedList: any[] = [];
        const bookmarkedList: any[] = [];
        const recommendedList: any[] = [];
        /* console.log(courseList); */
        courseList.forEach((course: any) => {
            if (course.bookmarked && !course.subscribed) { bookmarkedList.push(course) };
            if (course.subscribed) { subscribedList.push(course) };
            if (!course.bookmarked && !course.subscribed && recommendedList.length < 2) { recommendedList.push(course) };
        })
        setSubscribedCoursesList(subscribedList);
        setCompletedCoursesAmount(subscribedList.filter(sub => sub.finished).length)
        setBookmarkedCoursesList(bookmarkedList);
        setRecommendedCoursesList(recommendedList);
    }, [courseList])

    React.useEffect(() => {
        let filteredList: any = [];
        const dateNow = new Date().getTime();
        eventList.forEach((eventDetails: any) => {
            if (new Date(eventDetails?.date).getTime() > dateNow && filteredList.length < 3) {
                filteredList.push(eventDetails);
            }
        })
        setFilteredEventList(filteredList)
    }, [eventList])

    const handleClick = () => {
        if (hiddenFileInput && hiddenFileInput.current) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = (e: any) => {
        const picture = e?.target?.files[0] || null;
        const accessToken = localStorage.getItem('access_token');
        if (picture) {
            let formData = new FormData();
            formData.append("image", picture);
            let myHeaders = new Headers();
            myHeaders.append("Authorization", `Bearer ${accessToken}`);
            fetch("/api/user/profile/picture", {
                body: formData,
                method: 'POST',
                headers: myHeaders
            })
                .then(response => response.text())
                .catch(error => console.log('error', error));
        }
    };

    const handleContinueClick = (courseId: number | string) => {
        history.push(`/tananyagok/${courseId}`)
    }

    React.useEffect(() => {
        dataProvider.getMany('/user/statistics', (response: any) => {
            setStatistics(response?.data?.data);
        })
        dataProvider.getMany('/notifications', (response: any) => {
            /*  console.log('notis')
             console.log(response?.data?.data || []) */
            setNotifications(response?.data?.data || [])
        })
    }, [])

    const calculateFirstLetters = (): string => {
        if (userProfile.firstName?.length > 0 && userProfile.lastName?.length > 0) {
            const letters = userProfile.lastName.charAt(0) + userProfile.firstName.charAt(0)
            return letters;
        }
        return ''
    }

    return (
        <div className="container-xl mt-5">
            <div className="row justify-content-between">
                <div className="col-12 col-lg-8 pe-4">
                    <div className="row mb-5 align-items-center">
                        <div className="col-auto mx-auto mx-lg-0 col-lg-auto text-center profile-page-pic-container">
                            {userProfile?.profilePictureUrl ?
                                <img src={userProfile.profilePictureUrl} alt="" className="img-fluid" />
                                :
                                <span className="header_letters d-flex justify-content-center align-items-center body_bold">
                                    {calculateFirstLetters()}
                                </span>
                            }
                            <div onClick={handleClick}>
                                <Circle icon='ejicon-edit' disabled={false} type='red' />
                            </div>
                            <input type="file" name="image" accept="image/*" multiple={false} id="upload-photo" onChange={(e: any) => handleChange(e)} ref={hiddenFileInput} />
                        </div>
                        <div className="col-12 col-lg-auto text-lg-left">
                            <h2 className="mb-0">{` ${userProfile.lastName} ${userProfile.firstName}`}</h2>
                            <p className="mb-0">
                                <i className="ejicon ejicon-map me-2"></i>
                                {userProfile.region}</p>
                        </div>
                    </div>
                    <div className="row mb-5 align-items-center">
                        <div className="col-12 col-lg-4">
                            <div className="card d-block d-lg-none mb-4">
                                <div className="card-body">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="profile-iconholder">
                                            <img src={books} alt="" />
                                        </div>
                                        <div className="ms-3">
                                            <p className="mb-0">
                                                <strong>{statistics?.completedCourses} modult</strong>
                                            </p>
                                            <p className="mb-0">végeztél el</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-lg-block">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="profile-iconholder">
                                        <img src={books} alt="" />
                                    </div>
                                    <div className="ms-3">
                                        <p className="mb-0">
                                            <strong>{statistics?.completedCourses} modult</strong>
                                        </p>
                                        <p className="mb-0">végeztél el</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card d-block d-lg-none mb-4">
                                <div className="card-body">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="profile-iconholder">
                                            <img src={medal} alt="" />
                                        </div>
                                        <div className="ms-3">
                                            <p className="mb-0">
                                                <strong>{statistics?.badgeCount} jelvényt</strong>
                                            </p>
                                            <p className="mb-0">szereztél eddig</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-lg-block">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="profile-iconholder">
                                        <img src={medal} alt="" />
                                    </div>
                                    <div className="ms-3">
                                        <p className="mb-0">
                                            <strong>{statistics?.badgeCount} jelvényt</strong>
                                        </p>
                                        <p className="mb-0">szereztél eddig</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="card d-block d-lg-none">
                                <div className="card-body">
                                    <div className="d-flex flex-row align-items-center">
                                        <div className="profile-iconholder">
                                            <img src={clock} alt="" />
                                        </div>
                                        <div className="ms-3">
                                            <p className="mb-0">
                                                <strong>{statistics?.totalHours} órát</strong>
                                            </p>
                                            <p className="mb-0">töltöttél tanulással</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="d-none d-lg-block">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="profile-iconholder">
                                        <img src={clock} alt="" />
                                    </div>
                                    <div className="ms-3">
                                        <p className="mb-0">
                                            <strong>{statistics?.totalHours} órát</strong>
                                        </p>
                                        <p className="mb-0">töltöttél tanulással</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="taglist_container d-flex flex-row justify-content-between">
                        {tagList.map((tagProperties, index) =>
                            <div
                                key={`tag-${index}`}
                                className={`tag_container body_small body_bold
                        ${index === selectedTag || index === hoveredTag ? 'tag_active' : ''}
                        `}
                                onClick={() => setSelectedTag(index)}
                                onMouseEnter={() => { setHoveredTag(index) }}
                                onMouseLeave={() => { setHoveredTag(-1) }}
                            >
                                <Tag
                                    color={index === selectedTag || index === hoveredTag ? 'red' : 'grey'}
                                    label={tagProperties.label}
                                    leftIcon={true}
                                    icon={tagProperties.icon}
                                    rightIcon={false}
                                    removeTag={() => { }}
                                />
                            </div>
                        )}
                    </div>
                    <hr></hr>
                    {tagList[selectedTag].component}
                </div>
                <div className="col-12 col-lg-4 p-4 ps-lg-3 pt-lg-4 pe-lg-0 profile-sidebar">
                    <h5>Tennivalók</h5>
                    <TodoList />
                    {subscribedCoursesList.length > completedCoursesAmount && <h5 className="mt-5">Megkezdett kurzusok</h5>}
                    {subscribedCoursesList.map((courseDetails: any, index: number) =>
                        <>
                            {!courseDetails?.finished &&
                                <div className="card justified-card-on-mobile">
                                    <div className="card-body">
                                        <div className="d-flex flex-row align-items-start align-items-lg-center ">
                                            <img className="resize-img-size" src={courseDetails?.cover?.sizes?.course?.url} alt="" />
                                            <div className="ms-3">
                                                <h5 className="mb-3 ">{courseDetails.title}</h5>
                                                <div className="d-flex flex-row align-items-center profile-sidethings-container"
                                                    onClick={() => handleContinueClick(courseDetails.slug)}

                                                >
                                                    <Button
                                                        type='primary'
                                                        label='Folytatom'
                                                        disabled={false}
                                                        icon=''
                                                        icon_position='left'
                                                    />
                                                    <div className="progress_container d-flex ms-3 me-auto">
                                                        <ProgressCircle progress={courseDetails?.progress?.progress || 0} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </>)}
                    <h5 className="mt-5">Mi történt mostanában?</h5>
                    {
                        notifications.map((notificationDetails: any) =>
                            <div
                                onClick={() => history.push(notificationDetails.link)}
                                style={{ cursor: 'pointer' }}
                            >
                                <Notification
                                    title={notificationDetails.title}
                                    description={notificationDetails.message}
                                    type='warn'
                                    profil={true}
                                    date={notificationDetails.date}
                                    isReaded={false}
                                />
                            </div>
                        )
                    }
                </div>
            </div>
        </div >
    )
}

export default Profile

