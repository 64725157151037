function DeezerIcon() {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="16" cy="16" r="16" fill="var(--red)"/>
      <rect x="7" y="20.2105" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
      <rect x="11.6763" y="20.2105" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
      <rect x="11.6763" y="16.5262" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
      <rect x="11.6763" y="12.6841" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
      <rect x="16.397" y="16.5262" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
      <rect x="16.397" y="20.2105" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
      <rect x="21.0737" y="20.2105" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
      <rect x="21.0737" y="16.5262" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
      <rect x="21.0737" y="12.6841" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
      <rect x="21.0737" y="9" width="3.92647" height="2.78949" rx="0.2" fill="white"/>
    </svg>
  );
}

export default DeezerIcon;