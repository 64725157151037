/* 
properties:
  title: string,
    description: string,
    percentage: number,
    rating: number,
    nrOfRatings: number,
    tags: Array<any>,           ===> any = {label: string, color: string}, de az előre beállított értékek felülírhatják a színt
    nrOfStudents: number,
    mainPicture: string            ===> kép neve
    handleParentClick: Function    ===> callback függvény, belépéshez

*/

import React from 'react'

import Circle from '../../core/circle/Circle'
import StarLine from '../../core/star/StarLine';
import Tag from '../../core/tag/Tag'

import authProvider from '../../authProvider';
import dataProvider from '../../dataProvider';

import './modulcard.css'

interface modulcard_properties {
    title: string,
    description: string,
    percentage: number,
    rating: number,
    nrOfRatings: number,
    tags: Array<any>,
    nrOfStudents: number,
    mainPicture: string,
    handleParentClick: Function,
    id?: number,
    bookmarked?: boolean,
    refresher?: Function
}

function ModulCard(props: modulcard_properties) {

    const { title,
        description,
        percentage,
        rating,
        nrOfRatings,
        tags,
        nrOfStudents,
        mainPicture,
        handleParentClick,
        id,
        bookmarked,
        refresher
    } = props;
    const [bookmarkedCourse, setBookmarkedCourse] = React.useState<boolean | undefined>(bookmarked)

    const bookmarkCourse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation()
        if (id) {
            dataProvider.updateSpecial('/courses', id, 'bookmark', {}, (response: any) => { /* console.log(response) */ });
        }
        setBookmarkedCourse(bookmarkedCourse ? false : true)
        if (refresher) {
            setTimeout(() => {
                refresher()
            }, 200);
        }
    }

    React.useEffect(() => {
        setBookmarkedCourse(bookmarked)
    }, [bookmarked])

    return (
        <div
            className="modulcard_container"
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => handleParentClick(e)}
        >
            <div className="modulcard_img_elements ratio ratio-16x9">
                {percentage === 100 ? <div className="modulcard_status">
                    <Tag color='green' label='Befejezve' leftIcon={true} icon='ejicon-tick' rightIcon={false} removeTag={(e: any) => { }} />
                </div> : null}
                {authProvider.checkAuth() && <div className="modulcard_bookmark">
                    <div
                        className={`modulcard_circle_container ${bookmarkedCourse ? '' : 'not_bookmarked'}`}
                        onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => bookmarkCourse(e)}
                    >
                        <Circle icon='ejicon-bookmark' disabled={false} type="white" />
                    </div>
                </div>}
                {percentage > 0 ? <div className="modulcard_completion">
                    <div className="modulcard_completion_max">
                        <div className="modulcard_completion_status" style={{ "width": `${percentage}%` }} >
                        </div>
                    </div>
                    <div className="modulcard_completion_no body_tiny">
                        {percentage} %
                    </div>
                </div>
                    : null
                }
                <div className="modulcard_img ">
                    <img src={mainPicture} width='100%' alt="modul_kép" />
                </div>
            </div>
            <div className="modulcard_textbox">
                <div className="modulcard_stats">
                    <div className="modulcard_stats_cell">
                        <div>
                            <StarLine
                                rating={rating}
                                disabled={true}
                            />
                        </div>
                        <div className="modulcard_stars_no">
                            ({nrOfRatings})
                        </div>
                    </div>
                    <div className="modulcard_stats_cell">
                        <div className="modulcard_viewers_icon ejicon-user">
                        </div>
                        <div className="modulcard_viewers_no">
                            {nrOfStudents}
                        </div>
                    </div>
                </div>
                <div className="modulcard_title heading_five" >
                    {title}
                </div>
                <div className="modulcard_desc body_small">
                    {description}
                </div>
                <div className="modulcard_tags">
                    {tags.map((tagProps, index: number) => <div key={`tag-${index}`}><Tag color={tagProps.color} label={tagProps.label} leftIcon={false} icon='' rightIcon={false} removeTag={() => { }} /></div>)}
                </div>
            </div>
        </div>
    )
}

export default ModulCard