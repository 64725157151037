import React from 'react'
import { Container } from 'react-bootstrap'
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet";
import Calendar from 'react-calendar';

import TabList from '../../core/tab/TabList'
import Tag from '../../core/tag/Tag'
import Button from '../../core/button/Button'
import EventCard from '../../components/event/EventCard'
import Circle from '../../core/circle/Circle'
import DropInput from '../../core/inputs/DropInput'

import dataProvider from '../../dataProvider'

import event1 from '../../assets/img/events/1.jpg'

import './events.css'

interface extra_filters {
    city: string | null,
    date: string | null,
    category: string | null
}

function Events(props: any) {

    const { eventList, refreshEventList, modalPopper, closeModal } = props

    const params: any = useParams()
    const slug = params?.slug || null;

    const [filter, setFilter] = React.useState('összes');
    const [filteredEventList, setFilteredEvents] = React.useState<any[]>(eventList);
    const [extraFilteredEventList, setExtraFilteredEventList] = React.useState<any[]>([]);
    const [value, onChange] = React.useState(new Date());
    const [extraFilters, setExtraFilters] = React.useState<extra_filters>({
        city: null,
        date: null,
        category: null
    })
    const [showExtraFilterDrop, setShowExtraFilterDrop] = React.useState(false);
    const [cityDrop, setCityDrop] = React.useState([]);
    const [categoryDrop, setCategoryDrop] = React.useState([]);
    const [datesDrop, setDatesDrop] = React.useState<string[]>([])
    const [monthlySelectionNumber, setMonthlySelectionNumber] = React.useState(0);
    const [dailyDotAmounts, setDailyDotAmounts] = React.useState<any>({})
    const [dailyRegisteredDotAmounts, setDailyRegisteredDotAmounts] = React.useState<any>({})
    const [actualMonth, setactualMonth] = React.useState(new Date().getMonth());
    const [showMobileCalendar, setShowMobileCalendar] = React.useState(false)


    const tabList: Array<any> = [
        {
            label: 'Összes',
            onClickHandler: () => setFilter('összes')
        },
        {
            label: 'Online',
            onClickHandler: () => setFilter('online')
        },
        {
            label: 'Személyes',
            onClickHandler: () => setFilter('személyes')
        }
    ]

    const dropList: any = [
        <DropInput
            disabled={false}
            leftIcon='ejicon-pin'
            label='Válassz helyszint!'
            type='radio'
            onChangeHandler={(e: any) => setExtraFilters({ ...extraFilters, city: e[0] })}
            dropList={cityDrop}
            initialSelection={[]}
        />,
        <DropInput
            disabled={false}
            leftIcon='ejicon-calendar'
            label='Válassz dátumot!'
            type='radio'
            onChangeHandler={(e: any) => setExtraFilters({ ...extraFilters, date: e[0] })}
            dropList={datesDrop}
            initialSelection={[]}
        />,
        <DropInput
            disabled={false}
            leftIcon='ejicon-book'
            label='Válassz témát!'
            type='radio'
            onChangeHandler={(e: any) => setExtraFilters({ ...extraFilters, category: e[0] })}
            dropList={categoryDrop}
            initialSelection={[]}
        />
    ]

    React.useEffect(() => {
        let newList: any[] = []
        let thisMonthCount = 0;
        let dateList: string[] = [];
        let dailyCounters: any = {};
        let dailyRegisteredCounters: any = {};
        switch (filter) {
            case 'összes':
                setFilteredEvents(eventList.map((event: any) => event))
                setTimeout(() => {
                    setExtraFiltering(null)
                }, 20);

                break;
            case 'online':
                eventList.forEach((eventData: any) => {
                    if (eventData.online) { newList.push(eventData) }
                })
                setFilteredEvents(newList)
                setTimeout(() => {
                    setExtraFiltering(null)
                }, 20);
                break;
            case 'személyes':
                eventList.forEach((eventData: any) => {
                    if (!eventData.online) { newList.push(eventData) }
                })
                setFilteredEvents(newList)
                setTimeout(() => {
                    setExtraFiltering(null)
                }, 20);
                break;
            default:
                break;
        }
        eventList.forEach((eventDetails: any) => {

            const eventDate = eventDetails.date.split(' ')[0];
            if (new Date(eventDetails?.date).getMonth() === actualMonth && eventDetails.registered) {
                thisMonthCount++
            }
            if (!dateList.includes(eventDate)) {
                dateList.push(eventDate)
                dailyCounters[calculateYYYYMMDD(eventDate)] = 1;
            } else {
                dailyCounters[calculateYYYYMMDD(eventDate)] = dailyCounters[calculateYYYYMMDD(eventDate)] + 1
            }
            if (eventDetails.registered) {
                if (!dailyRegisteredCounters[calculateYYYYMMDD(eventDate)]) {
                    dailyRegisteredCounters[calculateYYYYMMDD(eventDate)] = 1;
                } else {
                    dailyRegisteredCounters[calculateYYYYMMDD(eventDate)] = dailyRegisteredCounters[calculateYYYYMMDD(eventDate)] + 1
                }
            }
        })
        setDailyDotAmounts(dailyCounters)
        setDailyRegisteredDotAmounts(dailyRegisteredCounters)
        setMonthlySelectionNumber(thisMonthCount)
        setDatesDrop(dateList)
    }, [filter, eventList])

    const setMyEventMonth = (myMonth: any) => {
        let newList: any[] = []
        let thisMonthCount = 0;
        let dateList: string[] = [];
        let dailyCounters: any = {};
        let dailyRegisteredCounters: any = {};
        eventList.forEach((eventDetails: any) => {
            const eventDate = eventDetails.date.split(' ')[0];
            if (new Date(eventDetails?.date).getMonth() === myMonth && eventDetails.registered) {
                thisMonthCount++
            }
            if (!dateList.includes(eventDate)) {
                dateList.push(eventDate)
                dailyCounters[calculateYYYYMMDD(eventDate)] = 1;
            } else {
                dailyCounters[calculateYYYYMMDD(eventDate)] = dailyCounters[calculateYYYYMMDD(eventDate)] + 1
            }
            if (eventDetails.registered) {
                if (!dailyRegisteredCounters[calculateYYYYMMDD(eventDate)]) {
                    dailyRegisteredCounters[calculateYYYYMMDD(eventDate)] = 1;
                } else {
                    dailyRegisteredCounters[calculateYYYYMMDD(eventDate)] = dailyRegisteredCounters[calculateYYYYMMDD(eventDate)] + 1
                }
            }

        })
        setDailyRegisteredDotAmounts(dailyRegisteredCounters)
        setDailyDotAmounts(dailyCounters)
        setMonthlySelectionNumber(thisMonthCount)
        setDatesDrop(dateList)
    }


    React.useEffect(() => {
        dataProvider.getMany('/events/cities', (response: any) => {

            if (response?.data?.data && response?.status === 200) {
                setCityDrop(response.data.data.map((tagDetails: any) => tagDetails))
            }
        });
        dataProvider.getMany('/events/eventtags', (response: any) => {

            if (response?.data?.data && response?.status === 200) {
                setCategoryDrop(response.data.data.map((tagDetails: any) => tagDetails?.name))
            }
        });
    }, [])

    const toggleBookmarkEvent = (eventId: number, currentValue: boolean | undefined) => {
        !currentValue && dataProvider.updateSpecial('/events', eventId, 'registration', {}, (response: any) => {
            if (response?.status === 200) {
                setTimeout(() => {
                    refreshEventList()
                }, 100);
            }
            if (response?.Error?.response?.data?.errors[0]?.code === 'event:no_available_seats') {
                modalPopper(<NoSeatsError closeModal={closeModal} />)
            }
            if (response?.Error?.response?.data?.errors[0]?.code === 'event:application_deadline_expired') {
                modalPopper(<DeadlineExpiredError closeModal={closeModal} />)
            }
        })
        currentValue && dataProvider.updateSpecial('/events', eventId, 'unregister', {}, (response: any) => {
            if (response?.status === 200) {
                setTimeout(() => {
                    refreshEventList()
                }, 100);
            }
        })
    }
    const setExtraFiltering = (base: extra_filters | null) => {
        let newListBase = filteredEventList.map((event: any) => event);
        let newList: any[] = []
        const city = base ? base.city : extraFilters.city
        const category = base ? base.category : extraFilters.category
        const date = base ? base.date : extraFilters.date;
        if (city !== null) {
            newListBase.forEach((eventDetails: any) => eventDetails.city === city && newList.push(eventDetails))
            newListBase = newList.map((event: any) => event);
            newList = []
        }
        if (date !== null) {
            newListBase.forEach((eventDetails: any) => eventDetails.date.split(' ')[0] === date && newList.push(eventDetails))
            newListBase = newList.map((event: any) => event);
            newList = []
        }
        if (category !== null) {
            newListBase.forEach((eventDetails: any) => {
                JSON.stringify(eventDetails.tags).includes(category) && newList.push(eventDetails)
            })
            newListBase = newList.map((event: any) => event);
            newList = []
        }
        setExtraFilteredEventList(newListBase)
    }

    const removeTag = (newExtraFilter: any) => {
        setExtraFilters(newExtraFilter)
        setExtraFiltering(newExtraFilter)
    }

    const calculateYYYYMMDD = (rawDate: Date) => {
        const date = new Date(rawDate)
        return (`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`)
    }

    const toggleShowCalendar = () => {
        console.log(42)
        setShowMobileCalendar(!showMobileCalendar)
    }



    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Események - enjovom.hu</title>
                <meta name="description" content="Ingyenes személyes és online workshopjaink munkakereső fiataloknak" />
            </Helmet>
            <Container onClick={
                showExtraFilterDrop ? () => { setShowExtraFilterDrop(false) } : () => { }
            }>
                <div className='row'>
                    <div className='col-xl-8'>
                        <Container className='mt-5'>
                            <div className='col events_title d-flex justify-content-center' >
                                <h1>#énjövőm események</h1>
                            </div>
                            <div className='mt-5 calendar_mobile'>
                                <div className='megnezem_naptar_btn col d-flex justify-content-center' onClick={toggleShowCalendar}>
                                    <Button type='secondary' label='Megnézem a naptáram' disabled={false} icon='ejicon-calendar' icon_position='left' />
                                </div>
                                {showMobileCalendar &&
                                    <div className='event_calendar_small mt-4 pb-4'>
                                        <Tag
                                            color='orange'
                                            label={`${monthlySelectionNumber} eseményre regisztráltál ebben a hónapban`}
                                            leftIcon={false}
                                            icon='ejicon-communication'
                                            rightIcon={false}
                                            removeTag={() => { }}
                                        />
                                        <div className="" >
                                            <Calendar
                                                onChange={() => { }}
                                                onActiveStartDateChange={
                                                    ({ activeStartDate, value, view }) =>
                                                        <>
                                                            {setactualMonth(new Date(activeStartDate).getMonth())}

                                                            {setMyEventMonth(new Date(activeStartDate).getMonth())}
                                                        </>
                                                }
                                                value={value}
                                                locale='hu-HU'
                                                maxDetail='month'
                                                defaultView='month'
                                                showNavigation={true}
                                                showNeighboringMonth={false}
                                                tileContent={
                                                    ({ activeStartDate, date, view }) => /* view === 'month' && */
                                                        <>
                                                            <div className='tile_active_my'></div>
                                                            <div className='calendar_potty_row'>
                                                                {new Array(dailyDotAmounts[calculateYYYYMMDD(date)] || 0).fill(true).map((nothing: boolean, index: number) =>
                                                                    <div className='calendar_potty'
                                                                        style={{ backgroundColor: dailyRegisteredDotAmounts[calculateYYYYMMDD(date)] && dailyRegisteredDotAmounts[calculateYYYYMMDD(date)] > index ? '#13C767' : '#F40009' }} />
                                                                )}
                                                            </div>
                                                        </>
                                                }
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className='mt-5 col course_tablist d-flex justify-content-center'>
                                <TabList tabList={tabList} />
                            </div>

                            <div className='col filter mt-5'>
                                <div className='row'>
                                    <div className='col-4'>
                                        {!(extraFilters.city || extraFilters.category || extraFilters.date) && <h3>Összes esemény</h3>}
                                    </div>
                                    <div className='col-6 d-flex flex-row-reverse my-auto'>
                                        <div>
                                            {extraFilters.city &&
                                                <Tag
                                                    color='grey'
                                                    label={extraFilters.city}
                                                    leftIcon={false}
                                                    icon=''
                                                    rightIcon={true}
                                                    removeTag={(e: any) => removeTag({ ...extraFilters, city: null })}
                                                />
                                            }
                                        </div>
                                        <div>
                                            {extraFilters.date &&
                                                <Tag
                                                    color='grey'
                                                    label={extraFilters.date}
                                                    leftIcon={false}
                                                    icon=''
                                                    rightIcon={true}
                                                    removeTag={(e: any) => removeTag({ ...extraFilters, date: null })}
                                                />
                                            }
                                        </div>
                                        <div>
                                            {extraFilters.category &&
                                                <Tag
                                                    color='grey'
                                                    label={extraFilters.category}
                                                    leftIcon={false}
                                                    icon=''
                                                    rightIcon={true}
                                                    removeTag={(e: any) => removeTag({ ...extraFilters, category: null })}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className='col-2' onClick={() => setShowExtraFilterDrop(true)}>
                                        <Circle icon='ejicon-filter' disabled={false} type='bordered' />
                                    </div>
                                    <div className='col-6'></div>
                                    <div className='col-4 d-flex flex-column'>
                                        {
                                            showExtraFilterDrop &&
                                            <div
                                                className='col-11 col-md-4 col-lg-4 col-xl-3 m-3 justify-content-center extra_filter_container'
                                                style={{ position: 'absolute', zIndex: 1 }}
                                                onClick={(e: any) => e.stopPropagation()}

                                            >
                                                {
                                                    showExtraFilterDrop && dropList.map((element: any) =>
                                                        <div className='m-2' >
                                                            {element}
                                                        </div>)
                                                }
                                                {showExtraFilterDrop &&
                                                    <div className='d-flex mx-auto justify-content-center' onClick={() => {
                                                        setExtraFiltering(null)
                                                        setShowExtraFilterDrop(false)
                                                    }}>
                                                        <Button
                                                            type='primary'
                                                            label='Szűrés'
                                                            disabled={false}
                                                            icon={null}
                                                            icon_position=''
                                                        />
                                                    </div>}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </Container>
                        <Container className='mt-5 d-flex'>
                            <div className='row'>
                                {
                                    !(extraFilters.city || extraFilters.category || extraFilters.date)
                                        ? filteredEventList.map((eventDetails: any) =>
                                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 ' key={eventDetails.id}>
                                                <div className="w-100">
                                                    <EventCard
                                                        foundation={undefined}
                                                        title={eventDetails.title}
                                                        tags={eventDetails?.tags?.map((tagDetails: any) =>
                                                        ({
                                                            color: 'orange',
                                                            label: tagDetails.name,
                                                            leftIcon: false,
                                                            icon: '',
                                                            rightIcon: false,
                                                            removeTag: { function() { } },
                                                        })
                                                        )}
                                                        description={eventDetails?.description}
                                                        mainPicture={eventDetails?.cover?.sizes?.original?.url || event1}
                                                        handleParentClick={() => toggleBookmarkEvent(eventDetails?.id, eventDetails.registered)}
                                                        schedule={eventDetails.date.split(' ')[0]}
                                                        presentors={[eventDetails.lecturers]}
                                                        attendants={new Array(eventDetails.registeredUsersCount)}
                                                        placement={eventDetails.online ? "online" : eventDetails.city || ''}
                                                        registered={eventDetails.registered}
                                                        availableSeats={eventDetails.availableSeats}
                                                        id={eventDetails.id}
                                                        popMeUp={slug && slug === eventDetails.slug}
                                                    />
                                                </div>
                                            </div>)
                                        :
                                        extraFilteredEventList.map((eventDetails: any) =>
                                            <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 ' key={eventDetails.id}>
                                                <div className="w-100">
                                                    <EventCard
                                                        foundation={undefined}
                                                        title={eventDetails.title}
                                                        tags={eventDetails?.tags?.map((tagDetails: any) =>
                                                        ({
                                                            color: 'orange',
                                                            label: tagDetails.name,
                                                            leftIcon: false,
                                                            icon: '',
                                                            rightIcon: false,
                                                            removeTag: { function() { } },
                                                        })
                                                        )}
                                                        description={eventDetails.description}
                                                        mainPicture={eventDetails.cover.sizes.original.url || event1}
                                                        handleParentClick={() => toggleBookmarkEvent(eventDetails.id, eventDetails.registered)}
                                                        schedule={eventDetails.date.split(' ')[0]}
                                                        presentors={[eventDetails.lecturers]}
                                                        attendants={new Array(eventDetails.registeredUsersCount)}
                                                        placement={eventDetails.online ? "online" : eventDetails.city || ''}
                                                        registered={eventDetails.registered}
                                                        availableSeats={eventDetails.availableSeats}
                                                        id={eventDetails.id}
                                                        popMeUp={slug && slug === eventDetails.slug}
                                                    />
                                                </div>
                                            </div>)}
                            </div>
                        </Container>
                    </div>
                    <div className='col-12 col-lg-6 col-xl-4 mt-5' >
                        <div className='event_calendar'>
                            <Tag
                                color='orange'
                                label={`${monthlySelectionNumber} eseményre regisztráltál ebben a hónapban`}
                                leftIcon={false}
                                icon='ejicon-communication'
                                rightIcon={false}
                                removeTag={() => { }}
                            />
                            <div className="" >
                                <Calendar
                                    onChange={() => { }}
                                    onActiveStartDateChange={
                                        ({ activeStartDate, value, view }) =>
                                            <>
                                                {setactualMonth(new Date(activeStartDate).getMonth())}

                                                {setMyEventMonth(new Date(activeStartDate).getMonth())}
                                            </>
                                    }
                                    value={value}
                                    locale='hu-HU'
                                    maxDetail='month'
                                    defaultView='month'
                                    showNavigation={true}
                                    showNeighboringMonth={false}
                                    tileContent={
                                        ({ activeStartDate, date, view }) => /* view === 'month' && */
                                            <>
                                                <div className='tile_active_my'></div>
                                                <div className='calendar_potty_row'>
                                                    {new Array(dailyDotAmounts[calculateYYYYMMDD(date)] || 0).fill(true).map((nothing: boolean, index: number) =>
                                                        <div className='calendar_potty'
                                                            style={{ backgroundColor: dailyRegisteredDotAmounts[calculateYYYYMMDD(date)] && dailyRegisteredDotAmounts[calculateYYYYMMDD(date)] > index ? '#13C767' : '#F40009' }} />
                                                    )}
                                                </div>
                                            </>
                                    }
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}

const NoSeatsError = (props: any) => {
    const { closeModal } = props
    return (
        <div className='modal-reg-megerosites' onClick={closeModal}>Az eseményre nincs már szabad hely!</div>
    )
}
const DeadlineExpiredError = (props: any) => {
    const { closeModal } = props
    return (
        <div className='modal-reg-megerosites' onClick={closeModal}>A regisztráció jelentkezési határideje lejárt!</div>
    )
}


export default Events
