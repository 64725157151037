import React from 'react'
import { Container } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom'

import TabList from '../../core/tab/TabList'
import EventCard from '../../components/event/EventCard'
import ModulCard from '../../components/modul/ModulCard'

import dataProvider from '../../dataProvider'

import './course_list.css'

import sampleImg from '../../assets/img/modul_sample_bg.png'
import sampleImg4 from '../../assets/img/events/4.jpg'

function CourseList(props: any) {
	const {
		courseList,
		refreshCourseList,
		eventList,
		refreshEventList,
		selectedFilter,
	} = props

	const history = useHistory()

	const [filteredCourseList, setFilteredCourseList] = React.useState(
		courseList.map((course: any) => course),
	)
	const [filter, setFilter] = React.useState(
		selectedFilter ? selectedFilter : ' ',
	)

	const tabList: Array<any> = [
		{
			label: 'összes',
			onClickHandler: () => setFilter(' '),
		},
		{
			label: 'önismeret és önfejlesztés',
			onClickHandler: () => setFilter('önismeret és önfejlesztés'),
		},
		{
			label: 'kommunikáció',
			onClickHandler: () => setFilter('kommunikáció'),
		},
		{
			label: 'projektmenedzsment',
			onClickHandler: () => setFilter('projektmenedzsment'),
		},
		{
			label: 'vállalkozói készségek',
			onClickHandler: () => setFilter('vállalkozói készségek'),
		},
		{
			label: 'üzleti készségek',
			onClickHandler: () => setFilter('üzleti készségek'),
		},
		{
			label: 'vezetői készségek',
			onClickHandler: () => setFilter('vezetői készségek'),
		},
		{
			label: 'kapcsolatépítés',
			onClickHandler: () => setFilter('kapcsolatépítés'),
		},
		{
			label: 'fenntarthatóság',
			onClickHandler: () => setFilter('fenntarthatóság'),
		},
	]

	const handleCourseSelect = (courseId: number | string) => {
		history.push(`/tananyagok/${courseId}`)
	}

	React.useEffect(() => {
		let newCourseList: any[] = []
		courseList.forEach(
			(courseDetails: any) =>
				JSON.stringify(courseDetails).includes(filter || ' ') &&
				newCourseList.push(courseDetails),
		)
		setFilteredCourseList(newCourseList)
	}, [filter, courseList])

	const toggleBookmarkEvent = (
		eventId: number,
		currentValue: boolean | undefined,
	) => {
		!currentValue &&
			dataProvider.updateSpecial(
				'/events',
				eventId,
				'registration',
				{},
				(response: any) => {
					if (response?.status === 200) {
						setTimeout(() => {
							refreshEventList()
						}, 100)
					}
				},
			)
		currentValue &&
			dataProvider.updateSpecial(
				'/events',
				eventId,
				'unregister',
				{},
				(response: any) => {
					if (response?.status === 200) {
						setTimeout(() => {
							refreshEventList()
						}, 100)
					}
				},
			)
	}

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Önfejlesztés bárhol, bármikor! - enjovom.hu</title>
				<meta
					name="description"
					content="Tananyagaink az önfejlesztés kezdeti lépéseitől az alapvető jogi és vállalkozói ismeretekig kínálnak ingyenes segítséget, hogy megtaláld helyed a munkaerőpiacon."
				/>
			</Helmet>
			<Container className="mt-5">
				<h2
					className={`text-center mt-5 ${
						selectedFilter ? 'first-capitalize' : ''
					}`}
				>
					{selectedFilter ? selectedFilter : '#énjövőm tananyagok'}
				</h2>
				{!selectedFilter && (
					<div className="course_tablist d-flex justify-content-center pt-2 pt-lg-5">
						<TabList tabList={tabList} />
					</div>
				)}
			</Container>
			<Container>
				<div className="row">
					<div className="col">
						<h3 className="mb-4 mt-5">Összes tananyag</h3>
					</div>
				</div>
				<div className="row">
					{filteredCourseList.map((courseDetails: any) => (
						<div
							className="col-12 col-md-6 col-lg-4 col-xl-4 mb-4"
							key={courseDetails.id}
						>
							<ModulCard
								title={courseDetails.title}
								description={courseDetails.shortDescription}
								rating={courseDetails.rating}
								nrOfRatings={courseDetails.reviewsCount}
								nrOfStudents={courseDetails.students}
								tags={courseDetails.tags.map(
									(tagDetails: any) => ({
										label: tagDetails.name,
										color: 'orange',
									}),
								)}
								percentage={0}
								mainPicture={
									courseDetails.cover.sizes.course.url ||
									sampleImg
								}
								handleParentClick={() =>
									handleCourseSelect(courseDetails.slug)
								}
								id={courseDetails.id}
								bookmarked={courseDetails?.bookmarked}
								refresher={refreshCourseList}
							/>
						</div>
					))}
				</div>
			</Container>
			<Container className="course_events">
				<div className="d-flex flex-row align-items-end mt-5 mb-4 justify-content-between">
					<h3 className="mb-0">Gyere el workshopjainkra is!</h3>
					<Link
						to="/esemenyek"
						className="text-cc-primary d-none d-lg-flex"
					>
						<b>Összes esemény</b>
					</Link>
				</div>

				<div className="row">
					{eventList.map(
						(eventDetails: any, index: number) =>
							index < 4 && (
								<div
									className="col-xxl-3 col-xl-4 col-lg-4 col-md-6 col-sm-12 col-xs-12"
									key={`event-${eventDetails.id}`}
								>
									<EventCard
										foundation={undefined}
										title={eventDetails.title}
										tags={eventDetails?.tags?.map(
											(tagDetails: any) => ({
												color: 'orange',
												label: tagDetails.name,
												leftIcon: false,
												icon: '',
												rightIcon: false,
												removeTag: { function() {} },
											}),
										)}
										description={eventDetails.description}
										mainPicture={
											eventDetails.cover.sizes.original
												.url || sampleImg4
										}
										handleParentClick={() =>
											toggleBookmarkEvent(
												eventDetails.id,
												eventDetails.registered,
											)
										}
										schedule={
											eventDetails.date.split(' ')[0]
										}
										presentors={[eventDetails.lecturers]}
										attendants={
											new Array(
												eventDetails.registeredUsersCount,
											)
										}
										placement={
											eventDetails.online
												? 'online'
												: eventDetails.city || ''
										}
										registered={eventDetails.registered}
										availableSeats={
											eventDetails.availableSeats
										}
										id={eventDetails.id}
									/>
								</div>
							),
					)}
				</div>
				<Link
					to="/esemenyek"
					className="text-cc-primary d-block d-lg-none text-center text-cc-primary"
				>
					<b>Összes esemény</b>
				</Link>
			</Container>
		</div>
	)
}

export default CourseList
