import React from 'react'
import './course_slide.css'

import courseSlidesNoteBook from "../../assets/img/course_slides/course_slides_notebook.png"
import courseSlidesNoteBookBig from "../../assets/img/course_slides/course_slides_notebook_big.png"
/* A modal kivittem komponensbe, ami csak ehhez az oldalhoz tartozik, tehát ebben a könyvtárban van */
import CS_Modal from './CS_Modal';
import Circle from '../../core/circle/Circle';
import StarLine from '../../core/star/StarLine';

import courseDetailCheckGreen from "../../assets/img/course_detail/check_green.svg";
import courseDetailCheckGrey from "../../assets/img/course_detail/check_grey.svg";
import Button from '../../core/button/Button';
import Tag from '../../core/tag/Tag';



function CourseSlide() {


    const [isModalOpened, setIsModalOpened] = React.useState(false);


    const handleModalStateChange = () => {
        setIsModalOpened(!isModalOpened);
    }

    return (
        <div className='container  mt-5'>
            <div className="d-flex flex-row align-items-center mb-4">
                <div className="me-auto">
                    <h2 className="mb-0">Kommunikációs tippek</h2>
                    <StarLine
                        rating={3}
                        disabled={true}
                    />
                </div>
                <div className="d-none d-md-inline-block">
                    <Button type='secondary' label='Később folytatom' disabled={false} icon='ejicon-pause' icon_position='left' />
                </div>

            </div>
            <div className="card">
                <div className="card-body p-4">
                    <div className="row">
                        <div className="col-12 col-lg-8 d-none d-md-block">
                            <div className="course-preview-container">
                                <div className="row">
                                    <div className="col-7 d-flex align-items-end">
                                        <div className="p-5">
                                            <p className="text-cc-primary"><strong>Interaktív tréning</strong></p>
                                            <h2 className="mb-4">Üzleti készségek
                                                Projektmenedzsment</h2>
                                            <div className="row">
                                                <div className="col-6">
                                                    <p className="text-cc-primary mb-2"><strong>Lorem ipsum</strong></p>
                                                    <h2 className="mb-0">8,7%</h2>
                                                </div>
                                                <div className="col-6">
                                                    <p className="text-cc-primary mb-2"><strong>Dolorsit</strong></p>
                                                    <h2 className="mb-0">3,477</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5">
                                        <img src={courseSlidesNoteBookBig} alt="" />
                                    </div>
                                </div>
                                <div className="fullscreen" onClick={handleModalStateChange}>
                                    <Circle icon='ejicon-fullscreen' disabled={false} type="white" />
                                </div>

                            </div>
                            <div className="d-flex flex-row align-items-center justify-content-center mt-4">
                                <Circle icon='ejicon-arrow-right' disabled={false} type="red" />
                                <span className="mx-4">1/24</span>
                                <Circle icon='ejicon-arrow-left' disabled={false} type="red" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4 px-3 px-xl-5">
                            <div className="d-flex flex-row d-md-none" onClick={handleModalStateChange}>
                                <Button type='primary' label='Elindítom a tanfolyamot' disabled={false} icon='ejicon-pause' icon_position='left' />
                            </div>
                            <h5 className="mt-4 mb-4">Tananyag felépítése</h5>
                            <ul className="list-style-none">
                                <li className="mb-3">
                                    <div className="d-flex flex-row align-items-center">
                                        <img src={courseDetailCheckGreen} alt="" /><span className="mx-2">1. lecke</span><strong>Bevezetés</strong>
                                    </div>
                                </li>
                                <li className="mb-3">
                                    <div className="d-flex flex-row align-items-center">
                                        <img src={courseDetailCheckGreen} alt="" /><span className="mx-2">2. lecke</span><strong>Lorem ipsum dolor</strong>
                                    </div>
                                </li>
                                <li className="mb-3">
                                    <div className="d-flex flex-row align-items-center">
                                        <img src={courseDetailCheckGreen} alt="" /><span className="mx-2">3. lecke</span><strong>Nunc posuere</strong>
                                    </div>
                                </li>
                                <li className="mb-3">
                                    <div className="d-flex flex-row align-items-center">
                                        <img src={courseDetailCheckGreen} alt="" /><span className="mx-2">4. lecke</span><strong>Bibendum sodales</strong>
                                    </div>
                                </li>
                                <li className="mb-3">
                                    <div className="d-flex flex-row align-items-center">
                                        <img src={courseDetailCheckGrey} alt="" /><span className="mx-2">5. lecke</span><strong>Összefoglalás</strong>
                                    </div>
                                </li>
                                <li className="mb-0">
                                    <div className="d-flex flex-row align-items-center">
                                        <img src={courseDetailCheckGrey} alt="" /><span className="mx-2">6. lecke</span><strong>Lorem ipsum dolor</strong>
                                    </div>
                                </li>
                            </ul>
                            <h5 className="mt-4 mb-4">Ha tetszett, oszd meg ismerőseiddel is!</h5>
                            <div className='d-flex flex-row justify-content-left mt-3'>
                                <div className='me-3'>
                                    <Tag color='red' label='' leftIcon={true} icon='ejicon-facebook' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                </div>
                                <div className='me-3 '>
                                    <Tag color='red' label='' leftIcon={true} icon='ejicon-linkedin' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                </div>
                                <div className='me-3 '>
                                    <Tag color='red' label='' leftIcon={true} icon='ejicon-link-chain' rightIcon={false} removeTag={(e: any) => { console.log(e) }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Csak akkor rendereli a modalt, ha nyitott állapotban van */}
            {isModalOpened ? <CS_Modal handleModalStateChange={handleModalStateChange} /> : null}
        </div>
    )
}

export default CourseSlide
