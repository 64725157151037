import React from 'react';
import Circle from '../../core/circle/Circle';

import dataProvider from '../../dataProvider';

import allaskereses from '../../assets/img/profil/álláskeresés.png';
import idogazdalkodas from '../../assets/img/profil/időgazdálkodás.png';
import jogaim_es_kotelezettsegeim from '../../assets/img/profil/jogaim_és_kötelezettségeim.png';
import motivacio from '../../assets/img/profil/motiváció.png';
import vallalkozasepites from '../../assets/img/profil/vállakozásépítés.png';
import visszajelzes from '../../assets/img/profil/visszajelzés.png';
import targyalas from '../../assets/img/profil/tárgyalás.png';
import uzleti_tervezes from '../../assets/img/profil/üzleti_tervezés.png';
import ertekesites from '../../assets/img/profil/értékesítés.png';
import projectmenedzsment from '../../assets/img/profil/projektmenedzsment.png';
import networking from '../../assets/img/profil/networking.png';
import penzugyek from '../../assets/img/profil/Pénzügyek.png';
import kommunikacio from '../../assets/img/profil/kommunikáció.png';
import onismeret from '../../assets/img/profil/önismeret.png';
import Button from '../../core/button/Button';

const interestCardsFakeApi = [
    {
        label: 'Időgazdálkodás',
        src: idogazdalkodas,
        selected: false
    },
    {
        label: 'Álláskeresés',
        src: allaskereses,
        selected: false
    },
    {
        label: 'Jogaim és kötelezettségeim',
        src: jogaim_es_kotelezettsegeim,
        selected: false
    },
    {
        label: 'Motiváció',
        src: motivacio,
        selected: false
    },
    {
        label: 'Vállakozásépítés',
        src: vallalkozasepites,
        selected: false
    },
    {
        label: 'Visszajelzés',
        src: visszajelzes,
        selected: false
    },
    {
        label: 'Tárgyalás',
        src: targyalas,
        selected: false
    },
    {
        label: 'Üzleti tervezés',
        src: uzleti_tervezes,
        selected: false
    },
    {
        label: 'Értékesítés',
        src: ertekesites,
        selected: false
    },
    {
        label: 'Projektmenedzsment',
        src: projectmenedzsment,
        selected: false
    },
    {
        label: 'Networking',
        src: networking,
        selected: false
    },
    {
        label: 'Pénzügyek',
        src: penzugyek,
        selected: false
    },
    {
        label: 'Önismeret',
        src: onismeret,
        selected: false
    },
    {
        label: 'Kommunikáció',
        src: kommunikacio,
        selected: false
    }
]

const pictureHandler: any = {
    'időgazdálkodás': idogazdalkodas,
    'álláskeresés': allaskereses,
    'jogaim és kötelezettségeim': jogaim_es_kotelezettsegeim,
    'motiváció': motivacio,
    'vállalkozói készségek': vallalkozasepites,
    'visszajelzés': visszajelzes,
    'kapcsolatépítés': targyalas,
    'üzleti készségek': uzleti_tervezes,
    'értékesítés': ertekesites,
    'projektmenedzsment': projectmenedzsment,
    'networking': networking,
    'vezetői készségek': penzugyek,
    'önismeret és önfejlesztés': onismeret,
    'kommunikáció': kommunikacio,
    'vállalkozásépítés': vallalkozasepites,
    'önismeret': onismeret,
    'pályaválasztás': networking
}

export const Interests = (props: any) => {

    const {
        modalPopper,
        closeModal
    } = props
    const [interestTags, setInterestsTags] = React.useState<any[]>([]);
    const [myLiveInterests, setMyLiveInterests] = React.useState<number[]>([]);

    const toggleSelection = (tagIndex: number, type: string) => {
        if (type === 'interests') {
            if (myLiveInterests.includes(tagIndex)) {
                const liveData = myLiveInterests.filter(value => value != tagIndex);
                setMyLiveInterests(liveData)
            } else {
                const liveData = [...myLiveInterests, tagIndex];
                setMyLiveInterests(liveData);
            }
        }
    }

    const tryToSaveInterests = () => {
        dataProvider.updateOne('/user/profile/interests', { tags: myLiveInterests }, (response: any) => {
            if (response?.status === 200) {
                modalPopper(<div onClick={closeModal}>
                    <h3 style={{ padding: '24px' }}>A beállításaidat elmentettük.</h3>
                </div>)
            }
        })
    }

    React.useEffect(() => {
        dataProvider.getMany('/courses/tags', (response: any) => {
            if (response?.status === 200) {
                setInterestsTags(response?.data?.data)
            }
        })
        dataProvider.getMany('/user/profile/interests', (response: any) => {
            if (response?.status === 200) {
                setMyLiveInterests(response?.data?.data?.tags.map((tagData: any) => tagData.id));
            }
        })
    }, [])

    return (
        <div className="w-100">
            <p>Jelöld ki, melyik témák érdekelnek, hogy személyre szabottan segíthessünk a továbbiakban! </p>
            <div className="row  row-cols-lg-6">
                {interestTags.map((tagData: any, index: number) =>
                    <div className="col" key={tagData.id}>
                        <div className={`profile-interests-container ${myLiveInterests.includes(tagData.id) ? 'profile-interests-container-selected' : ''}`}
                            onClick={() => toggleSelection(tagData.id, 'interests')}>
                            <div className={myLiveInterests.includes(tagData.id) ? 'interests-selected' : ''}>
                                <img src={pictureHandler[tagData.name]} alt="" />    {/* /// Fake */}
                                {myLiveInterests.includes(tagData.id) && <Circle icon='ejicon-tick' disabled={false} type='red' />}
                            </div>
                            <p className="text-center small mt-2 mb-4">{tagData.name}</p>

                        </div>
                    </div>)}

            </div>
            <div className="d-flex m-3 justify-content-center" onClick={tryToSaveInterests}>
                <Button
                    type='primary'
                    label='Beállítások mentése'
                    disabled={false}
                    icon=''
                    icon_position=''
                />
            </div>
        </div>
    );
};
