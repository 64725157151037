import React from 'react'

function NotFound() {
    return (
        <>
        <div style={{width:"100%", height:'100%', padding:'24px', textAlign:'center'}}>
            <div style={{fontSize:'10em', fontWeight:'bolder', margin: ' 0 auto'}}>404</div>
            <div>
                <h2>Az Ön által keresett aloldal nem található,vagy megváltozott az elérése.</h2>

            </div>
        </div>

        </>
    )
}

export default NotFound