import React from 'react'
import Button from '../../core/button/Button'
import './login_reg.css'
import { Container } from 'react-bootstrap'
import './login.scss'
import enjovom_bejelentkezes_csoportkep from '../../assets/img/enjovom_bejelentkezés_csoportkép.jpg'
import emojiApplause from '../../assets/icons/emoji/applause.png';
import Input from '../../core/inputs/Input'
import authProvider from '../../authProvider';
import { validate } from '../../validate'


function ForgottenPassword(props: any) {
    const [email, setEmail] = React.useState('')

    const { modalPopper, closeModal } = props

    const validateEmail = () => {
        return validate.email(email);
    }

    const askForNewPassword = () => {
        if (validate) {
            authProvider.passwordReset(email, (response: any) => {
                if (response?.status === 200) {
                    modalPopper(<SuccessPage />)
                }
            });
        }
    }

    const SuccessPage = () => {
        return (
            <div style={{padding: '24px', lineHeight:'40px'}}>
                <h3 style={{lineHeight:'32px'}} className='d-flex flex-row  login_greetings_container mt-2' onClick={closeModal}>
                    Jelszóváltás megerősítése céljából email üzenetet küldtünk neked.
                </h3>
                <br></br>
                <h5>
                    Kérjük, hogy nézd meg a postaládádat és kövesd az e-mailünkben található utasításokat!
            </h5>
            </div>
        )
    }

    return (
        <Container fluid>
            <div className='d-flex flex-lg-row flex-xl-row flex-column-reverse flex-md-column-reverse'>
                <div className='login_container d-flex align-items-start flex-column col-xl-6 col-lg-6 col-md-12 col-12'>
                    <div className='d-flex align-items-start flex-column'>
                        <div className='d-flex flex-row heading_one login_greetings_container'>
                            <div><img src={emojiApplause} className='login_apllause_emoji' alt="" /></div>
                            <h1 className="login_header_text"> Üdv újra itt</h1>
                        </div>
                        <div className='body_large login_folytasd'>
                            Folytasd, ahol abbahagytad
                        </div>
                    </div>
                    <div className='heading_four  login_bejelentkezes mt-2 mt-lg-5 mb-2'>
                        <h4>Elfelejtett jelszó</h4>
                    </div>
                    <div className='body_normal mb-2 mb-lg-5'>
                        Elfeljtetted a jelszavad? Semmi gond! Add meg az e-mail címed és mi máris küldjük új jelszavad.
                    </div>
                    <div className='inputs_container col-xl-8 col-lg-8 col-md-12 col-12 mb-2'>
                        <div className="input_holder">
                            <Input
                                disabled={false}
                                label=''
                                leftIcon='ejicon-envelope-alt'
                                rightIcon={null}
                                value={email}
                                onChangeHandler={(e: any) => setEmail(e.target.value)}
                                error=''
                                type='text'
                                placeHolder='E-mail cím' />
                        </div>
                    </div>
                    <div className="login_button" onClick={() => askForNewPassword()}>

                        <Button
                            type='primary'
                            disabled={!validateEmail()}
                            icon={null}
                            icon_position={null}
                            label='Új jelszó kérése'
                        />
                    </div>
                </div>
                <div className="login_picture_container col-xl-6 col-lg-6 col-md-12 col-12">
                    <Container fluid className='login_picture_container_inside text-end '>
                        <img src={enjovom_bejelentkezes_csoportkep} alt="" className="login_responsive_img" />
                        <div className="login_maximum col-xl-8 col-lg-8 col-md-10 col-10 heading_two">
                            <div className='col login_hozd'>Hozd ki magadból<br />a maximumot!</div>

                        </div>
                    </Container>
                </div>
            </div>
        </Container>
    )
}

export default ForgottenPassword
